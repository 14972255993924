// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Service } from "../../utilities/src/models/Service";
import BlockHelpers from "../../utilities/src/BlockHelpers";
import { parseCatalogue } from "../../utilities/src/helpers/utils";
import { RefObject } from "react";
import {
  isTokenExpired,
  clearStorageData,
} from "../../ss-cms-common-components/src/Utilities/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  services: Service[];
  loading: boolean;
  deletionError: string;
  deletionSuccess: string;
}

interface SS {
  id: any;
}

export default class ServicesmanagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  getAllCataloguesApiCallId: any;
  getAllCategoriesApiCallId: any;
  searchCataloguesApiCallId: any;
  deleteCataloguesApiCallId: any;
  debounceTimer: NodeJS.Timeout | null = null;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      services: [],
      loading: true,
      deletionError: "",
      deletionSuccess: "",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getAllCatalogues();
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }

      if (
        (this.getAllCataloguesApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) ||
        (this.searchCataloguesApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))
      ) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (responseJson && !responseJson.errors && responseJson.data) {
          this.handleGetAllCatalogues(responseJson);
        } else {
          this.setState({ services: [] });
          this.setState({ loading: false });

          const errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );

          this.parseApiCatchErrorResponse(errorReponse);
        }
      } else if (this.deleteCataloguesApiCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (responseJson && !responseJson.error) {
          this.setState({ deletionSuccess: responseJson.message });

          setTimeout(() => {
            this.setState({ deletionSuccess: "" });
          }, 4000);

          this.getAllCatalogues();
          return;
        }
        
        this.setState({ deletionError: responseJson.error });

        setTimeout(() => {
          this.setState({ deletionError: "" });
        }, 4000);

        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
        
      }

    }
  };

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };
  
  handleGetAllCatalogues = (responseJson: any) => {
    const services: Service[] = responseJson.data.map((response: any) =>
      parseCatalogue(response)
    );

    this.setState({
      services,
    });
    this.setState({ loading: false });
  };

  getAllCatalogues = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson,
    };

    this.getAllCataloguesApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getCataloguesEndPoint,
      header,
    });
  };

  searchServices = (queryRef: RefObject<HTMLInputElement>) => {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      this.setState({ loading: true });
      const header = {
        "Content-Type": configJSON.contentTypeApplicationJson,
      };

      const query = (queryRef.current && queryRef.current.value) || "";

      const attrs = {
        query,
      };
      const queryParams = new URLSearchParams(attrs).toString();

      this.searchCataloguesApiCallId = BlockHelpers.callApi({
        method: configJSON.getMethod,
        endPoint:
          query !== ""
            ? `${configJSON.searchCataloguesEndPoint}?${queryParams}`
            : configJSON.getCataloguesEndPoint,
        header,
      });
      clearTimeout(this.debounceTimer!);
    }, 500);
  };

  deleteServices = (catalogueIds: string[]) => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson,
    };

    const body = {
      catalogue_ids: catalogueIds,
    };

    this.deleteCataloguesApiCallId = BlockHelpers.callApi({
      method: configJSON.deleteMethod,
      endPoint: configJSON.deleteCataloguesEndPoint,
      header,
      body: JSON.stringify(body),
    });
  };
}
// Customizable Area End
