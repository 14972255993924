// Customizable Area Start
import React from "react";
import {
  Card,
  Checkbox,
  List,
  ListItem,
  SvgIcon,
  useMediaQuery,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { FilterOptions as useStyles } from "./styles/FilterOptions.web";
import { Category } from "../../utilities/src/models/Category";
import theme from "../../utilities/src/theme";
import { ReactComponent as CheckboxIcon } from "../assets/Checkbox.svg";
import { ReactComponent as CheckboxIconChecked } from "../assets/Checkbox-checked.svg";

interface Props {
  options: Category[];
  tempSelectedCategories: number[];
  selectCategory: (identifier: number, updateCategories: boolean) => void;
}

const FilterOptions: React.FC<Props> = ({
  options,
  tempSelectedCategories,
  selectCategory,
}) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card className={classes.card}>
      <Typography className={classes.header}>Category</Typography>
      <List className={classes.list}>
        {options.map((category) => (
          <ListItem key={category.id} className={classes.item}>
            <Typography>{`${category.name} (${category.count})`}</Typography>
            <Checkbox
              checked={tempSelectedCategories.includes(category.id)}
              className={classes.checkbox}
              icon={<SvgIcon component={CheckboxIcon} viewBox="0 0 20 20" />}
              checkedIcon={
                <SvgIcon component={CheckboxIconChecked} viewBox="0 0 20 20" />
              }
              onClick={() =>
                selectCategory(category.id, isSmallScreen ? false : true)
              }
            />
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default FilterOptions;
// Customizable Area End
