import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { getResponseVariables } from "../GetResponseVariables/GetResponseVariables";
import { getStorageData, removeStorageData } from "../../../../framework/src/Utilities";

export const isTokenExpired = (message: Message) => {
  const [responseJson] = getResponseVariables(message);
  if (responseJson?.errors) {
    if (
      Array.isArray(responseJson?.errors) &&
      responseJson?.errors.length > 0 &&
      responseJson?.errors[0]?.token &&
      (responseJson?.errors[0]?.token == "Token has Expired" ||
        responseJson?.errors[0]?.token == "Invalid token" ||
        responseJson?.errors[0]?.token == "invalid token")
    ) {
      return true;
    }
    return false;
  }
  return false;
};

export const parseExpireTokenResponse = (
  responseJson: any,
  state: any,
  props: any
) => {
  if (responseJson?.errors) {
    if (!state.invalidTokenMessageRecieved) {
      if (
        Array.isArray(responseJson?.errors) &&
        responseJson?.errors.length > 0 &&
        responseJson?.errors[0]?.token &&
        (responseJson?.errors[0]?.token == "Token has Expired" ||
          responseJson?.errors[0]?.token == "Invalid token" ||
          responseJson?.errors[0]?.token == "invalid token")
      ) {
        return false;
      }
      return true;
    }
  }
  return true;
};

export const clearStorageData = async () => {
  removeStorageData("admintoken");
  removeStorageData("adminuser");
}

export const logoutAndRedirectToLoginPage = (props: any) => {
  clearStorageData();
  navigateToLogin(props);
};

export const checkResponseError = (responseJson: any) => {
  return !responseJson || responseJson?.errors || responseJson?.error;
};

export const getError = (responseJson: any) => {
  const errors =
    responseJson?.error ||
    responseJson?.errors ||
    "An error occurred during connection to the server";
  const isOnlyOneString = typeof errors === "string";
  const stringErrors = typeof errors[0] === "string";
  return isOnlyOneString
    ? errors
    : stringErrors
    ? errors.join("\n")
    : errors
        .map((i: any) => Object.values(i))
        .flat()
        .join("\n");
};

export const showError = (
  title: any,
  responseJson: any,
  hideLoader: any,
  setDialogState: any
) => {
  hideLoader?.();
  const errorMessage = getError(responseJson);
  setDialogState?.(true, {
    title: title,
    message: errorMessage,
    confirmColor: "white",
    confirmBackground: "#FF1744",
    confirmHoverBackground: "rgb(240, 25, 73)",
    hideCancel: true,
  });
  return errorMessage;
};

export const generateRequestMessage =  async (
  endpoint: any,
  type: any,
  additionalHeaders?: any
) => {
  const headers = {
    "content-type": "application/json",
    token: await getStorageData("admintoken"),
    ...(additionalHeaders || {}),
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  const addSlash = !endpoint.startsWith("/") && !endpoint.startsWith("http");
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    addSlash ? `/${endpoint}` : endpoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    type
  );
  return requestMessage;
};

export const navigateToLogin = (props: unknown) => {
  const to = new Message(getName(MessageEnum.NavigationMessage));
  to.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLogin");
  to.addData(getName(MessageEnum.NavigationPropsMessage), props);
  runEngine.sendMessage(to.messageId, to);
};
