// Customizable Area Start
import React from "react";
import { Field, Form, Formik } from "formik";
import * as YupValidation from "yup";
import { TextInput } from "../../ss-cms-common-components/src/Inputs/Inputs.web";
import { Dialog } from "../../ss-cms-common-components/src/Dialog/Dialog";
import CCAvenueActivateDialogController from "./CCAvenueActivateDialogController.web";
const configJSON = require("./config.js");

export default class CCAvenueActivateDialog extends CCAvenueActivateDialogController {
  render() {
    const { open, setOpen, defaultValues } = this.props;
    const initialValues = {
      id: defaultValues?.attributes?.id || 1,
      merchant_id: defaultValues?.attributes?.merchant_id || "",
      access_code: defaultValues?.attributes?.access_code || "",
      working_key: defaultValues?.attributes?.working_key || ""
    }
    return (
      <Dialog
        open={open}
        setOpen={setOpen}
        onSubmit={this.handleSubmitDialog}
        title={configJSON.ccAvenuePaymentIntegration}
        customContent
        okay="Save"
        data-testid="ccavenue-activate-dialog"
        containerClassName="payment-activate-dialog"
        titleClassName="title"
        ignoreClickAway
      >
        <Formik
          innerRef={this.formikRef}
          initialValues={initialValues}
          validationSchema={YupValidation.object().shape({
            merchant_id: YupValidation.string().required(configJSON.thisFieldIsRequired),
            access_code: YupValidation.string().required(configJSON.thisFieldIsRequired),
            working_key: YupValidation.string().required(configJSON.thisFieldIsRequired)
          })}
          onSubmit={this.handleSubmit}
          enableReinitialize
        >
          {(formikProps) => (
            <Form autoComplete="off" noValidate className="form" translate={undefined}>
              <Field type="hidden" name="id" />
              <TextInput label={configJSON.labelMerchantId} name="merchant_id" required {...formikProps} />
              <TextInput label={configJSON.labelAccessCode} name="access_code" required {...formikProps} />
              <TextInput label={configJSON.labelWorkingKey} name="working_key" required {...formikProps} />
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  }
}
// Customizable Area End
