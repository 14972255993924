import { Theme, makeStyles } from "@material-ui/core";

export const Catalogue = makeStyles((theme: Theme) => ({
  breadcrumbsWrapper: {
    margin: theme.spacing(5, 10, 1),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 1.25, 1),
      display: "flex",
    },
  },
  breadcrumbLink: {
    color: "#3FC1CB",
    textDecoration: "none",
  },
  breadcrumbNonLink: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "130px",
  },
  currentCatalogueWrapper: {
    display: "flex",
    gap: theme.spacing(16),
    padding: theme.spacing(6.25, 10, 11.25),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: theme.spacing(1.75),
      padding: theme.spacing(2.5),
    },
  },
  imagesWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(1.25),
    },
  },
  largeImageWrapper: {
    display: "flex",
    width: "628px",
    height: "611px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "335px",
    },
  },
  largeImage: {
    flex: "1 1 auto",
  },
  smallImagesWrapper: {
    display: "flex",
    width: "628px",
    gap: theme.spacing(3),
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      gap: theme.spacing(1.25),
    },
  },
  smallImage: {
    width: "85px",
    height: "83px",
    flex: "0 0 auto",
    cursor: "pointer",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing(5),
    marginTop: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(3),
      marginTop: theme.spacing(0),
    },
  },
  infoTitle: {
    fontSize: "18px",
    lineHeight: "24px",
    color: "#757575",
    paddingBottom: theme.spacing(1),
  },
  catalogueName: {
    fontSize: "32px",
    lineHeight: "36px",
    letterSpacing: "3.2px",
    color: "#364F6B",
    textTransform: "uppercase",
  },
  servicesList: {
    paddingInlineStart: theme.spacing(5),
  },
  servicesListItem: {
    display: "list-item",
    listStyleType: "disc",
    padding: theme.spacing(0),
  },
  info: {
    fontSize: "18px",
    lineHeight: "24px",
    color: "#000000",
  },
  link: {
    textDecoration: "none",
    display: "flex",
    width: "100%",
  },
  bookNowButton: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    backgroundColor: "#3FC1CB",
    color: "#FFFFFF",
    borderRadius: "5px",
    padding: theme.spacing(1.5, 7.5),
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
}));
