Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.appointmentApiContentType = 'application/json'

exports.appointmentAPiEndPoint = 'bx_block_appointment_management/availabilities'

exports.addappointmentAPiEndPoint = 'bx_block_appointment_management/appointments'

exports.catalogueAPIEndPoint = 'bx_block_catalogue/catalogues'

exports.addAppointmentAPiMethod = 'POST'
exports.deleteAllAppointmentsAPiMethod = 'DELETE'
exports.getAppointmentListAPiMethod = 'GET'

exports.errorTitle = 'Error'
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.'
exports.stripeAPiMethod = 'POST'
exports.postStripePaymentApiEndPoints = 'bx_block_stripe_integration/payments'
exports.dataEncryptionEndPoint = 'bx_block_ccavenue_integration/check_out_encrypt_data'
// Customizable Area End
