import React from "react";
// Customizable Area Start
import ServicemanagementController, {
  Props,
} from "./ServicemanagementController.web";
import Service from "./Service.web";
// Customizable Area End

export default class ServiceWrapper extends ServicemanagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Service
        service={this.state.service}
        categoriesData={this.state.categories}
        availabilityErrors={this.state.availabilityErrors}
        validateAvailability={this.validateAvailability}
        resetAvailabilityErrors={this.resetAvailabilityErrors}
        upsertService={this.upsertService}
      />
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
