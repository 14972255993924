Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.dummyRequestEndpoint = "/bx_block_email_template/email_settings";
exports.httpGetMethod = "GET";

exports.textWelcome = "Welcome,";
exports.textHint = "Complete these steps to get your store up and running!";

exports.textSettingUpStore = "Setting up Store";
exports.textAddStoreDetails = "Add Store Details";
exports.textUpdateCommonSettings = "Update Common Settings";
exports.textCustomBranding = "Customise Branding of your homepage";
exports.textAddGallery = "Add Gallery images";
exports.textConfigureEmailTemplates = "Configure Email Templates";
exports.textPopulateContent = "Populate content in Static Pages";

exports.textSettingUpServices = "Setting up Services";
exports.textAddCategories = "Add Categories, Services and availability";

exports.textCompleteBusinessSettings = "Completing Business Settings";
exports.textAddPaymentIntegration = "Add Payment integration details";
exports.textMobileAppSettings = "Mobile App Settings";
exports.textDone = "And with this, you're all done!";
exports.textDoneHint = "Your store is up and running";

exports.textSetTo = "You are now all set to:"
exports.textStartBookings = "Start receiving bookings";
exports.textSeeCustomers = "See details of customers"
exports.textCustomerFeedback = "Get Customer Feedback"
// Customizable Area End
