import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { withToastProps } from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import {
  convertFromHtml,
  EditorStateType,
  getHtml,
} from "../../ss-cms-common-components/src/Editor/Editor";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { generateRequestMessage, isTokenExpired, showError, clearStorageData } from "../../ss-cms-common-components/src/Utilities/Utilities";
export const configJSON = require("./config.js");

export type Props = RouterProps &
  DialogProps &
  withHeadeActionBarProps &
  withLoaderProps &
  withToastProps & {
    navigation: {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    id: string;
    match: MatchType;
    classes: ClassesType;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  customerFeedbackId: number;
  description: EditorStateType;
  position: string;
  customerName: string;
  image: string;
  activated: boolean;
  selectedFile: string | null | ArrayBuffer;
  selectedCropedFile: string | null | ArrayBuffer;
  cropperOpen: boolean;
  localValidation: boolean;
  editCustomerFeedback: CustomerFeedbackType,
  selectedFileType: string,
  imageSize: number;

  // Customizable Area End
}

interface SS {
  id: number;
}
interface MatchType {
  params: {
    id: number;
  }
}
interface ClassesType {
  input: string;
  backIcon: string;
  activatedCellStyleTrue: string;
  activatedCellStyleFalse: string;
  editUploadBoxContainer: string;
  crossOverImage: string;
  editUploadBoxImage: string;
  cropperBox: string;
  modalTitle: string;
  titleBlock: string;
  closeIcon: string;
}
interface CustomerFeedbackType {
  description?: EditorStateType;
  position?: string;
  customerName?: string;
  image?: string;
  activated?: boolean;
}
interface ClassesIOSSwitchType {
  root: string;
  switchBase: string;
  thumb: string;
  track: string;
  checked: string;
  focusVisible: string;
}
interface ResponseJsonType {
  data?: {
    attributes: {
      description: string;
      position: string;
      customer_name: string;
      image: string;
      is_active: boolean;
    }
  },
  errors?: {}
}
export type IOSSwitchProps = {
  classes: ClassesIOSSwitchType;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  "data-testid": string;
}

export default class CustomerFeedbackCreateController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCustomerFeedbackApiCallId: string = "";
  addCustomerFeedbackApiCallId: string = "";
  updateCustomerFeedbackApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      customerFeedbackId: Number(this.props.navigation.getParam("id") || 0),
      description: convertFromHtml(""),
      position: "",
      customerName: "",
      image: "",
      activated: false,
      selectedFile: null,
      cropperOpen: false,
      selectedCropedFile: null,
      localValidation: false,
      editCustomerFeedback: {},
      selectedFileType: "image/jpeg",
      imageSize: 0,

    };
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.ActionMessageFromToaster)
    ];

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  handleGetCustomerFeedbackApiCallIdResponse = (responseJson: ResponseJsonType) => {
    this.props.hideLoader();
    if (responseJson && responseJson?.data) {
      this.initCustomerFeedbackData(responseJson.data);
    } else {
      showError(configJSON.textErrorOnInitialize,responseJson, this.props.hideLoader, this.props.setDialogState)
    }
  }
  handleAddCustomerFeedbackApiCallIdResponse = (responseJson: ResponseJsonType) => {
    this.props.hideLoader();
    if (responseJson && !responseJson?.errors) {
      const to = new Message(getName(MessageEnum.NavigationMessage));
        to.addData(getName(MessageEnum.NavigationTargetMessage), "AdminCustomerFeedback");
        to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        runEngine.sendMessage(to.messageId, to);
    } else {
      showError(configJSON.textErrorOnSaveChanges,responseJson, this.props.hideLoader, this.props.setDialogState)
    }
  }
  handleToasterMessageResponse = (message: Message) => {
    const type = message?.getData(
      getName(MessageEnum.ActionMessageFromToasterMessage)
    );
    if (type === configJSON.keySaveChanges) {
      if (this.state.description.getCurrentContent().getPlainText().length > 100) {
        setTimeout(() => {
          this.props.showHeaderBar({
            type: configJSON.keyError,
            message: configJSON.descriptionValidation,
          });
        }, 500)
        return;
      }
      if (this.state.customerFeedbackId) {
        this.updateCustomerFeedbackApi();
      } else {
        this.addCustomerFeedbackApi();
      }
    } else if (type === configJSON.keyCancelChanges) {
      if (this.state.customerFeedbackId) {
        this.getCustomerFeedbackDataApi();
      } else {
        this.resetPageData();
      }
    }
  }
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.getCustomerFeedbackApiCallId === apiRequestCallId) {
        this.handleGetCustomerFeedbackApiCallIdResponse(responseJson);
      } else if (
        this.addCustomerFeedbackApiCallId === apiRequestCallId
        || this.updateCustomerFeedbackApiCallId === apiRequestCallId
      ) {
        this.handleAddCustomerFeedbackApiCallIdResponse(responseJson);
      }
    } else if (getName(MessageEnum.ActionMessageFromToaster)) {
      this.handleToasterMessageResponse(message);
    }
  }

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const toSend = new Message(getName(MessageEnum.NavigationMessage));
    toSend.addData(
      getName(MessageEnum.NavigationTargetMessage),
      configJSON.EmailAccountLogin
    );
    toSend.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(toSend.messageId, toSend);
  };
  
  async componentDidMount() {
    if (this.state.customerFeedbackId) {
      this.getCustomerFeedbackDataApi();
    }
  }
  goBack = () => {
    const toSend = new Message(getName(MessageEnum.NavigationMessage));
    toSend.addData(getName(MessageEnum.NavigationTargetMessage), configJSON.AdminCustomerFeedback);
    toSend.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(toSend.messageId, toSend);
  };
  openToastOnChange = () => {
    this.props.showHeaderBar({
      message: "",
      ignoreClickAway: true,
    });
  };
  handleDescriptionChange = (value: EditorStateType) => {
    this.setState({ description: value });
    this.openToastOnChange();
  };
  handlePositionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ position: event.target.value });
    this.openToastOnChange();
  };
  handleCustomerNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ customerName: event.target.value });
    this.openToastOnChange();
  };
  handleActiveChange = (value: boolean) => {
    this.setState({ activated: value });
    this.openToastOnChange();
  };
  handleCropperClose = () => {
    this.setState({ cropperOpen: false });
  };
  handleCropperOpen = () => {
    this.setState({ cropperOpen: true });
  };
  selectImageFile = (files: Blob[]) => {
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ selectedFile: reader.result, imageSize: files[0].size, selectedFileType: files[0].type }, () => {
        this.handleCropperOpen();
      });
    };
    reader.readAsDataURL(files[0]);
  };
  handleCropperSubmit = (data: string) => {
    if (data) {
      this.setState(
        {
          selectedCropedFile: data,
          image: data,
        },
        () => {
          this.setState({ cropperOpen: false });
          this.openToastOnChange();
        }
      );
    }
  };
  removeLogoImg = () => {
    this.setState(
      {
        selectedFile: null,
        selectedCropedFile: "",
        image: "",
      },
      () => {
        this.openToastOnChange();
      }
    );
  };
  initCustomerFeedbackData = (item: { attributes: { description: string; position: string; customer_name: string; image: string; is_active: boolean; }; }) => {


    this.setState({
      editCustomerFeedback: {
        ...this.state.editCustomerFeedback,
        description: convertFromHtml(item.attributes.description),
        position: item.attributes.position,
        customerName: item.attributes.customer_name,
        image: item.attributes.image,
        activated: item.attributes.is_active,
      },
      description: convertFromHtml(item.attributes.description),
      position: item.attributes.position,
      customerName: item.attributes.customer_name,
      image: item.attributes.image,
      activated: item.attributes.is_active,
    });
  };
  resetPageData = () => {
    this.setState({
      description: convertFromHtml(""),
      position: "",
      customerName: "",
      image: "",
      activated: false,
      cropperOpen: false,
      selectedFile: null,
      selectedCropedFile: null,
    });
  };
  getCustomerFeedbackDataApi = async () => {
    this.props.showLoader();
    const requestMessage = await generateRequestMessage(`${configJSON.getCustomerFeedbackAPI}/${this.state.customerFeedbackId}`, configJSON.validationApiMethodType);
    this.getCustomerFeedbackApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  addCustomerFeedbackApi = () => {
    const { description, position, customerName, image, activated } =
      this.state;
    const customerFeedback = {
      description: getHtml(description),
      position,
      customer_name: customerName,
      image,
      is_active: activated,
    };

    this.setState({
      localValidation:
        !description.getCurrentContent().getPlainText() ||
        !customerName ||
        !position ||
        !description ||
        !image
    }, async () => {
      if (!this.state.localValidation) {
        this.props.showLoader();
        const requestMessage = await generateRequestMessage(configJSON.getCustomerFeedbackAPI, configJSON.loginAPiMethod);
        this.addCustomerFeedbackApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(customerFeedback)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    })


  };
  updateCustomerFeedbackApi = async () => {
    const {
      description,
      position,
      customerName,
      image,
      activated,
      customerFeedbackId,
      editCustomerFeedback
    } = this.state;
    if (!image) {
      return this.props.setDialogState(true, {
        title: configJSON.updateModalTitle,
        message: configJSON.updateModalMessage,
        confirmColor: configJSON.deleteModalConfirmColor,
        confirmBackground: configJSON.deleteModalConfirmBackground,
        confirmHoverBackground: configJSON.deleteModalConfirmHoverBackground,
        hideCancel: true,
      });
    }

    let imageUpdated = editCustomerFeedback.image === image ? {} : { image: image }
    let customerFeedback = {
      description: getHtml(description),
      position: position,
      customer_name: customerName,
      ...imageUpdated,
      is_active: activated,
    };

    this.props.showLoader();
    const requestMessage = await generateRequestMessage(`${configJSON.getCustomerFeedbackAPI}/${customerFeedbackId}`, configJSON.putAPiMethod);
    this.updateCustomerFeedbackApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(customerFeedback)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
