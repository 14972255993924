import React from "react";
// Customizable Area Start
import CommonSettingsAdminController, {
  Props
} from "./CommonSettingsAdminController.web";
import CommonSettingsAdmin from "./CommonSettingsAdmin.web";

export default class CommonSettingsAdminWrapper extends CommonSettingsAdminController {
  constructor(props: Props) {
    super(props);
  }


  render() {
    return (
      //Merge Engine DefaultContainer
      <CommonSettingsAdmin
        data={this.state.data}
        isSaving={this.state.isSaving}
        upsertService={this.upsertService}
        resetValues={this.state.resetValues}
        setResetValues={this.setResetValues}
      />
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area End
