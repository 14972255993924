// Customizable Area Start
import React from "react";
import OrderList from "./OrderList.web";

import OrderManagementController, {
    Props
} from "./OrderManagementController.web";


export default class OrderManagementList extends OrderManagementController {
    constructor(props: Props) {
        super(props);

    }

    handleDialogOpen = () => {
        this.setState({ isDialogOpen: true });
    };

    handleDialogClose = () => {
        this.setState({ isDialogOpen: false });
    };


    handleSubmit = (values: any) => {

        this.filterOrder(values);
        this.handleDialogClose();
        
    };

     searchFunction = (query: string) => {

      };


    fetchSearchResults = () => {
        const { searchQuery } = this.state;

        if (searchQuery !== null && searchQuery !== '') {
            if (searchQuery.length > 0) {
                this.serach_orders(searchQuery)
            }

        } else {
            this.setState({ searchResults: [] });
        }
    };

    render() {
        return (
            // Customizable Area Start
             <>
            
              <OrderList
               orderList_Data={this.state.orderList_Data}
               handleDialogOpen={this.handleDialogOpen}
               handleDialogClose={this.handleDialogClose}
               searchFunction={this.searchFunction}
               searchQuery={this.state.searchQuery}
               isDialogOpen={this.state.isDialogOpen}
               handleSubmit={this.handleSubmit}
               appointment_summary={this.appointment_summary}
               getList={this.getList}
               download={this.download}
               serach_orders_list={this.serach_orders_list}
               isLoading={this.state.isLoading}
               isOrderBlank={this.state.isOrderBlank}
               
              />
             </>
       
            // Customizable Area End
        );
    }
}



// Customizable Area End

