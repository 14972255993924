Object.defineProperty(exports, '__esModule', {
  value: true
});
// Customizable Area Start

exports.urlGetValidations = 'profile/validations';
exports.validationApiContentType = 'application/json';
exports.validationApiMethodType = 'GET';
exports.loginAPiEndPoint = '/admin/v1/login/';
exports.forgotAPiEndPoint = '/admin/v1/forgot_password';
exports.otpValidateAPIEndPoint = '/admin/v1/forgot_password/otp_validate';
exports.loginAPiMethod = 'POST';
exports.putAPiMethod = 'PUT';
exports.deleteAPiMethod = 'DELETE';
exports.loginApiContentType = 'application/json';
exports.dashboardOnbordingAPI = '/admin/v1/onboarding';
exports.getCustomerFeedbackAPI =
  '/bx_block_customer_feedback/customer_feedbacks';
exports.postBulkDeleteAPI =
  '/bx_block_customer_feedback/customer_feedbacks/bulk_destroy';

exports.resource = "customer_feedback";
exports.AdminCustomerFeedbackCreate = "AdminCustomerFeedbackCreate"
exports.AdminCustomerFeedback = "AdminCustomerFeedback"
exports.EmailAccountLogin = "EmailAccountLogin"
exports.errorEmailNotValid = 'Email not valid.';
exports.errorPasswordNotValid = 'Password not valid.';
exports.placeHolderEmail = 'Email';
exports.placeHolderPassword = 'Password';
exports.labelHeader = 'The reason we require login';
exports.btnTxtLogin = 'LOG IN';
exports.labelRememberMe = 'Remember me';
exports.btnTxtSocialLogin = 'LOGIN WITH SOCIAL MEDIA';
exports.labelOr = 'OR';
exports.labelTitle = 'Log in';
exports.customerFeedback = 'Customer feedback';
exports.customerFeedbackDetails = 'Customer feedback details';
exports.description = 'Description *';
exports.position = 'Position *';
exports.customerName = 'Customer Name *';
exports.image = 'Image *';
exports.addImage = 'Add Image';
exports.sizeMsg = 'Max 20MB';
exports.imageEmpty = 'Image field can not be empty';
exports.imageValidation = 'Image can not be greater than 20MB';
exports.activated = 'Activated';
exports.notActivated = 'Not Activated';
exports.customerFeedbackImage = 'Add Customer Feedback Image';
exports.customerFeedbackUrl = '/website-and-emails/customer-feedback';
exports.descriptionValidation = 'Description cannot exceed 100 characters';
exports.deleteCustomerFeedback = 'Delete Customer Feedback';
exports.createCustomerFeedback = 'Create new customer feedback';
exports.searchCustomerFeedback = 'Search customer feedback';
exports.paginationText = "customer feedback's";
exports.emptyPageTitle = 'You have no customer feedback';
exports.emptyPageMessage =
  'You haven’t added any customer feedback yet. Manually input customer feedback about your products here.';
exports.emptyPageButtonLabel = 'Create customer feedback';
exports.errorMessage = 'Something went wrong';
exports.customerFeedbackUrl = '/website-and-emails/customer-feedback/create';
exports.deleteModalTitle = 'Are you sure?';
exports.deleteModalMessage =
  'Are you sure you want to delete this customer feedback.';
exports.deleteModalConfirmColor = 'white';
exports.deleteModalConfirmBackground = '#FF1744';
exports.deleteModalConfirmHoverBackground = 'rgb(240, 25, 73)';
exports.updateModalTitle = 'Please select image';
exports.updateModalMessage = 'Customer feedback cannot be saved without image';
exports.back = 'Back';
exports.edit = 'Edit';
exports.add = 'Add';
exports.red = 'red';
exports.black = 'black';
exports.yes = 'Yes';
exports.no = 'No';
exports.requiredField = 'This field is required';
exports.maxCharacters = 'Maximum 100 characters';

exports.imgPasswordInVisiblePath = 'assets/ic_password_invisible.png';
exports.imgPasswordVisiblePath = 'assets/ic_password_visible.png';
exports.textErrorOnInitialize = "Error on page initialization";
exports.textErrorOnSaveChanges = "Error on save changes"
exports.textTableHeaderId = "#";
exports.textTableHeaderDescription = "Description";
exports.textTableHeaderPosition = "Position";
exports.textTableHeaderCustomerName = "Customer Name";
exports.textTableHeaderActivated = "Activated";
exports.textConfirmDelete = "Yes, delete"

exports.keyError = "error";
exports.keySaveChanges = "SAVECHANGES";
exports.keyCancelChanges = "DISCARDCHANGES";
exports.keyLogin = "EmailAccountLogin";
exports.keySuccess = "success"
// Customizable Area End
