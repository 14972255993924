Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.contentTypeApplicationJson = 'application/json'

exports.getBrandsEndPoint = 'catalogue/brands'
exports.getStaticPagesEndPoint = 'bx_block_static_pages/static_pages'

exports.getMethod = 'GET'

// Customizable Area End
