// Customizable Area Start
import React from "react";
import { Box, Divider, Card } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Location as useStyles } from "./styles/Location.web";
import { Location as LocationDetails } from "../../utilities/src/models/Location";
import ImageCard from "../../utilities/src/components/ImageCard.web";
import { ServiceImage } from "../../utilities/src/models/ServiceImage";

interface Props {
  locationDetails: LocationDetails;
}

const Location: React.FC<Props> = ({ locationDetails }) => {
  const classes = useStyles();

  return (
    <>
      <Box>
        <Typography className={classes.title}>LOCATION</Typography>
        <Divider className={classes.titleDivider} />
      </Box>

      <Box className={classes.locationWrapper}>
        <Box className={classes.location}>
          <Card className={classes.locationCard}>
            <Box>
              <Typography className={classes.locationTitle}>Address</Typography>
              <Typography className={classes.locationBody}>
                {locationDetails.addressLine1}
              </Typography>
              <Typography className={classes.locationBody}>
                {locationDetails.addressLine2}
              </Typography>
              <Typography className={classes.locationBody}>
                {locationDetails.city}
                {
                  Boolean(locationDetails.state) &&
                  `, ${locationDetails.state}`
                }
              </Typography>
              <Typography className={classes.locationBody}>
                {locationDetails.country}
              </Typography>
              <Typography className={classes.locationBody}>
                {locationDetails.postalCode}
              </Typography>
            </Box>
            <Box>
              {locationDetails.shopAvailability.length ? <Typography className={classes.locationTitle}>
                Opening hours
              </Typography> : null}
              {locationDetails.shopAvailability.map(
                ({ weekDay, openingTime, closingTime }, index) => (
                  <Box key={index} className={classes.locationOpeningHours}>
                    <Typography
                      className={`${classes.locationBody} ${classes.locationOpeningHoursDay}`}
                    >
                      {weekDay}
                    </Typography>
                    <Typography
                      className={`${classes.locationBody} ${classes.locationOpeningHoursTime}`}
                    >
                      {openingTime} - {closingTime}
                    </Typography>
                  </Box>
                )
              )}
            </Box>
          </Card>

          <ImageCard
            imageClassName={classes.map}
            image={(locationDetails.location.image as ServiceImage).url}
            onClick={() => window.open(locationDetails.location.mapLink)}
          />
        </Box>
      </Box>
    </>
  );
};

export default Location;
// Customizable Area End