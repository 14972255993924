// Customizable Area Start
import React from "react";
import { IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import StripPaymentCardController from "./StripPaymentCardController.web";
const configJSON = require("./config.js");
const clipboardIcon = require("./assets/clipboard.svg");
const ccaIcon = require("./assets/stripe-icon.png");
import StripPaymentActivateDialog from "./StripPaymentActivateDialog.web";
import "./assets/styles.css"

export default class StripPaymentCard extends StripPaymentCardController {
  render() {
    const {showActivateDialog, payment} = this.state;
    const  api_key = payment?.attributes?.api_key; const  api_secret_key=payment?.attributes?.api_secret_key;
    const isMerchantIDSet = Boolean(payment?.attributes?.api_key !== "n/a");
    return (
      <div className="payment" style={{ display: "contents"}}>
      <div className="payment-card-vertical ccavenue-payment-card">
        <div className="card-image">
          <img src={ccaIcon} className="payment-card-type" data-testid="strip-img-icon" />
        </div>
        <div className="payment-card-details">
          <div className="payment-card-information">
            {isMerchantIDSet ? (
              <div className="payment-card-active">{configJSON.active}</div>
            ) : (
              <div className="payment-card-inactive">{configJSON.notActive}</div>
            )}
            <div className="payment-card-configuration-type" data-testid="strip-title">
              {configJSON.stripTitle}
            </div>
            {isMerchantIDSet ? (
              <>
                <div className="payment-card-card-information">
                  <span className="title text-nowrap">{configJSON.labelApiKey}:</span>
                  <span className="information" title={api_key ?? ""}>
                    {api_key}
                  </span>
                  <IconButton className="clipboard">
                    <img src={clipboardIcon} data-testid="strip-img-clipboard" onClick={this.handleClipboard(api_key)} />
                  </IconButton>
                </div>
                <div className="payment-card-card-information">
                  <span className="title text-nowrap">{configJSON.labelSecretApiKey}:</span>
                  <span className="information" title={api_secret_key ?? ""}>
                    {api_secret_key}
                  </span>
                  <IconButton className="clipboard">
                    <img src={clipboardIcon} data-testid="strip-img-clipboard" onClick={this.handleClipboard(api_secret_key)} />
                  </IconButton>
                </div>
              </>
            ) : (
              <div className="payment-card-card-not-active">{configJSON.useToGetMessage}</div>
            )}
          </div>
          <Button className="card-button" onClick={this.handleClick} data-testid="strip-activate-button">
            {isMerchantIDSet ? configJSON.editAccount : configJSON.activateAccount}
          </Button>
        </div>

        <StripPaymentActivateDialog
          open={showActivateDialog}
          setOpen={this.handleClick}
          defaultValues={payment}
          setDialogState={this.props.setDialogState} 
          showLoader={this.props.showLoader} 
          hideLoader={this.props.hideLoader}  
          showHeader={this.props.showHeader}      
        />
      </div>

      
      </div>
    );
  }
}
// Customizable Area End
