import React from "react";
// Customizable Area Start
import {
  Box,
  Breadcrumbs,
  createStyles,
  Theme,
  withStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import { ExpandMore, NavigateNext as NavigateNextIcon } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import StaticPageController, { StaticPage } from "./StaticPagesController.web";
import { ArrowIcon } from "./assets"
import parser from "html-react-parser";

const configJSON = require("./config.js");

export class StaticPages extends StaticPageController {
  render() {
    const selectedId = this.props.navigation.getParam("id");
    const { classes } = this.props;
    const { staticPages } = this.state;

    const selected = staticPages.find(
      (staticPage: StaticPage) => staticPage.id === selectedId
    );

    const staticAboutUs = staticPages.find(
      (staticPage: StaticPage) =>
        staticPage.attributes.page_type.name === "About us"
    )
    const staticTOS = staticPages.find(
      (staticPage: StaticPage) =>
        staticPage.attributes.page_type.name === "Terms of Service"
    )
    const staticPrivacy = staticPages.find(
      (staticPage: StaticPage) =>
        staticPage.attributes.page_type.name === "Privacy Policy"
    )
    const staticFAQs = staticPages.find(
      (staticPage: StaticPage) =>
        staticPage.attributes.page_type.name === "FAQs" // Privacy Policy
    )

    return (
      <>
        <Box className={classes.breadcrumbsWrapper}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Link to="/" className={classes.breadcrumbLink}>
              <Typography>{configJSON.textHome}</Typography>
            </Link>
            <Link to="/StaticPages" className={classes.breadcrumbLink}>
              <Typography>{configJSON.textHelpCenter}</Typography>
            </Link>
            {selectedId && <Typography className={classes.breadcrumbNonLink}>
              {selected?.attributes?.title}
            </Typography>
            }
          </Breadcrumbs>
        </Box>

        <div className={classes.staticPage}>
          {
            selectedId && <Link to="/StaticPages" className={classes.backButtonArea}>
              <img src={ArrowIcon} alt="icon" />
              {configJSON.textHelpCenter}
            </Link>
          }
          <div className={classes.content}>
            <div className={classes.leftPanel}>
              {staticAboutUs && <div onClick={() => this.navigateToStatic(staticAboutUs)} className={`${classes.leftPanelItem} ${selected?.id === staticAboutUs.id ? classes.leftPanelItemSelected : ""} `}>
                {configJSON.textAboutUs}
              </div>}
              {staticFAQs && <div onClick={() => this.navigateToStatic(staticFAQs)} className={`${classes.leftPanelItem} ${selected?.id === staticFAQs.id ? classes.leftPanelItemSelected : ""} `}>
                {configJSON.textFAQs}
              </div>}
              {staticPrivacy && <div onClick={() => this.navigateToStatic(staticPrivacy)} className={`${classes.leftPanelItem} ${selected?.id === staticPrivacy.id ? classes.leftPanelItemSelected : ""} `}>
                {configJSON.textPrivacyPolicy}
              </div>}
              {staticTOS && <div onClick={() => this.navigateToStatic(staticTOS)} className={`${classes.leftPanelItem} ${selected?.id === staticTOS.id ? classes.leftPanelItemSelected : ""} `}>
                {configJSON.textTOS}
              </div>}
            </div>
            {!selectedId && <div className={classes.leftPanelMobile}>
              {staticAboutUs && <div onClick={() => this.navigateToStatic(staticAboutUs)} className={`${classes.leftPanelItem} ${selected?.id === staticAboutUs.id ? classes.leftPanelItemSelected : ""} `}>
                {configJSON.textAboutUs}
              </div>}
              {staticFAQs && <div onClick={() => this.navigateToStatic(staticFAQs)} className={`${classes.leftPanelItem} ${selected?.id === staticFAQs.id ? classes.leftPanelItemSelected : ""} `}>
                {configJSON.textFAQs}
              </div>}
              {staticPrivacy && <div onClick={() => this.navigateToStatic(staticPrivacy)} className={`${classes.leftPanelItem} ${selected?.id === staticPrivacy.id ? classes.leftPanelItemSelected : ""} `}>
                {configJSON.textPrivacyPolicy}
              </div>}
              {staticTOS && <div onClick={() => this.navigateToStatic(staticTOS)} className={`${classes.leftPanelItem} ${selected?.id === staticTOS.id ? classes.leftPanelItemSelected : ""} `}>
                {configJSON.textTOS}
              </div>}
            </div>}
            {
              selectedId ?
                <div className={classes.rightPanel}>
                  <div className={classes.pageTitle}>{selected?.attributes.title}</div>
                  {selected?.attributes?.page_type?.name === "FAQs" ? <div className={classes.faqs}>
                    {selected?.attributes.description?.questions?.filter(question => question.attributes.status).map((question) => <div className={classes.faqsSmall}>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <div className={classes.faqTitle}>{question.attributes.title}</div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className={classes.faqContent}>{parser(question.attributes.content)}</div>
                        </AccordionDetails>
                      </Accordion>
                    </div>)}
                    {selected?.attributes.description?.questions?.filter(question => question.attributes.status).map((question) => <div className={classes.faqsWide}>
                      <div className={classes.faqTitle}>{question.attributes.title}</div>
                      <div className={classes.faqContent}>{parser(question.attributes.content)}</div>
                    </div>)}
                  </div> : parser(selected?.attributes?.description?.content || "")}
                </div> :
                <div className={classes.rightPanelEmpty} />
            }
          </div>
        </div>
      </>
    );
  }
}

export const styles = (theme: Theme) =>
  createStyles({
    staticPage: {
      padding: "66px 80px",
      [theme.breakpoints.down("sm")]: {
        padding: "21px 12px",
      }
    },
    backButtonArea: {
      marginBottom: 20,
      fontSize: 24,
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: "0px",
      textAlign: "left",
      color: "#364F6B",
      display: "flex",
      gap: 12,
      cursor: "pointer",
      textDecoration: "none",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    content: {
      display: "flex",
      gap: 20,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      }
    },
    select: {
      border: "1px solid #364F6B33",
      padding: "18.5px 14px"
    },
    leftPanel: {
      display: "flex",
      flex: 1,
      padding: "0 20px",
      borderRadius: 5,
      backgroundColor: "#FFFFFF",
      flexDirection: "column",
      "& *": {
        borderBottom: "1px solid #E8E8E8",
      },
      "& *:last-child": {
        borderBottom: "none",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      }
    },
    leftPanelMobile: {
      display: "none",
      flex: 1,
      padding: "0 20px",
      borderRadius: 5,
      backgroundColor: "#FFFFFF",
      flexDirection: "column",
      "& *": {
        borderBottom: "1px solid #E8E8E8",
      },
      "& *:last-child": {
        borderBottom: "none",
      },
      [theme.breakpoints.down("sm")]: {
        display: "flex",
      }
    },
    leftPanelItem: {
      color: "#000000",
      fontSize: 14,
      lineHeight: "16px",
      padding: "20px 0",
      cursor: "pointer"
    },
    leftPanelItemSelected: {
      fontWeight: 500,
      color: "#6200EA"
    },
    rightPanel: {
      flex: 3,
      padding: 40,
      borderRadius: 5,
      backgroundColor: "#FFFFFF",
      "& img": {
        maxWidth: "100%"
      }
    },
    pageTitle: {
      fontFamily: "Rubik",
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: "0px",
      textAlign: "left",
      color: "#364F6B",
      marginBottom: 40
    },
    rightPanelEmpty: {
      flex: 3,
    },
    faqs: {
      display: "flex",
      flexDirection: "column",
      gap: 30
    },
    faqsWide: {
      display: "block",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      }
    },
    faqsSmall: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      }
    },
    faqTitle: {
      fontSize: 20,
      fontWeight: 500,
      color: "#000000"
    },
    faqContent: {

    },
    breadcrumbsWrapper: {
      margin: theme.spacing(5, 10, 1),
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(2, 1.25, 1),
        display: "flex",
      },
    },
    breadcrumbLink: {
      color: "#3FC1CB",
      textDecoration: "none",
    },
    breadcrumbNonLink: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  });

export default withStyles(styles)(StaticPages);
// Customizable Area End
