// Customizable Area Start
import React, { useEffect, useRef, useState } from "react";
import { Typography, TextField, Link, Container, Slide, Box, CardMedia, Button, Grid } from "@material-ui/core";
import { useStyles } from "../styles/Service.web";
import { useFormik } from "formik";
import * as yup from 'yup';
import { builder, arrow, add_plus } from "../assets";

interface FormValues {
  category_name: string;
  category_img: File | null;
}

const validationSchema = yup.object().shape({
  category_name: yup.string().required('Category name is required'),
  category_img: yup
    .mixed()
    .required('Image is reqiured')
    .test('fileValidation', 'Image size should not be grater than 20 MB', (value) => {
      if (value && value instanceof File) {
        const validFormats = ['image/jpeg', 'image/gif', 'image/png', 'image/tiff', 'image/raw', 'image/psd'];
        const excludedFormats = ['image/x-icon'];
        const isValidFormat = validFormats.includes(value.type);
        const isExcludedFormat = excludedFormats.includes(value.type);

        if (isExcludedFormat) {
          return false;
        }

        const maxSizeBytes = 20 * 1024 * 1024;

        const isValidSize = value.size <= maxSizeBytes;

        return isValidFormat && isValidSize;
      }
      return false;
    }),
});

interface Props {

  backeditlist: () => void;
  editimages: (id: any, values: FormValues) => void;
  id: any;
  name: any;
  image: any;
  removeImage: any;
  isLoading: any;
  isLoadingTrue:any;
}

const Editcategoryform: React.FC<Props> = ({

  backeditlist,
  editimages,
  id,
  name,
  image,
  removeImage,
  isLoading: any,
  isLoadingTrue,
}) => {
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const resetButtonRef = useRef<HTMLButtonElement>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [isError, setIsError] = useState("Image is required");
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [isCategoryNameTouched, setIsCategoryNameTouched] = useState(false); 
  const [isInputFieldClicked, setIsInputFieldClicked] = useState(false); 

  useEffect(() => {
    setSelectedCategory(name)
  }, [])
  const initialValues = {
    category_name: name || "",
    category_img: null
  };
  const formik = useFormik<FormValues>({
    initialValues,
    onSubmit: values => {
      editimages(id, values);
    },
    validationSchema,
  });

  const values = {
    images: ['image1.jpg', 'image2.jpg', 'image3.jpg']
  };

  const classes = useStyles({ hasImages: values.images.length > 0 });

  const isFormChanged =
    JSON.stringify(values) !== JSON.stringify(initialValues);

  const saveChanges = () => {
    submitButtonRef.current && submitButtonRef.current.click();
  };
  const discardChanges = () => {
    resetButtonRef.current && resetButtonRef.current.click();
    setSelectedCategory(name)
  };


  const { resetForm } = formik;

  const textCategoryBtnStyle: React.CSSProperties = {
    color: "#000000",
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0px",
    textAlign: "left",
    marginBottom: 8
  };

  const imageTitle: React.CSSProperties = {
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#3C3E49",

  }
  const categoryTitleText: React.CSSProperties = {
    color: "#3C3E49",
    fontFamily: "Rubik",
    fontSize: "28px",
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "-0.02em",
    textAlign: "left",
    marginTop: '5px'
  }




  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCategory(event.target.value);
    setIsCategoryNameTouched(true);
    setIsInputFieldClicked(true);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    const maxSizeBytes = 20 * 1024 * 1024;
    const allowedFormats = [
      "image/jpeg",
      "image/gif",
      "image/png",
      "image/tiff",
      "image/raw",
      "image/psd",
    ];

    if (file != null) {
      const fileExtension = file.name.split(".").pop()?.toLowerCase();

      if (file.size > maxSizeBytes) {
        setIsError("Image size should not be greater than 20MB");
        setImagePreview(null);
      } else if (fileExtension === "ico") {
        setIsError(".ico format images are not allowed");
        setImagePreview(null);
      } else if (allowedFormats.includes(file.type)) {
        setIsError("Image is required");
        const reader = new FileReader();
        reader.onload = () => {
          setImagePreview(reader.result as string);
        };
        reader.readAsDataURL(file);
        if (file && file.type.startsWith("image/")) {
          setSelectedImage(file);
        }
      } else {
        setIsError(
          "Image should be a JPEG, PNG, GIF, TIFF, RAW, PSD, or JPG type"
        );
        setImagePreview(null);
      }
    } else {
      setIsError("Image is required");
      setImagePreview(null);
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if(selectedCategory!="")
    {
      if(imagePreview!=null|| image.length>0)
      {
        isLoadingTrue();
        console.log("Category:", selectedCategory);
        console.log("Image:", selectedImage);
        const values = {
          category_name: selectedCategory,
          category_img: selectedImage,
        };
        editimages(id, values);
  
      }
    }
    
  
  };

  const getStatusText = () => {
    if (image.length > 0) {
      return (
        <div id="category_img" style={webStyle.imgSectionDiv}>
          <label htmlFor="imageInput">
            <img
              src={
                selectedImage
                  ? window.URL.createObjectURL(selectedImage)
                  : image
              }
              alt="Category"
              style={webStyle.imgStyle}
            />
          </label>
          <input
            type="file"
            accept="image/*"
            id="addImageInput"
            name="category_img"
            style={{ display: "none" }}
            onChange={handleImageChange}
            required
          />
          <Link
            type="reset"
            onClick={() => {
              removeImage();
              setSelectedImage(null);
            }}
            style={webStyle.removeButton}
          >
            Remove
          </Link>
        </div>
      );
    } else if (selectedImage != null && imagePreview != null) {
      return (
        <div id="category_img" style={webStyle.imgSectionDiv}>
          <label htmlFor="imageInput">
            <img
              src={
                selectedImage && imagePreview
                  ? window.URL.createObjectURL(selectedImage)
                  : image
              }
              alt="Category"
              style={webStyle.imgStyle}
            />
          </label>
          <input
            type="file"
            accept="image/*"
            id="addImageInput"
            name="category_img"
            style={{ display: "none" }}
            onChange={handleImageChange}
            required
          />
          <Link
            type="reset"
            onClick={() => {
              setSelectedImage(null);
              setImagePreview(null);
              removeImage();
            }}
            style={webStyle.removeButton}
          >
            Remove
          </Link>
        </div>
      );
    } else {
      return (
        <div id="outlined-full-width"
          style={{
            width: "100%",
            height: "100%",
            margin: "2px",
            border: imagePreview ? "none" : "2px dotted #C0C3CE",
            display: "flex",
            flexDirection: "column",
            alignItems: imagePreview ? "flex-start" : "center",
            justifyContent: "center",
            borderRadius: imagePreview ? "none" : "10px",
            position: "relative",
            overflow: imagePreview ? "visible" : "hidden",
          }}
        >
          {imagePreview ? (
            <>
              <div style={webStyle.imagePreviewStyle}>
                <img
                  src={imagePreview}
                  alt="Category Preview"
                  style={webStyle.imgplusStyle}
                />
              </div>
              <div onClick={removeImage} style={webStyle.removeTextStyle}>
                Remove
              </div>
            </>
          ) : (
            <div style={webStyle.imgMainStyle}> 
              <label htmlFor="addImageInput">
                <img
                  src={add_plus}
                  style={{
                    width: "16px",
                    height: "16px",
                    cursor: "pointer",
                  }}
                  alt="Add Image"
                />
              </label>
              <input
                id="addImageInput"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                name="category_img"
                onChange={handleImageChange}
                required
              />

              <Typography className={classes.AddImagetitle}>Add Image</Typography>
              <Typography className={classes.mbText}>Max 20MB</Typography>
            </div>
          )}
        </div>
      );
    }
  };


  return (
    <>
      <Slide direction="down" in={true} mountOnEnter unmountOnExit style={{left: 0, right: 0}}>
        <Box className={classes.formActionsWrapper}>
          <Box className={classes.formActions}>
            <CardMedia component="img" src={builder} className={classes.logo} />
            <Box>
              <Button variant="text" onClick={discardChanges} className={classes.discardChangesbutton}>
                Discard changes
              </Button>
              <Button variant="contained" onClick={handleSubmit} className={classes.saveChangesbutton}>
                Save changes
              </Button>
            </Box>
          </Box>
        </Box>
      </Slide>
      <Container maxWidth="lg" style={webStyle.containerMain}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div style={webStyle.backButton}
              onClick={backeditlist}
            >
              <img src={arrow} alt="arrow" style={webStyle.arrowStyleimg} />
              <Typography style={webStyle.backTitleText} >
                Back
              </Typography>

            </div>
            <div className="col">
              <Typography style={categoryTitleText} >
                Edit a category
              </Typography>
            </div>

            <div style={webStyle.mainSection}>
              <div className="row">
                <form onSubmit={handleSubmit}>
                  <div style={webStyle.firstTitlemain} >
                    <Typography style={webStyle.titleTextParag}>
                      Category details
                    </Typography>
                    <div style={webStyle.secondTitle}>
                      <Typography style={textCategoryBtnStyle} >
                        Category name *
                      </Typography>
                      <TextField
                        type="text"
                        id="category"
                        style={webStyle.inputTitleParag}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        placeholder="Category name"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        required
                      />
                    </div>
                    {isCategoryNameTouched && selectedCategory === "" && (
                      <div className={classes.formErrorText}>
                        Category name is required
                      </div>
                    )}   
                    <div style={webStyle.imgSectionMain}>
                      <Typography style={imageTitle}>
                        Image *
                      </Typography>
                      {getStatusText()}
                    </div>
                    <Button
                    ref={resetButtonRef}
                    className={classes.hidden}
                    onClick={() => resetForm()}
                  />
                  <Button
                    ref={submitButtonRef}
                    type="submit"
                    className={classes.hidden}
                  />
                  </div>
                 {imagePreview == null && image.length === 0 && (
                    <div className={classes.formErrorText} style={{paddingLeft:'44px',marginTop:'-9px'}}>
                      {isError}
                    </div>
                  )}
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Editcategoryform;


const webStyle = {
  containerMain: {
    backgroundColor: '#FAFAFA',
    marginBottom: '50px'
  },
  removeButton: {
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#3C3E49",
    textDecoration: "underline",
    cursor: "pointer",
  },
  backButton: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginTop: '52px'
  },
  titleTextParag: {
    fontFamily: "Rubik",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "28px",
    letterSpacing: "-0.02em",
    textAlign: "left" as "left",
  },
  inputTitleParag: {
    margin: 1,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  mainSection: {
    border: "2px solid #ECEDF0",
    margin: "50 auto",
    maxWidth: "684px",
    height: "477px",
    top: "209px",
    left: "496px",
    borderRadius: "3px",
    width: "100%",
    backgroundColor: '#FFFFFF'
  },
  firstTitlemain: {
    padding: "40px"
  },
  secondTitle: {
    marginTop: "20px",
  },
  imgSectionDiv: {
    width: "100%",
    height: "100%",
    marginTop: "12px",
    display: "flex",
    flexDirection: "column" as "column",
  },
  imgSectionMain: {
    marginTop: "20px",
    height: "177px",
    width: "604px"
  },
  imgStyle: {
    width: "155px",
    height: "158px",
    borderRadius: "4px"
  },
  backTitleText: {
    marginLeft: "4px",
    fontFamily: 'Rubik',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0em',
    textAlign: 'left' as 'left',
    color: '#676B7E',
  },
  arrowStyleimg: {
    width: '12px',
    height: '12px'
  },
  removeTextStyle: {
    left: "10px",
    cursor: "pointer",
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0px",
    textAlign: "left" as "left",
    color: "#3C3E49",
  },
  imgplusStyle: {
    width: "155px",
    height: "158px",
    borderRadius: "4px",
  },
  imagePreviewStyle: {
    position: "relative" as "relative",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start" as "flex-start",
  },
  imgMainStyle: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center" as "center",
    justifyContent: "center" as "center",
    width: "100%",
    height: "100%",
  }

};
// Customizable Area End