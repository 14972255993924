import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { EmailSettingsMap, Tab } from "./Types";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { onMaximizeType } from "../../ss-cms-common-components/src/Editor-v4/Editor";
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import {
  generateRequestMessage,
  showError,
  isTokenExpired,
  clearStorageData
} from "../../ss-cms-common-components/src/Utilities/Utilities";

const configJSON = require("./config.js");

export type Props = DialogProps &
  withLoaderProps &
  withHeadeActionBarProps & {
    navigation: {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

export interface DefaultEmailSettingsType {
  [key: string]: string;
}

interface S {
  // Customizable Area Start
  redirected: boolean;
  selectedTab: number;
  headerTabs: Tab[];
  content?: string;
  contentPlain?: string;
  title: string;
  titleInit: string;
  event_name: string;
  defaultEmailSettings: DefaultEmailSettingsType;
  editorMaximized: boolean;
  titleError: boolean;
  contentError: boolean;
  emailSubject: string;
  emailSubjectError: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export const normalizeContentWithDefaultEmailSettings = (
  content: string,
  defaultEmailSettings: DefaultEmailSettingsType
) => {
  let normalizedContent = content;
  if (defaultEmailSettings.logo) {
    normalizedContent = normalizedContent.replace(
      /%{brand_logo}/gi,
      `<img style='width: 100px;height:100px;' src='${defaultEmailSettings?.logo}' />`
    );
  }

  if (defaultEmailSettings.brand_name) {
    normalizedContent = normalizedContent.replace(
      /%{brand_name}/gi,
      defaultEmailSettings?.brand_name
    );
  }
  return normalizedContent;
};
// Customizable Area End

export default class EmailTemplatesCreateController extends BlockComponent<
  Props,
  S,
  SS
> {
  defaultMessageId = "";
  detailMessageId: string = "";
  settingsMessageId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ActionMessageFromToaster),
    ];

    this.state = {
      redirected: false,
      selectedTab: 0,
      headerTabs: [],
      content: undefined,
      contentPlain: "",
      titleError: false,
      contentError: false,
      title: "",
      titleInit: "",
      event_name: "",
      defaultEmailSettings: {},
      editorMaximized: false,
      emailSubject: "",
      emailSubjectError: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // this.callGetValidationApi();
    // Customizable Area Start
    this.getEmailDetails();
    this.getDefaultEmailSettings();
    // Customizable Area End
  }

  // Customizable Area Start
  goToEmailTemplates = () => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "AdminEmailTemplates"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(to);
    this.setState({ redirected: true })
  };

  getDefaultEmailSettings = async () => {
    const message = await generateRequestMessage(
      configJSON.defaultEmailSettingsEndpoint,
      configJSON.httpGetMethod
    );
    this.defaultMessageId = message.messageId;
    this.send(message);
  };

  getCurrentId = () => this.props.navigation.getParam("id") || "";

  getEmailDetails = async () => {
    this.props.showLoader();
    const detailMessage = await generateRequestMessage(
      `${configJSON.emailSettingsEndpoint}/${this.getCurrentId()}`,
      configJSON.httpGetMethod
    );

    this.detailMessageId = detailMessage.messageId;
    this.send(detailMessage);
  };

  handleDeviceResolution = (selectedTab: number) => {
    let selectedTabs: string;
    if (selectedTab === 0) {
      selectedTabs = configJSON.deviceTypeDesktop;
    } else {
      if (selectedTab === 1) {
        selectedTabs = configJSON.deviceTypeTablet;
      } else {
        selectedTabs = configJSON.deviceTypeMobile;
      }
    }
    return selectedTabs;
  };

  saveChanges = async () => {
    const requestMessage = await generateRequestMessage(
      `${configJSON.emailSettingsEndpoint}/${this.getCurrentId()}`,
      configJSON.httpPatchMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        title: this.state.title,
        content: this.state.content,
        subject: this.state.emailSubject,
      })
    );

    this.settingsMessageId = requestMessage.messageId;
    this.props.showLoader();
    this.send(requestMessage);
  };

  receive = (from: string, message: Message) => {
    if (isTokenExpired(message)) {
      return this.logoutAndNavigateLogin();
    }
    if (message.id === getName(MessageEnum.ActionMessageFromToaster)) {
      const type = message.getData(
        getName(MessageEnum.ActionMessageFromToasterMessage)
      );
      if (type === "SAVECHANGES") {
        if (
          !this.state.contentPlain ||
          this.state.contentPlain === "\n" ||
          !this.state.title ||
          !this.state.emailSubject
        ) {
          const titleError = !this.state.title;
          const contentError =
            !this.state.contentPlain || this.state.contentPlain === "\n";
          const emailSubjectError = !this.state.emailSubject;

          return this.setState({
            titleError,
            contentError,
            emailSubjectError,
          });
        }
        this.saveChanges();
      }
      if (type === "DISCARDCHANGES") {
        window.location.reload();
      }
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.messageSettingsCall(message);
      this.messageDetailCall(message);
      this.messageDefaultCall(message);
    }
  };

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      configJSON.routeEmailLogin
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  messageSettingsCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (apiRequestCallId === this.settingsMessageId) {
      if (responseJson.errors) {
        return showError(
          configJSON.textErrorOnSaveChanges,
          responseJson,
          this.props.hideLoader,
          this.props.setDialogState
        );
      }
      this.props.hideLoader();
      this.props.showHeaderBar({ type: "success" });
      this.goToEmailTemplates();
    }
  };

  messageDetailCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (apiRequestCallId === this.detailMessageId) {
      if (responseJson.errors) {
        return showError(
          configJSON.textErrorOnPageInitialization,
          responseJson,
          this.props.hideLoader,
          this.props.setDialogState
        );
      }
      this.setState({
        content: responseJson.data.attributes.content,
        contentPlain: responseJson.data.attributes.content,
        titleInit: responseJson.data.attributes.title,
        title: responseJson.data.attributes.title,
        event_name: responseJson.data.attributes.event_name,
        emailSubject: responseJson.data.attributes.subject,
      });
      this.props.hideLoader();
    }
  };

  messageDefaultCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (apiRequestCallId === this.defaultMessageId) {
      if (responseJson.errors) {
        return showError(
          configJSON.textErrorOnLoadingDefaultSettings,
          responseJson,
          this.props.hideLoader,
          this.props.setDialogState
        );
      }
      this.setState({
        defaultEmailSettings: responseJson?.data?.[0]?.attributes || {},
      });
    }
  };

  async componentWillUnmount() {
    super.componentWillUnmount();
    if (this.state.editorMaximized) {
      this.props.showHeaderBar({ type: "whitescreen" });
      window.location.reload();
    }
  }

  handleTabChange = (event: React.ChangeEvent<{}>, selectedTab: number) => {
    this.setState({ selectedTab });
  };

  handleTitleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    this.setState({ title: e.target.value });
    this.setState({ titleError: false });
    this.props.showHeaderBar({ message: "" });
  };

  handleEmailSubjectChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    this.setState({ emailSubject: e.target.value, emailSubjectError: false });
    this.props.showHeaderBar({ message: "" });
  };

  handleBackButtonClick = () => {
    this.goToEmailTemplates();
  };

  handleEditorChange = (content: string, contentPlain?: string) => {
    this.setState({ content });
    this.setState({ contentPlain });
    this.setState({ contentError: false });
    this.props.showHeaderBar({ message: "" });
  };

  handleMaximize: onMaximizeType = (event) => {
    if (Number(event.data) === 1) {
      this.setState({ editorMaximized: true });
    } else {
      this.setState({ editorMaximized: false });
    }
  };
  // Customizable Area End
}
