import { Theme, makeStyles } from "@material-ui/core";

export const FilterItems = makeStyles((theme: Theme) => ({
  drawer: {
    width: "100%",
  },
  drawerCloseButton: {
    fontSize: "32px",
  },
  appbar: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px #F5F5F5",
    color: "#000000",
  },
  toolbar: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
      minHeight: "58px",
      backgroundColor: "#F9FAFB",
      padding: theme.spacing(0, 3),
      margin: theme.spacing(0),
    },
  },
  header: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  drawerContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    backgroundColor: "#FAFAFA",
    padding: theme.spacing(2.5),
  },
  filterActions: {
    display: "flex",
    gap: theme.spacing(1.25),
    flex: 1,
    alignItems: "flex-end",
    justifyContent: "center",
    paddingTop: theme.spacing(5),
  },
  applyNowButton: {
    backgroundColor: "#3FC1CB",
    textTransform: "none",
    flex: "0 0 50%",
    color: "#FFFFFF",
  },
  resetButton: {
    backgroundColor: "#364F6B",
    textTransform: "none",
    flex: "0 0 50%",
    color: "#FFFFFF",
  },
}));
