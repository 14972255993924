// Customizable Area Start
import React from "react";
import { Box, Step, StepLabel, Stepper, withStyles } from "@material-ui/core";
import { createTheme, Theme } from "@material-ui/core/styles";
import AppointmentmanagementController , {Props} from "./AppointmentmanagementController"
import './cal.css';

const steps = [
  'Date & time',
  'Personal Details',
  'Payment',
];

const theme = createTheme({});

const styles = (theme: Theme) => ({
  stepperClass: {
    maxWidth: '400px',
    minWidth: '700px',
    margin: "auto",
    background: "transparent !important",
    [theme.breakpoints.down('sm')]: {
      maxWidth: '400px',
      minWidth: 'auto',
      margin: "auto",
      padding: "10px 0px"
    },
  },
});


export class AppointmentStiper extends AppointmentmanagementController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { activeStep } = this.props

    return (
      <Box>
        <Stepper activeStep={activeStep} alternativeLabel className={this.props.classes.stepperClass}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    );
  }
}

export default withStyles(styles)(AppointmentStiper);


// Customizable Area End