import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import BlockHelpers from "../../utilities/src/BlockHelpers";
import { RefObject } from "react";
export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}


interface OrderSummary {
  id: string;
  type: string;
  attributes: {
    order_number: string;
    appointment_date: string;
    order_date: string;
    status: string;
    total: number;
    service: {
      title: string;
      price: number;
      duration: number;
    };
    service_images: {
      id: number;
      url: string;
    };
    payment_mode:string;
    customer: {
      id: number;
      full_phone_number: string;
      email: string;
      full_name: string;
      created_at: string;
      updated_at: string;
      appointment_id: number;
      comment: string | null;
    };
    billing_address: {
      id: number;
      country: string;
      city: string;
      zip_code: string;
      state: string;
      address_line_1: string;
      address_line_2: string;
      flat_number: string | null;
      created_at: string;
      updated_at: string;
      appointment_id: number;
    };
  };
}

interface S {
  // Customizable Area Start
  token: string;
  categoriesArray: any;
  category: string;
  subCategory: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  selectedCategoryID: any;
  orderList_Data: any;
  isDialogOpen: boolean;
  selectedOrderData: any;
  userDetailData: any;
  searchQuery: string;
  searchResults: any[];
  selectedValue: string | null;
  orderSummary: OrderSummary | null;
  isLoading:boolean;
  isOrderBlank:boolean;
  isFormChanged:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OrderManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  orderCategoryAddApiCallId: any;
  orderCategoryGetApiCallId: any;
  searchCategoryGetApiCallId: any;
  orderStatusGetApiCallId: any;
  appointmentGetApiCallId:any;
  debounceTimer: NodeJS.Timeout | null = null;


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      categoriesArray: [],
      category: "",
      subCategory: "",
      isVisible: false,
      dropdownCategoryStatus: false,
      activeModalType: "",
      selectedCategoryID: [],
      orderList_Data: null,
      isDialogOpen: false,
      selectedOrderData: null,
      userDetailData: [],
      searchQuery: '',
      searchResults: [],
      orderSummary: null,
      selectedValue: null,
      isLoading:false,
      isOrderBlank:false,
      isFormChanged:false,
      
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
// Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();

    this.getListOrder();
   

  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
  
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
  
      if (responseJson.data) {
        if (apiRequestCallId === this.orderCategoryGetApiCallId) {
          this.handleApiResponse(responseJson.data, true);
        } else if (apiRequestCallId === this.orderCategoryAddApiCallId) {
          this.handleApiResponse(responseJson.data, false);
        } else if (apiRequestCallId === this.searchCategoryGetApiCallId) {
          this.handleApiResponse(responseJson.data, false);
        } else if (apiRequestCallId === this.orderStatusGetApiCallId) {
          this.updateState(responseJson.data);
          this.handleBack();
        } else if (apiRequestCallId === this.appointmentGetApiCallId) {
          this.updateState(responseJson.data);
        }
      } else if (responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ isLoading: false });
      }

    }
  }
  
  handleApiResponse(data: any, isOrderBlank: boolean) {
    this.setState({ isOrderBlank });
    this.setState({ orderList_Data: data });
    this.setState({ isLoading: false });
  }
  




  filterOrder = (values: any) => {
   
    const header = {};

    let complete = ""
    let cancelled = ""
    let in_progress = ""
    let confirmed = ""
    let refunded = ""
    let placed = ""


    let apiEndPoint = "bx_block_appointment_management/filter_order?";
    
    const checkboxValue = values.checkboxes

    if (values.from_date != null && values.from_date != undefined) {
      apiEndPoint += "[start_date]=" + values.from_date;
    }

    if (values.to_date != null && values.to_date != undefined) {
      apiEndPoint += "&[end_date]=" + values.to_date;
    }


    if (checkboxValue.Confirmed) {
      confirmed = "confirmed"

      apiEndPoint += "&[status][]=" + confirmed;
    }

    if (checkboxValue.Completed) {
      complete = "completed"
      apiEndPoint += "&[status][]=" + complete;
    }


    if (checkboxValue.InProgress) {
      in_progress = "in_progress"
      apiEndPoint += "&[status][]=" + in_progress;
    }
    
    if (checkboxValue.Cancelled) {
      cancelled = "cancelled"
      apiEndPoint += "&[status][]=" + cancelled;
    }

    if (checkboxValue.Refunded) {
      refunded = "refunded"
      apiEndPoint += "&[status][]=" + refunded;
    }

    if (checkboxValue.placed) {
      placed = "placed"
      apiEndPoint += "&[status][]=" + placed;
    }

    if (values.integerField1 != null && values.integerField1 != undefined) {
      apiEndPoint += "&[total][from]=" + values.integerField1;
    }

    if (values.integerField2 != null && values.integerField2 != undefined) {
      apiEndPoint += "&[total][to]=" + values.integerField2;
    }

    this.orderCategoryAddApiCallId = BlockHelpers.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: apiEndPoint,
      header,
      body: null
    });

  };


  serach_orders = (searchtxt: any) => {
    const header = {};
    const apiEndPoint = `bx_block_appointment_management/search_order?search_query=${searchtxt}`;
    this.searchCategoryGetApiCallId = BlockHelpers.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: apiEndPoint,
      header,
      body: null
    });

  }


  serach_orders_list = (queryRef: RefObject<HTMLInputElement>) => {
   
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      const query = (queryRef.current && queryRef.current.value) || "";
  
      this.setState({isLoading:true})
    if(query.toString().length>0)
    {
      const header = {};
      const apiEndPoint = `bx_block_appointment_management/search_order?search_query=${query}`;
      this.searchCategoryGetApiCallId = BlockHelpers.callApi({
        method: configJSON.listOfOrdersMethod,
        endPoint: apiEndPoint,
        header,
        body: null
      });
    }else if(query.toString().length==0)
    {
      this.getListOrder()
    }
      clearTimeout(this.debounceTimer!);
    }, 800);


  }

  getListOrder = () => {
    this.setState({isLoading:true})
    const header = {};
    this.orderCategoryGetApiCallId = BlockHelpers.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: configJSON.orderlistEndPoint,
      header
    });
  };

   getList=(id:any)=>{
    localStorage.setItem("orderID",id.toString())
    this.props.navigation.navigate("OrderMangementList/OrderManagementDetails")
  
}


  orderStatus = (id:any,status:any) => {
 this.setState({isLoading:true})
    const header = {};
    const body = new FormData();
    body.append("[appointment][status]", status.selectedValue+"");
    
    this.orderStatusGetApiCallId = BlockHelpers.callApi({
      method: configJSON.createOrderMethod,
      endPoint: configJSON.orderstatusApiEndPoint + `/${id}`,
      header,
      body
    });
  };

 


  appointment_summary = (id:any) => {
    const header = {};
    const apiEndPoint = `bx_block_appointment_management/appointment_summary?id=${id}`;
    this.appointmentGetApiCallId = BlockHelpers.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: apiEndPoint,
      header,
      body: null
    });

  }


  handleBack = () => {
    this.props.navigation.navigate("OrderMangementList")
  };

  download = () =>{
    window.open("https://ssserviceinternal-365523-ruby.b365523.dev.eastus.az.svc.builder.cafe/bx_block_appointment_management/download_csv.csv", '_blank','noopener');
  }

  handleStatusChange = (newValue:any) =>{
    this.setState({ selectedValue: newValue });
  }

  updateState = (response:any) =>{
    this.setState({orderSummary:response});
    this.setState({ selectedValue: response.attributes.status });
    this.setState({ isLoading: false });
  } 




  // Customizable Area End
}
