// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import BlockHelpers from "../../utilities/src/BlockHelpers";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  generateRequestMessage,
  isTokenExpired,
  clearStorageData,
} from "../../ss-cms-common-components/src/Utilities/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

type FormValues = {
  category_name: string;
  category_img: File | null;
};

interface S {
  token: string;
  categoriesArray: any;
  category: string;
  subCategory: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  selectedCategoryID: any;
  showChild: string;
  category_name: string;
  category_img: string;
  category_id: number;
  categories_Data: any;
  selectedIds: any;
  isLoading:boolean;
  isError:boolean;
  msgError:string;
  deleteError:string;
  isSuccessfulyDelete:boolean
}

interface SS {
  id: any;
}

export default class CategoriessubcategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  updateCategoryEditApiCallId: any;
  deleteCategoryDelApiCallId: any;
  getCategoryAddApiCallId: any;
  getCategoryGetApiCallId: any;
  getCategoriesApiCallId: any;
  deleteCategoriesApiCallId: any;
  deleteSubCategoriesApiCallId: any;
  addCategoryApiCallId: any;
  addSubCategoryApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    
  
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      categoriesArray: [],
      category: "",
      subCategory: "",
      isVisible: false,
      dropdownCategoryStatus: false,
      activeModalType: "",
      selectedCategoryID: [],
      showChild: "imagelist",
      category_name: "",
      category_img: "",
      category_id: 0,
      categories_Data: [],
      selectedIds: [],
      isLoading:false,
      isError:false,
      msgError:"",
      deleteError:"",
      isSuccessfulyDelete:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
  
    this.getListCategory()
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
    }
    this.setState({isLoading:false})
    runEngine.debugLog("Message Received", message);
  
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getCategoryAddApiCallId && !responseJson.error) {
      this.setState({ showChild: "imagelist" });
      this.getListCategory();
      return;
    }
  
    if (apiRequestCallId === this.getCategoryGetApiCallId && responseJson) {
      this.setState({
        categories_Data: responseJson.data[0].data.attributes
      });
      
      if(responseJson.data[0].data.attributes!=null && responseJson.data[0].data.attributes!=undefined)
      {
        return this.setState({ showChild: "imagelist" }); 
      }
      return this.setState({ showChild: "" }); 
      
    }
  
    if (apiRequestCallId === this.deleteCategoryDelApiCallId && !responseJson.error) {
     
      this.setState({isSuccessfulyDelete:true})
      setTimeout(() => {
        this.setState({isSuccessfulyDelete:false})
      }, 4000);
     
      this.getListCategory();
      return;
    }
  
    if (apiRequestCallId === this.updateCategoryEditApiCallId && !responseJson.error) {
      this.getListCategory();
      this.setState({ showChild: "imagelist" });
    }
    else if (responseJson.error) {
     // alert(responseJson.error)
      this.setState({ isError: true });
      this.setState({msgError:responseJson.error})
      setTimeout(() => {
       
         this.setState({ isError: false });
       }, 4000);
    }
  }
  
  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  setCategoryTxt = (text: string) => {
    this.setState({ category: text });
  };
  setSubCategoryTxt = (text: string) => {
    this.setState({ subCategory: text });
  };
  clickCategory = (item: any, Index: number) => {
    let array = this.state.categoriesArray;
    let idarray = this.state.selectedCategoryID;
    let index = idarray.indexOf(item.attributes.id);

    if (index > -1) {
      idarray.splice(index, 1);
      array[Index].Check = false;
      this.setState({ categoriesArray: array });
    } else {
      idarray.push(item.attributes.id);
      array[Index].Check = true;
      this.setState({ categoriesArray: array });
      this.setState({ selectedCategoryID: idarray });
    }
  };

  toggleModal = (type: string) => {
    this.setState({ activeModalType: type, isVisible: !this.state.isVisible });
  };

  expandCategoryView = () => {
    this.setState({
      dropdownCategoryStatus: !this.state.dropdownCategoryStatus
    });
  };
  expand = (id: string) => {
    const newArray = [...this.state.categoriesArray];
  
    for (const category of newArray) {
      if (category.id === id) {
        category.expand = !category.expand;
      }
    }
  
    this.setState({ categoriesArray: newArray });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  addCategory = async () => {
    if (this.isStringNullOrBlank(this.state.category)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else {
      const requestMessage = await generateRequestMessage(
        configJSON.categoryAPIEndPoint,
        configJSON.httpPostType
      )
      this.addCategoryApiCallId = requestMessage.messageId;
      let data = {
        categories: [{ name: this.state.category }]
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };
  addSubCategory = async () => {
    if (this.isStringNullOrBlank(this.state.subCategory)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (this.state.selectedCategoryID.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorCategory);
      return false;
    } else {
      let data = {
        sub_category: {
          name: this.state.subCategory
        },
        parent_categories: this.state.selectedCategoryID
      };
      const requestMessage = await generateRequestMessage(
        configJSON.subCategoryAPIEndPoint,
        configJSON.httpPostType
      )
      this.addSubCategoryApiCallId = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  handleAddCategory = () => {
    this.setState({ showChild: "addcategory" });
  };

  handleCreateCategory = () => {
    this.setState({ showChild: "addcategory" });
  };

  editCategory = (name: any, id: any, image_url: any) => {
    this.setState({ category_img: image_url });
    this.setState({ category_name: name });
    this.setState({ category_id: id });
    this.setState({ showChild: "editcategory" });
  };

  deleteCategory = async (categoryId:any) => {

  
    this.setState({
      selectedIds: categoryId
    },
    async () => {
      this.setState({isLoading:true})
      const requestMessage = await generateRequestMessage(
        configJSON.deleteCategortApiEndPoint,
        configJSON.httpDeleteType
      )
      this.deleteCategoryDelApiCallId = requestMessage.messageId;
      //GO TO REQUEST STATE
  
      const httpBody = {
        category_ids: this.state.selectedIds
      };
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    );

   

    
   
  };

  handleCheckboxClick = (rowId: any) => {
    const { selectedIds } = this.state;
    const updatedIds = selectedIds.includes(rowId)
      ? selectedIds.filter((id: any) => id !== rowId)
      : [...selectedIds, rowId];

    this.setState({
      selectedIds: updatedIds
    });
    console.log("Row ID clicked:", this.state.selectedIds);
  };

  getListCategory = () => {
    this.setState({isLoading:true})
    const header = {};
    this.getCategoryGetApiCallId = BlockHelpers.callApi({
      method: configJSON.httpGetType,
      endPoint: configJSON.addCategoryApiEndPoint,
      header
    });
  };

  addimages = (values: FormValues) => {
    this.setState({isLoading:true})
    const header = {};
    const body = new FormData();
    body.append("[category][name]", values.category_name);
    if (values.category_img) {
      body.append("[category][image]", values.category_img);
    }
    this.getCategoryAddApiCallId = BlockHelpers.callApi({
      method: configJSON.httpPostType,
      endPoint: configJSON.addCategoryApiEndPoint,
      header,
      body
    });
  };
  isLoadingTrue=()=>{
    this.setState({isLoading:true})
  }

  editimages = async (id: number, values: FormValues) => {
    
   
    const header = {};
    const body = new FormData();
    body.append("[category][name]", values.category_name);
    if (values.category_img) {
      body.append("[category][image]", values.category_img);
    }
    this.updateCategoryEditApiCallId = BlockHelpers.callApi({
      method: configJSON.httpPatchType,
      endPoint: configJSON.updateCategoryAPiEndPoint + `/${id}`,
      header,
      body
    });
  };

  back = () => {
    this.setState({ showChild: "imagelist" });
  };

  backeditlist = () => {
    this.setState({ showChild: "imagelist" });
  };

  imageRemove = () =>{
    this.setState({ category_img: "" });
  } 

  dialogClose = () =>{
    this.setState({ isError: false }); 
  }
 

}

// Customizable Area End