import { Theme, makeStyles } from "@material-ui/core";

export const FilterOptions = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(3, 3, 3.75),
    flex: 1,
    minWidth: "300px",
    maxWidth: "1000px",
  },
  header: {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    height: "500px",
    overflow: "auto",
    [theme.breakpoints.down(1280)]: {
      height: "360px",
    },
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "14px",
    lineHeight: "24px",
    padding: theme.spacing(1, 0),
  },
  checkbox: {
    padding: theme.spacing(0),
    margin: theme.spacing(0, 2),
    color: "transparent !important",
  },
}));
