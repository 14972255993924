Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.contentTypeApplicationJson = 'application/json'

exports.getBrandsEndPoint = 'catalogue/brands'
exports.getStoreDetails = '/bx_block_store_details/public/store_profile'

exports.getMethod = 'GET'

// Customizable Area End
