// Customizable Area Start
import { Theme, makeStyles } from "@material-ui/core";

export const TopServices = makeStyles((theme: Theme) => ({
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
  },
  titleWrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  title: {
    fontSize: "32px",
    fontWeight: 500,
    lineHeight: "36px",
    letterSpacing: "3.2px",
    color: "#364F6B",
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "28px",
      letterSpacing: "-0.4px",
      paddingBottom: theme.spacing(1),
    },
  },
  titleDivider: {
    width: "12%",
    height: "3px",
    backgroundColor: "#3FC1CB",
    margin: "auto",
    opacity: 1,
    [theme.breakpoints.down("sm")]: {
      width: "20%",
      margin: theme.spacing(0),
    },
  },
  viewAll: {
    fontSize: "18px",
    lineHeight: "24px",
    color: "#3FC1CB",
    textDecoration: "none",
    position: "absolute",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  serviceList: {
    display: "flex",
    padding: "40px 0 0 0",
    height: "541",
    gap: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "306px",
      overflowX: "auto",
      padding: "24px 0px 4px",
      gap: 16,
    },
  },
  serviceItem: {
    width: "auto",
    padding: 0
  },
  serviceLink: {
    textDecoration: "none",
    width: "100%",
    height: "100%",
    display: "flex",
  },
  serviceCard: {
    display: "flex",
    flexDirection: "column",
    width: "302px",
    flex: "1",
    [theme.breakpoints.down("sm")]: {
      width: "150px",
      height: "100%",
    },
  },
  serviceImage: {
    height: "80%",
    [theme.breakpoints.down("sm")]: {
      height: "75%",
    },
  },
  serviceContent: {
    flex: "1 1 auto",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 1, 1.5),
      "&.MuiCardContent-root:last-child": {
        paddingBottom: theme.spacing(1.5),
      },
    },
  },
  serviceName: {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#000000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
  serviceBrief: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "#000000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "20px",
    },
  },
}));
// Customizable Area End