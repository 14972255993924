// Customizable Area Start
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  TextField,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Switch,
  Typography,
  FormControl,
  CardMedia,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Link } from "react-router-dom";
import { ArrowBack as ArrowBackIcon, Add as AddIcon } from "@material-ui/icons";
import { useStyles } from "./styles/Service.web";
import { Category } from "../../utilities/src/models/Category";
import Itemavailability from "./Itemavailability.web";
import { Availability } from "../../utilities/src/models/Availability";
import Portal from "../../../components/src/Portal";
import { Service as IService } from "../../utilities/src/models/Service";
import { ServiceImage } from "../../utilities/src/models/ServiceImage";
import ImageCard from "../../utilities/src/components/ImageCard.web";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required."),
  description: Yup.string()
    .required("Description is required.")
    .max(100, "Description must be at most 100 characters."),
  images: Yup.array().test(
    "required",
    "Please upload at least one image.",
    function (value) {
      if (value) {
        return value.length > 0;
      }
    }
  ),
  duration: Yup.number().required("Duration is required."),
  price: Yup.number().required("Price is required."),
  hasDiscount: Yup.bool(),
  discount: Yup.number().when("hasDiscount", {
    is: true,
    then: (schema) => schema.required("Discount is required."),
  }),
  paymentPreference: Yup.string().required("Payment preference is required."),
  availability: Yup.array().of(
    Yup.object().shape({
      day: Yup.string(),
      workingHours: Yup.array().of(
        Yup.object().shape({
          openingTime: Yup.string(),
          closingTime: Yup.string(),
        })
      ),
      selected: Yup.bool(),
    })
  ),
  status: Yup.boolean(),
  category: Yup.number().required("Category is required."),
});

interface Props {
  service: IService | null;
  categoriesData: Category[];
  availabilityErrors: string[];
  resetAvailabilityErrors: () => void;
  validateAvailability: (
    duration: number,
    availability: Availability[]
  ) => void;
  upsertService: (
    values: any,
    isUpdate: boolean,
    deletedImagesIds?: number[]
  ) => void;
}

const Service: React.FC<Props> = ({
  service,
  categoriesData,
  availabilityErrors,
  resetAvailabilityErrors,
  validateAvailability,
  upsertService,
}) => {
  const imageUploadInputRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const resetButtonRef = useRef<HTMLButtonElement>(null);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [deletedImagesIds, setDeletedImagesIds] = useState<number[]>([]);
  const [initialValues, setInitialValues] = useState<any>({
    title: "",
    description: "",
    images: [],
    duration: "",
    price: "",
    hasDiscount: false,
    discount: "",
    paymentPreference: "",
    availability: [
      {
        day: "Monday",
        workingHours: [
          {
            openingTime: "",
            closingTime: "",
          },
        ],
        selected: false,
      },
      {
        day: "Tuesday",
        workingHours: [
          {
            openingTime: "",
            closingTime: "",
          },
        ],
        selected: false,
      },
      {
        day: "Wednesday",
        workingHours: [
          {
            openingTime: "",
            closingTime: "",
          },
        ],
        selected: false,
      },
      {
        day: "Thursday",
        workingHours: [
          {
            openingTime: "",
            closingTime: "",
          },
        ],
        selected: false,
      },
      {
        day: "Friday",
        workingHours: [
          {
            openingTime: "",
            closingTime: "",
          },
        ],
        selected: false,
      },
      {
        day: "Saturday",
        workingHours: [
          {
            openingTime: "",
            closingTime: "",
          },
        ],
        selected: false,
      },
      {
        day: "Sunday",
        workingHours: [
          {
            openingTime: "",
            closingTime: "",
          },
        ],
        selected: false,
      },
    ],
    status: false,
    category: "",
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (service) {
        upsertService(values, true, deletedImagesIds);
      } else {
        upsertService(values, false);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    getFieldProps,
    setValues,
    setErrors,
    setTouched,
    initialErrors,
    initialTouched,
  } = formik;

  const classes = useStyles({ hasImages: values.images.length > 0 });
  const [tempAvailability, setTempAvailability] = useState<Availability[]>([]);

  useEffect(() => {
    setInitialValues({
      title: service?.title || "",
      description: service?.description || "",
      images: service?.images || [],
      duration: service?.duration || "",
      price: service?.undiscountedPrice || "",
      hasDiscount: service?.hasDiscount || false,
      discount: service?.discount || "",
      paymentPreference: service?.paymentPreference || "",
      availability: service?.availability || [
        {
          day: "Monday",
          workingHours: [
            {
              openingTime: "",
              closingTime: "",
            },
          ],
          selected: false,
        },
        {
          day: "Tuesday",
          workingHours: [
            {
              openingTime: "",
              closingTime: "",
            },
          ],
          selected: false,
        },
        {
          day: "Wednesday",
          workingHours: [
            {
              openingTime: "",
              closingTime: "",
            },
          ],
          selected: false,
        },
        {
          day: "Thursday",
          workingHours: [
            {
              openingTime: "",
              closingTime: "",
            },
          ],
          selected: false,
        },
        {
          day: "Friday",
          workingHours: [
            {
              openingTime: "",
              closingTime: "",
            },
          ],
          selected: false,
        },
        {
          day: "Saturday",
          workingHours: [
            {
              openingTime: "",
              closingTime: "",
            },
          ],
          selected: false,
        },
        {
          day: "Sunday",
          workingHours: [
            {
              openingTime: "",
              closingTime: "",
            },
          ],
          selected: false,
        },
      ],
      status: service?.status || false,
      category: service?.category.id || "",
    });
  }, [service]);

  useEffect(() => {
    if (!availabilityErrors.length && tempAvailability.length) {
      setValues({ ...values, availability: tempAvailability });
      closeModal();
    }
  }, [availabilityErrors]);

  const clearFileInput = () => {
    if (imageUploadInputRef.current) {
      imageUploadInputRef.current.value = "";
    }
  };

  const addImage = () => {
    imageUploadInputRef.current && imageUploadInputRef.current.click();
  };

  const removeImage = (imageIndex: number) => {
    const isImage = (obj: any): obj is ServiceImage => {
      return "id" in obj && "url" in obj;
    };

    const newImages = (values.images as (ServiceImage | File)[]).filter(
      (image, index) => {
        const deletedImage = index === imageIndex;
        if (deletedImage) {
          isImage(image) &&
            setDeletedImagesIds((currentIds) => [
              ...currentIds,
              (image as ServiceImage).id,
            ]);
          return false;
        }
        return true;
      }
    );
    setTouched({ ...touched, images: true });
    setValues({ ...values, images: newImages }, true);
  };

  const checkImage = (event: ChangeEvent<HTMLInputElement>) => {
    const sizeLimit = 20971520;
    const fileList: FileList | null = event.currentTarget.files;

    const newImages: File[] = [...(values.images as File[])];
    if (fileList) {
      setTouched({ ...touched, images: true }, false);
      for (let index = 0; index < fileList.length; index++) {
        const file: File = fileList[index];
        if (!file.type.startsWith("image/")) {
          setErrors({ ...errors, images: "Image should be a JPEG, PNG, GIF, TIFF, RAW, PSD, or JPG type" });
          clearFileInput();
          return;
        }
        if (file.size > sizeLimit) {
          setErrors({ ...errors, images: "Image size should not be greater than 20MB" });
          clearFileInput();
          return;
        }
        newImages.push(fileList[index]);
      }
    }
    setValues({ ...values, images: newImages }, true);
    clearFileInput();
  };

  const checkDiscount = () => {
    setValues({
      ...values,
      hasDiscount: !values.hasDiscount,
      ...(!values.hasDiscount && { discount: "" }),
    });
    setTouched({ ...touched, hasDiscount: true, discount: false });
  };

  const shouldRenderAvailabilities = values.availability.some(
    (data: Availability) => data.selected
  );

  const openModal = () => setIsModalOpened(true);

  const closeModal = () => {
    setIsModalOpened(false);
    setTempAvailability([]);
    resetAvailabilityErrors();
  };

  const checkAvailabilityForm = (currentAvailability: Availability[]) => {
    validateAvailability(+values.duration, currentAvailability);
    setTempAvailability(currentAvailability);
  };

  const isFormChanged =
    JSON.stringify(values) !== JSON.stringify(initialValues);

  const discardChanges = () => {
    resetButtonRef.current && resetButtonRef.current.click();
  };

  const saveChanges = () => {
    submitButtonRef.current && submitButtonRef.current.click();
  };

  const reset = () => {
    setDeletedImagesIds([]);
    setValues({
      ...initialValues,
      images: [...initialValues.images],
      availability: [...initialValues.availability],
    });
    setErrors({ ...initialErrors });
    setTouched({ ...initialTouched });
  };

  return (
    <>
      <Slide direction="down" in={isFormChanged} mountOnEnter unmountOnExit>
        <Box className={classes.formActionsWrapper}>
          <Box className={classes.formActions}>
            <CardMedia
              component="img"
              src={require("../assets/Builder Studio Store.png")}
              className={classes.logo}
            />

            <Box>
              <Button
                variant="text"
                className={classes.discardChangesbutton}
                onClick={discardChanges}
              >
                Discard changes
              </Button>
              <Button
                variant="contained"
                className={classes.saveChangesbutton}
                onClick={saveChanges}
              >
                Save changes
              </Button>
            </Box>
          </Box>
        </Box>
      </Slide>

      <Box className={classes.container}>
        <Link to="/ServicesManagement" replace className={classes.link}>
          <Button
            variant="text"
            startIcon={<ArrowBackIcon />}
            className={classes.backButton}
          >
            <Typography>Back</Typography>
          </Button>
        </Link>

        <Typography className={classes.title}>
          {service ? "Edit service" : "Add a service"}
        </Typography>

        <form onSubmit={handleSubmit} className={classes.wrapper}>
          <Box className={classes.leftSideWrapper}>
            <Box className={classes.card}>
              <Typography className={classes.cardTitle}>
                Service details
              </Typography>

              <Box className={classes.inputsWrapper}>
                <Box>
                  <Typography className={classes.inputlabel}>
                    Title *
                  </Typography>
                  <TextField
                    variant="outlined"
                    placeholder="Service title"
                    fullWidth
                    {...getFieldProps("title")}
                    error={Boolean(
                      touched && touched.title && errors && errors.title
                    )}
                    helperText={
                      touched && touched.title && errors && errors.title
                    }
                  />
                </Box>

                <Box>
                  <Box className={classes.descriptionWrapper}>
                    <Typography className={classes.inputlabel}>
                      Description *
                    </Typography>

                    <Typography className={classes.descriptionCounter}>
                      {values.description.length} / 100
                    </Typography>
                  </Box>

                  <TextField
                    variant="outlined"
                    placeholder="Service description"
                    fullWidth
                    multiline
                    minRows={3}
                    {...getFieldProps("description")}
                    error={Boolean(
                      touched &&
                        touched.description &&
                        errors &&
                        errors.description
                    )}
                    helperText={
                      touched &&
                      touched.description &&
                      errors &&
                      errors.description
                    }
                  />
                </Box>

                <Box>
                  <Typography className={classes.inputlabel}>
                    Images *
                  </Typography>
                  <input
                    data-testid="image-upload"
                    ref={imageUploadInputRef}
                    name="images"
                    className={classes.hidden}
                    type="file"
                    accept="image/*"
                    onChange={checkImage}
                    onBlur={handleBlur}
                    multiple
                  />
                  <Box className={classes.imagesWrapper}>
                    {(values.images as (ServiceImage | File)[]).map(
                      (image: ServiceImage | File, index: number) => (
                        <Box key={index}>
                          <ImageCard
                            imageClassName={classes.previewImage}
                            image={
                              (image as ServiceImage).url ||
                              URL.createObjectURL(image as File)
                            }
                          />
                          <Button
                            variant="text"
                            onClick={() => removeImage(index)}
                            className={classes.removeButton}
                          >
                            Remove
                          </Button>
                        </Box>
                      )
                    )}
                    <Box className={classes.imageUpload} onClick={addImage}>
                      <AddIcon className={classes.addIcon} />
                      <Typography className={classes.addImageText}>
                        Add image
                      </Typography>
                      <Typography className={classes.fileSize}>
                        Max 20MB
                      </Typography>
                    </Box>
                  </Box>

                  {touched && touched.images && errors && errors.images && (
                    <Typography className={classes.validationError}>
                      {errors.images}
                    </Typography>
                  )}
                </Box>

                <Box>
                  <Typography className={classes.inputlabel}>
                    Service duration (in Mins) *
                  </Typography>
                  <Box className={classes.fieldWrapper}>
                    <TextField
                      type="number"
                      variant="outlined"
                      placeholder="Service duration"
                      className={classes.field}
                      {...getFieldProps("duration")}
                      error={Boolean(
                        touched && touched.duration && errors && errors.duration
                      )}
                      helperText={
                        touched && touched.duration && errors && errors.duration
                      }
                    />
                  </Box>
                </Box>

                <Box>
                  <Typography className={classes.inputlabel}>
                    Price *
                  </Typography>
                  <Box className={classes.fieldWrapper}>
                    <TextField
                      type="number"
                      variant="outlined"
                      placeholder="Service price"
                      className={classes.field}
                      {...getFieldProps("price")}
                      error={Boolean(
                        touched && touched.price && errors && errors.price
                      )}
                      helperText={
                        touched && touched.price && errors && errors.price
                      }
                    />

                    <Box className={classes.switchWrapper}>
                      <Switch
                        checked={values.hasDiscount}
                        className={classes.switch}
                        {...getFieldProps("hasDiscount")}
                        onChange={checkDiscount}
                      />
                      <Typography className={classes.hasDiscountSwitchText}>
                        Discount
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className={classes.discount}>
                  {values.hasDiscount && (
                    <Box className={classes.discountInputsWrapper}>
                      <Box>
                        <Typography className={classes.inputlabel}>
                          Discount *
                        </Typography>
                        <TextField
                          type="number"
                          variant="outlined"
                          placeholder="Eg. 10"
                          {...getFieldProps("discount")}
                          error={Boolean(
                            touched &&
                              touched.discount &&
                              errors &&
                              errors.discount
                          )}
                          helperText={
                            touched &&
                            touched.discount &&
                            errors &&
                            errors.discount
                          }
                        />
                      </Box>

                      <Typography className={classes.discountPercent}>
                        %
                      </Typography>

                      <Box>
                        <Typography className={classes.inputlabel}>
                          After discount
                        </Typography>
                        <TextField
                          value={
                            values.price &&
                            values.discount &&
                            +values.price -
                              +values.price * (+values.discount / 100)
                          }
                          variant="outlined"
                          disabled
                          className={classes.disabledInput}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box>
                  <FormControl component="fieldset">
                    <Typography className={classes.inputlabel}>
                      Payment preferences *
                    </Typography>
                    <RadioGroup {...getFieldProps("paymentPreference")}>
                      <FormControlLabel
                        value="pay_online"
                        control={<Radio className={classes.radioChecked} />}
                        label="Customers pay online"
                        classes={{ label: classes.radioLabel }}
                      />
                      <FormControlLabel
                        value="pay_in_person"
                        control={<Radio className={classes.radioChecked} />}
                        label="Customers pay in person at location"
                        classes={{ label: classes.radioLabel }}
                      />
                      <FormControlLabel
                        value="pay_online_or_in_person"
                        control={<Radio className={classes.radioChecked} />}
                        label="Customers pay online or in person at location"
                        classes={{ label: classes.radioLabel }}
                      />
                    </RadioGroup>
                  </FormControl>

                  {touched && touched.paymentPreference && errors && (
                    <Typography className={classes.validationError}>
                      {errors.paymentPreference}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>

            <Box className={classes.card}>
              <Box
                className={`${classes.cardTitle} ${classes.availabilityHeader}`}
              >
                <Box>
                  <Typography className={classes.availabilityTitle}>
                    Service availability
                  </Typography>

                  <Typography className={classes.availabilityText}>
                    Choose the days & times this service is available.
                  </Typography>
                </Box>

                <Button
                  className={classes.editAvailablityButton}
                  onClick={openModal}
                  disabled={!values.duration}
                >
                  Edit availability
                </Button>
              </Box>

              {shouldRenderAvailabilities && (
                <TableContainer>
                  <Table className={classes.tableCells}>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography className={classes.tableHeaderText}>
                            Day
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography className={classes.tableHeaderText}>
                            Time
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {values.availability.map(
                        (availability: Availability, index: number) =>
                          availability.selected && (
                            <TableRow
                              key={index}
                              className={classes.tableBodyRowCells}
                            >
                              <TableCell>
                                <Typography className={classes.tableCellText}>
                                  {availability.day}
                                </Typography>
                              </TableCell>

                              <TableCell>
                                <Box className={classes.workingHoursWrapper}>
                                  <Box className={classes.workingHours}>
                                    {availability.workingHours.map(
                                      ({ openingTime, closingTime }, index) => (
                                        <Typography
                                          key={index}
                                          className={classes.tableCellText}
                                        >
                                          {openingTime} - {closingTime}
                                        </Typography>
                                      )
                                    )}
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              {touched &&
                touched.availability &&
                errors &&
                errors.availability && (
                  <Typography className={classes.validationError}>
                    {errors.availability}
                  </Typography>
                )}
            </Box>
          </Box>

          <Box className={classes.RightSideWrapper}>
            <Box className={classes.card}>
              <Typography className={classes.cardTitle}>Status</Typography>
              <Box className={classes.switchWrapper}>
                <Switch
                  color="primary"
                  checked={values.status}
                  className={classes.switch}
                  {...getFieldProps("status")}
                />
                <Typography>
                  {values.status ? "Active" : "Not active"}
                </Typography>
              </Box>

              <Typography className={classes.helperText}>
                Your service {values.status ? "will be" : "is not"} visible on
                your store
              </Typography>
            </Box>

            <Box className={classes.card}>
              <Typography className={classes.cardTitle}>Category</Typography>

              <Typography className={classes.inputlabel}>Category *</Typography>
              <Autocomplete
                options={categoriesData}
                getOptionLabel={(category) => category.name}
                value={
                  categoriesData.find(
                    (category) => category.id === +values.category
                  ) || null
                }
                onChange={(_, value) =>
                  setValues({ ...values, category: value?.id || "" })
                }
                onBlur={handleBlur("category")}
                getOptionSelected={(category, value) =>
                  category.id === value.id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select"
                    fullWidth
                    error={Boolean(
                      touched && touched.category && errors && errors.category
                    )}
                    helperText={
                      touched && touched.category && errors && errors.category
                    }
                  />
                )}
              />

              <Typography className={classes.helperText}>
                Add a category to this service. This will allow the user to
                filter by them on the service listing page
              </Typography>
            </Box>
          </Box>

          <Button
            ref={resetButtonRef}
            className={classes.hidden}
            onClick={reset}
          />
          <Button
            ref={submitButtonRef}
            type="submit"
            className={classes.hidden}
          />
        </form>
      </Box>

      {isModalOpened && (
        <Portal>
          <Itemavailability
            availability={values.availability}
            availabilityErrors={availabilityErrors}
            checkAvailabilityForm={checkAvailabilityForm}
            closeModal={closeModal}
          />
        </Portal>
      )}
    </>
  );
};

export default Service;
// Customizable Area End