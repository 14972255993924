Object.defineProperty(exports, '__esModule', {
  value: true
});
// Customizable Area Start
exports.getMethod = "GET";
exports.putMethod = "PUT";
exports.postMethod = "POST";
exports.activationStatusLinkBase = "https://staging.cloud-marketplace.builder.ai/api/accounts/";
exports.errorPageInitialisation = "Error on page initialization";
exports.errorCardUpdate = "Error on card update";
exports.basic = "Basic ";
exports.errorActivation = "Error on Activation Status Check";
exports.payment = "Stripe Payment";
exports.stripeIntegration = "Stripe integration";
exports.thisFieldIsRequired = "This field is required";
exports.notActive = "Not active";
exports.active = "Active";
exports.stripeTitle = "Stripe";
exports.paymentTitle = "Payment";
exports.labelApiKey = "Api Key";
exports.labelSecretApiKey = "Secret Api Key";
exports.useToGetMessage = "Setup Stripe for your transaction.";
exports.viewDashboardLink = "https://dashboard.stripe.com/login";
exports.viewDashboard = "View dashboard";
exports.activateAccount = "Activate account";
exports.editAccount = "Edit account";
exports.consoleErrorBase = "Async: Could not copy text: ";
exports.labelMerchantId="UserId";

exports.stripAccountStatusPaymentsAdminURL = "bx_block_stripe_integration/stripe_configurations"
// Customizable Area End
