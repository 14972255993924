// Customizable Area Start
import React from "react";
import { Box, List, ListItem, Divider, SvgIcon } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Dashboard as useStyles } from "./styles/Dashboard.web";
import TopServices from "./TopServices.web";
import Location from "./Location.web";
import { ReactComponent as StarRateIcon } from "../assets/Rate-Star.svg";
import { Location as LocationDetails } from "../../utilities/src/models/Location";
import { Banner as IBanner } from "../../utilities/src/models/Banner";
import { Service } from "../../utilities/src/models/Service";
import { ServiceImage } from "../../utilities/src/models/ServiceImage";
import ImageCard from "../../utilities/src/components/ImageCard.web";

interface Props {
  banners: IBanner[];
  topServices: Service[];
  showTestimonials: boolean;
  testimonials: Record<string, string | number>[];
  locationDetails: LocationDetails | null;
}

const Dashboard: React.FC<Props> = ({
  banners,
  topServices,
  showTestimonials,
  testimonials,
  locationDetails,
}) => {
  const classes = useStyles();
  const [firstBanner, secondBanner, thirdBanner] = banners as { image: ServiceImage, url: string }[];

  return (
    <Box className={classes.container}>
      
      {(firstBanner && firstBanner.image &&Boolean(firstBanner.image.url)) && (
        <ImageCard
          imageClassName={classes.banner}
          image={(firstBanner.image as ServiceImage).url}
          onClick={() => window.open((firstBanner as any).url)}
          alt="banner"
        />
      )}
      <Box className={classes.sectionWrapper}>
        <TopServices services={topServices} />
      </Box>

      {(secondBanner && secondBanner.image && Boolean(secondBanner.image.url)) && (
        <ImageCard
          imageClassName={classes.banner}
          image={(secondBanner.image as ServiceImage).url}
          onClick={() => window.open((secondBanner as any).url)}
          alt="banner"
        />
      )}

      {showTestimonials && testimonials.length > 0 && (
        <Box className={classes.sectionWrapper}>
          <Box className={classes.sectionTitleWrapper}>
            <Typography className={classes.sectionTitle}>
              TESTIMONIALS
            </Typography>
            <Divider className={classes.titleDivider} />
          </Box>
          <List className={classes.testimonials}>
            {testimonials.map((testimonial, index) => (
              <ListItem key={index} className={classes.testimonial}>
                <Typography className={classes.testimonialUsername}>
                  {testimonial.name}
                </Typography>
                <Box className={classes.testimonialStarsWrapper}>
                  {Array(5)
                    .fill(null)
                    .map((_, index) => (
                      <SvgIcon
                        key={index}
                        component={StarRateIcon}
                        className={`${classes.testimonialStar} ${
                          index < +testimonial.rating
                            ? classes.testimonialRated
                            : classes.testimonialUnrated
                        }`}
                      />
                    ))}
                </Box>
                <Typography className={classes.testimonialDescription}>
                  {testimonial.description}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      {(thirdBanner && thirdBanner.image &&Boolean(thirdBanner.image.url)) && (
        <ImageCard
          imageClassName={classes.banner}
          image={(thirdBanner.image as ServiceImage).url}
          onClick={() => window.open((thirdBanner as any).url)}
          alt="banner"
        />
      )}

      {locationDetails && (
        <Box className={classes.sectionWrapper}>
          <Location locationDetails={locationDetails} />
        </Box>
      )}
    </Box>
  );
};

export default Dashboard;
// Customizable Area End