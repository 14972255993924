// Customizable Area Start
import React from "react";

export interface Props {
    text: string;
}

interface S {
    currency: string;
}

interface SS {
    id: any;
}

export default class Currency extends React.Component<Props, S, SS>  {
    constructor(props: Props) {
        super(props);
        const storeDetails = this.getStoreDetails();
        this.state = {
            currency: storeDetails?.currency_symbol || "₹"
        };
    }


    async componentDidMount() {
        window.addEventListener("store_details", this.localStorageListener);
    }

    async componentWillUnmount() {
        window.removeEventListener("store_details", this.localStorageListener);
    }

    localStorageListener = () => {
        const storeDetails = this.getStoreDetails();
        if (storeDetails) {
            this.setState({ currency: storeDetails?.currency_symbol || "₹" })
        }
    }

    getStoreDetails = () => {
        const storeDetailsString = localStorage.getItem("storeDetails");
        if (storeDetailsString) {
            return JSON.parse(storeDetailsString)
        }
        else null
    }

    render() {
        // "\u200E" for left-to-right conversion for د.إ
        const fullText = "\u200E" + (this.state.currency || "₹") + "\u200E" + (this.props.text || "");
        return fullText
    }
}

export const saveStoreDetails = (storeDetails: any) => {
    window.localStorage.setItem("storeDetails", JSON.stringify(storeDetails));
    window.dispatchEvent(new Event("store_details"));
}
// Customizable Area End