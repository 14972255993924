// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import { withRouter } from "react-router-dom";
import firebase from 'firebase';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../../blocks/utilities/src/theme';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';

import Appheader from '../../blocks/header/src/AppheaderWrapper';
import Appfooter from '../../blocks/footer/src/AppfooterWrapper';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import OrderManagement from '../../blocks/ordermanagement/src/OrderManagement';
import ShoppingCartOrders from '../../blocks/shoppingcart/src/ShoppingCartOrders';
import AddShoppingCartOrderItem from '../../blocks/shoppingcart/src/AddShoppingCartOrderItem';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import EducationalUserProfile from '../../blocks/educational-user-profile/src/EducationalUserProfile';
import Emailnotifications2 from '../../blocks/emailnotifications2/src/Emailnotifications2';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import Itemavailability from '../../blocks/itemavailability/src/Itemavailability';
import Notifications from '../../blocks/notifications/src/Notifications';
import Testimonials2 from '../../blocks/testimonials2/src/Testimonials2';
import ApiIntegration from '../../blocks/apiintegration/src/ApiIntegration';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import ServicesWrapper from '../../blocks/catalogue/src/ServicesWrapper';
import ServiceWrapper from '../../blocks/catalogue/src/ServiceWrapper';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import Storecontentmanagement2 from '../../blocks/storecontentmanagement2/src/Storecontentmanagement2';
import Scheduling from '../../blocks/scheduling/src/Scheduling';
import AddContact from '../../blocks/contactus/src/AddContact';
import Settings2 from '../../blocks/settings2/src/Settings2';
import Guestlogin2 from '../../blocks/guestlogin2/src/Guestlogin2';
import Rolesandpermissions from '../../blocks/rolesandpermissions/src/Rolesandpermissions';
import Sorting from '../../blocks/sorting/src/Sorting';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import Search from '../../blocks/search/src/Search';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import DashboardWrapper from '../../blocks/dashboard/src/DashboardWrapper';
import StripePayments from '../../blocks/stripepayments/src/StripePayments';
import InvoiceBilling from '../../blocks/invoicebilling/src/InvoiceBilling';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import ContactSuccess from '../../blocks/contactus/src/ContactSuccess';
import Gallery from '../../blocks/photolibrary/src/Gallery';
import Appointments from '../../blocks/appointmentmanagement/src/Appointments.web';
import Confirmation from '../../blocks/appointmentmanagement/src/Confirmation.web';
import StaticPages from '../../blocks/staticpages/src/StaticPages.web';

const routeMap = {
  Admin: {
    component: require('../../blocks/ss-cms-common-router/src/WebRoutes').default,
    path: '/admin/*',
    exact: true
  },
  OrderManagement: {
    component: OrderManagement,
    path: '/OrderManagement'
  },
  ShoppingCartOrders: {
    component: ShoppingCartOrders,
    path: '/ShoppingCartOrders'
  },
  AddShoppingCartOrderItem: {
    component: AddShoppingCartOrderItem,
    path: '/AddShoppingCartOrderItem'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: '/EducationalUserProfile'
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: '/Emailnotifications2'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  Itemavailability: {
    component: Itemavailability,
    path: '/Itemavailability'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  Testimonials2: {
    component: Testimonials2,
    path: '/Testimonials2'
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: '/ApiIntegration'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  Services: {
    component: ServicesWrapper,
    path: '/Services',
    exact: true
  },
  ServicesSearch: {
    component: ServicesWrapper,
    path: '/Services/:searchQuery',
    exact: true
  },
  Service: {
    component: ServiceWrapper,
    path: '/Service/:id',
    exact: true
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  Storecontentmanagement2: {
    component: Storecontentmanagement2,
    path: '/Storecontentmanagement2'
  },
  Scheduling: {
    component: Scheduling,
    path: '/Scheduling'
  },
  AddContact: {
    component: AddContact,
    path: '/AddContact'
  },
  Settings2: {
    component: Settings2,
    path: '/Settings2'
  },
  Guestlogin2: {
    component: Guestlogin2,
    path: '/Guestlogin2'
  },
  Rolesandpermissions: {
    component: Rolesandpermissions, 
    path: '/Rolesandpermissions'
  },
  Sorting: {
    component: Sorting,
    path: '/Sorting'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  Search: {
    component: Search,
    path: '/Search'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  StripePayments: {
    component: StripePayments,
    path: '/StripePayments'
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: '/InvoiceBilling'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },

  Home: {
    component: DashboardWrapper,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  ContactSuccess: {
    component: ContactSuccess,
    path: '/ContactSuccess'
  },
  Gallery: {
    component: Gallery,
    path: '/Gallery'
  },
  Appointments: {
    component: Appointments,
    path: '/Appointments/:id'
  },
  Confirmation: {
    component: Confirmation,
    path: '/Confirmation'
  },
  StaticPages: {
    component: StaticPages,
    path: '/StaticPages',
    exact: true
  },
  StaticPageDetail: {
    component: StaticPages,
    path: '/StaticPages/:id',
    exact: true
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View>
        <ThemeProvider theme={theme}>
          {WebRoutesGenerator({ routeMap, Appheader, Appfooter, location: this.props.location })}
          <ModalContainer />
        </ThemeProvider>
      </View>
    );
  }
}

export default withRouter(App);