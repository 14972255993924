// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Service } from "../../utilities/src/models/Service";
import { Category } from "../../utilities/src/models/Category";
import BlockHelpers from "../../utilities/src/BlockHelpers";
import {
  parseCatalogue,
  parseCategory,
} from "../../utilities/src/helpers/utils";
import { Navigation } from "../../utilities/src/models/Navigation";

export const configJSON = require("./config");

export interface Props {
  navigation: Navigation;
  identifier: string;
}

interface S {
  services: Service[];
  categories: Category[];
  selectedCategories: number[];
  tempSelectedCategories: number[];
  loading: boolean;
  searchQuery: string;
}

interface SS {
  identifier: string;
}

export default class CataloguesController extends BlockComponent<Props, S, SS> {
  getAllCataloguesApiCallId: string | undefined;
  getAllCategoriesApiCallId: string | undefined;
  searchCataloguesApiCallId: string | undefined;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      services: [],
      categories: [],
      selectedCategories: [],
      tempSelectedCategories: [],
      loading: true,
      searchQuery: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    const searchQuery = this.props.navigation.getParam("searchQuery");
    if (searchQuery) {
      this.searchServices(searchQuery as string);
    } else {
      this.getAllCatalogues();
    }
    this.getAllCategories();
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (
      prevProps.navigation.getParam("searchQuery") !==
      this.props.navigation.getParam("searchQuery")
    ) {
      const searchQuery = this.props.navigation.getParam("searchQuery");
      if (searchQuery) {
        this.searchServices(searchQuery as string);
      } else {
        this.getAllCatalogues();
      }
    }
    if (prevState.selectedCategories !== this.state.selectedCategories) {
      this.getAllCatalogues();
    }
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Recived", message);
    if (
      (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.getAllCataloguesApiCallId != null &&
        this.getAllCataloguesApiCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) ||
      (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.searchCataloguesApiCallId != null &&
        this.searchCataloguesApiCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.handleGetAllCatalogues(responseJson);
      } else {
        this.setState({ services: [] });
        this.setState({ loading: false });

        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAllCategoriesApiCallId != null &&
      this.getAllCategoriesApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.handleGetAllCategories(responseJson);
      } else {
        this.setState({ categories: [] });

        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  };

  handleGetAllCatalogues = (
    responseJson: Record<string, string | number | Object | Object[]>
  ) => {
    const services: Service[] = (responseJson.data as Object[]).map(
      (response) => parseCatalogue(response)
    );

    this.setState({
      services,
    });
    this.setState({ loading: false });
  };

  handleGetAllCategories = (
    responseJson: Record<string, string | number | Object | Object[]>
  ) => {
    const categories: Category[] = ((((responseJson.data as Object[])[0] as Record<
      string,
      string | number | Object | Object[]
    >).data as Record<string, string | number | Object | Object[]>)
      .attributes as Object[]).map((response) => {
      const category = parseCategory(response, false);
      return category;
    });

    this.setState({
      categories,
    });
  };

  getAllCatalogues = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson,
    };

    let urlParams = this.state.selectedCategories.length
      ? `category_ids=${this.state.selectedCategories.join(",")}`
      : null;

    this.getAllCataloguesApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: `${
        urlParams
          ? configJSON.getCataloguesEndPoint + "?" + urlParams
          : configJSON.getCataloguesEndPoint
      }`,
      header,
    });
  };

  getAllCategories = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson,
    };

    this.getAllCategoriesApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getCategoriesEndPoint,
      header,
    });
  };

  selectCategory = (identifier: number, updateCategories: boolean) => {
    this.setState((prevState) => ({
      tempSelectedCategories: prevState.tempSelectedCategories.includes(
        identifier
      )
        ? prevState.tempSelectedCategories.filter(
            (selectedCategory) => selectedCategory !== identifier
          )
        : [...prevState.tempSelectedCategories, identifier],
    }));

    updateCategories &&
      this.setState((prevState) => ({
        selectedCategories: prevState.selectedCategories.includes(identifier)
          ? prevState.selectedCategories.filter(
              (selectedCategory) => selectedCategory !== identifier
            )
          : [...prevState.selectedCategories, identifier],
      }));
  };

  resetCategoriesSelected = () => {
    this.setState({ tempSelectedCategories: [] });
  };

  discardCategoriesSelected = () => {
    this.setState((prevState) => ({
      tempSelectedCategories: [...prevState.selectedCategories],
    }));
  };

  applyCategoriesSelected = () => {
    this.setState((prevState) => ({
      selectedCategories: [...prevState.tempSelectedCategories],
    }));
  };

  searchServices = (searchQuery: string) => {
    this.setState({ searchQuery });
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson,
    };

    const attrs = {
      query: searchQuery,
      status: "active",
    };
    const queryParams = new URLSearchParams(attrs).toString();

    this.searchCataloguesApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: `${configJSON.searchCataloguesEndPoint}?${queryParams}`,
      header,
    });
  };

  deleteChip = () => {
    const searchQueryMessage = new Message(
      getName(MessageEnum.SearchQueryMessage)
    );

    searchQueryMessage.addData(getName(MessageEnum.SearchQueryMessage), " ");

    runEngine.sendMessage(searchQueryMessage.id, searchQueryMessage);

    this.props.navigation.navigate("Services");
  };
}
// Customizable Area End
