import React from "react";
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
} from "./stripeCommon";
// Customizable Area End

// Customizable Area Start
export interface GetUserProfileResponseType {
    data: {
        attributes: {
            account: {
                full_name: string;
                phone_number: string;
                email: string;
                designation: string;
                location: string;
                role: Array<string>;
                company: string;
                profile: string;
            }
        }
    }
}
export const configJSON = require("./config");
// Customizable Area End

interface SubmitStripeData {
    name: string;
    cardNumber: number | string,
    cardCVV: number | string,
    cardDate: number | string,
}
export interface StripesuccessRes {
    authentication_url: string,
    status: string,
}

export type Props = {
    // Customizable Area Start
    classes: Record<string, string>;
    handleSubmit: (event: SubmitStripeData) => void;
    stripeModalOpen: boolean;
    handleClose: () => void;
    // Customizable Area End
};

interface S {
    // Customizable Area Start
    number: string | number;
    name: string;
    expiry: string | number;
    cvc: string | number;
    issuer: string | number;
    focused: any;
    formData: unknown;
    setOpenStripeModal: boolean;
    isStripeModal: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class StripePaymentsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    form: React.ChangeEvent<HTMLFormElement> | unknown;
    getProfileDataApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            number: "",
            name: "",
            expiry: "",
            cvc: "",
            issuer: "",
            focused: "",
            formData: null,
            setOpenStripeModal: false,
            isStripeModal: this.props.stripeModalOpen
            // Customizable Area End
        };
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.form = React.createRef();
    }

    async componentDidMount() {
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handleCallback = (item: { issuer: string; maxLength: number }, isValid: boolean) => {
        if (isValid) {
            this.setState({ issuer: item.issuer });
        }
    };

    handleInputFocus = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            focused: target.name,
        });
    };

    handleName = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ name: target.value })
    }

    handleNumber = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        let value = formatCreditCardNumber(target.value);
        this.setState({ number: value })
    }

    handleExp = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        let value = formatExpirationDate(target.value);
        this.setState({ expiry: value })
    }

    handleCvv = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        let value = formatCVC(target.value, { number: "" });
        this.setState({ cvc: value })
    }

    handleSubmitForm = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        let data: SubmitStripeData = {
            name: this.state.name,
            cardNumber: this.state.number,
            cardDate: this.state.expiry,
            cardCVV: this.state.cvc
        }
        this.props.handleSubmit(data)
    };

    handleCloseModal = () => {
        this.props.handleClose()
    };
    // Customizable Area End
}