// Customizable Area Start
import * as React from "react";
import StoreDetailsController, {
  ICountry,
  IState,
  ICity,
} from "./StoreDetailsController.web";
import "../assets/StoreDetails.css";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import {
  Checkbox,
  withStyles,
  Grid,
  TextField,
  InputAdornment,
  FormControlLabel,
  createStyles,
  Theme,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Scrollbars } from "react-custom-scrollbars";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import withSnackBar from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import { withRouter } from "react-router-dom";
import { IndiaIcon, UKIcon, UEIcon, USIcon, EmptyIcon } from "./assets";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout"

export const configJSON = require("./config.js");

const flagMap = {
  gb: UKIcon,
  in: IndiaIcon,
  us: USIcon,
  ae: UEIcon,
  empty: EmptyIcon,
} as { [key: string]: string };

const getFlag = (countryCode: string) => {
  return flagMap[countryCode];
};

export class StoreDetails extends StoreDetailsController {
  renderCountryIndia = () => {
    const { classes } = this.props;
    const { cityState, zipcode, city } = this.state;
    
    return (
      <>
        <Grid item xs={12} md={3}>
          <div className="d-flex flex-column pb-24">
            <label className="input-label">{configJSON.state}</label>
            <Autocomplete<IState>
              id="state-list"
              data-testid="ac-state-list-overseas"
              options={this.state.states}
              classes={{
                option: classes.option,
                root: classes.countryTextField,
              }}
              open={this.state.openState}
              onOpen={this.handleStateOpen}
              onClose={this.handleStateClose}
              value={cityState}
              getOptionLabel={(option) => option?.attributes?.name || ""}
              renderOption={(option) => <>{option?.attributes?.name}</>}
              onChange={this.handleChangeState}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="cityState"
                  placeholder={configJSON.statePlaceholder}
                  InputProps={{
                    ...params.InputProps,
                    className: classes.countryAutocomplete,
                    startAdornment: <>{params.InputProps.startAdornment}</>,
                    endAdornment: (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={this.handleStateInverse}
                      >
                        <InputAdornment position="end">
                          <ArrowDropDownIcon />
                        </InputAdornment>
                      </div>
                    ),
                  }}
                  variant="outlined"
                />
              )}
            />
            <div style={{ height: "0.4em" }}>
              {!this.state.cityState?.id && this.state.localValidation && (
                <div id="state-error" className="validation-field">
                  {configJSON.requiredField}
                </div>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div id="city-dropdown" className="d-flex flex-column pb-24">
            <label className="input-label">{configJSON.city}</label>
            <Autocomplete<ICity>
              data-testid="ac-city-list"
              id="city-list"
              options={this.state.cities}
              classes={{
                option: classes.option,
                root: classes.countryTextField,
              }}
              open={this.state.openCity}
              onOpen={this.handleCityOpen}
              onClose={this.handleCityClose}
              value={city}
              getOptionLabel={(option) => option?.attributes?.name || ""}
              renderOption={(option) => <>{option?.attributes?.name}</>}
              onChange={this.handleChangeCity}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="city"
                  data-testid="txt-city"
                  placeholder={configJSON.cityPlaceholder}
                  InputProps={{
                    ...params.InputProps,
                    className: classes.countryAutocomplete,
                    startAdornment: <>{params.InputProps.startAdornment}</>,
                    endAdornment: (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={this.handleCityInverse}
                      >
                        <InputAdornment position="end">
                          <ArrowDropDownIcon />
                        </InputAdornment>
                      </div>
                    ),
                  }}
                  variant="outlined"
                />
              )}
            />
            <div style={{ height: "0.4em" }}>
              {!this.state.city?.id &&
                this.state.country?.attributes?.name === "India" &&
                this.state.isCityValidationActive && (
                  <div
                    id="city-error"
                    className="validation-field city-india-empty-field"
                  >
                    {configJSON.requiredField}
                  </div>
                )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className="d-flex flex-column pb-24">
            <label className="input-label">{configJSON.postCode}</label>
            <TextField
              InputProps={{
                className: classes.textField,
              }}
              name="zipcode"
              data-testid="txt-postCodeZIPPin"
              value={zipcode}
              placeholder={configJSON.codePlaceholder}
              onChange={this.handleChangeZIPCode}
              variant="outlined"
            />
            <div style={{ height: "0.4em" }}>
              {!this.state.zipcode && this.state.localValidation && (
                <div id="zipcode-error" className="validation-field">
                  {configJSON.requiredField}
                </div>
              )}
            </div>
          </div>
        </Grid>
      </>
    );
  };

  renderNonIndianCountry = () => {
    const { classes } = this.props;
    const { cityState, zipcode } = this.state;
    return (
      <>
        <Grid item xs={12} md={4}>
          <div className="d-flex flex-column pb-24">
            <label className="input-label">{configJSON.cityState}</label>
            <Autocomplete<IState, false, true>
              id="state-list"
              data-testid="ac-state-list"
              options={this.state.states}
              classes={{
                option: classes.option,
                root: classes.countryTextField,
              }}
              open={this.state.openState}
              onOpen={this.handleStateOpen}
              onClose={this.handleStateClose}
              value={cityState}
              getOptionLabel={(option) => option?.attributes?.name || ""}
              renderOption={(option) => <>{option?.attributes?.name}</>}
              onChange={this.handleChangeState}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="cityState"
                  data-testid="txt-cityState"
                  placeholder={configJSON.cityStatePlaceholder}
                  InputProps={{
                    ...params.InputProps,
                    className: classes.countryAutocomplete,
                    startAdornment: <>{params.InputProps.startAdornment}</>,
                    endAdornment: (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={this.handleStateInverse}
                      >
                        <InputAdornment position="end">
                          <ArrowDropDownIcon />
                        </InputAdornment>
                      </div>
                    ),
                  }}
                  variant="outlined"
                />
              )}
            />
            <div style={{ height: "0.4em" }}>
              {!this.state.cityState?.id && this.state.localValidation && (
                <div
                  id="state-error"
                  className="validation-field city-empty-field"
                >
                  {configJSON.requiredField}
                </div>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className="d-flex flex-column pb-24">
            <label className="input-label">{configJSON.postCode}</label>
            <TextField
              InputProps={{
                className: classes.textField,
              }}
              name="zipcode"
              data-testid="txt-postCodeZIPPin"
              value={zipcode}
              placeholder={configJSON.postCodePlaceholder}
              onChange={this.handleChangeZIPCode}
              variant="outlined"
            />
            <div style={{ height: "0.4em" }}>
              {!this.state.zipcode && this.state.localValidation && (
                <div id="zipcode-error" className="validation-field">
                  {configJSON.requiredField}
                </div>
              )}
            </div>
          </div>
        </Grid>
      </>
    );
  };

  renderIsWhatsAppIntegrationActive = () => {
    const { classes } = this.props;
    const {
      whatsAppAreaCode,
      whatsapp_number,
      whatsapp_message,
      whatsAppMessageTotalCharacter,
    } = this.state;
    return (
      <>
        <div className="d-flex flex-column pb-24">
          <label className="input-label">{configJSON.whatsappNumber}</label>
          <div className="area-code-text-field-container">
            <TextField
              InputProps={{
                className: classes.areaCodeSelect,
              }}
              SelectProps={{
                classes: { icon: classes.selectIconStyle },
              }}
              id="outlined-select-area-code"
              select
              value={whatsAppAreaCode}
              name="whatsAppAreaCode"
              data-testid="txt-whatsAppAreaCode"
              onChange={this.handleChangeInput}
              variant="outlined"
              size="small"
              error={!this.state.isWhatsappNumberValid}
            >
              {this.state?.locations?.map((country) => (
                <MenuItem
                  key={country.attributes?.phone_code}
                  value={country.attributes?.phone_code}
                >
                  <img
                    style={{
                      display: "inline-block",
                      width: "20px",
                      marginRight: "7px",
                    }}
                    src={getFlag(country?.attributes?.code) || getFlag("empty")}
                  />
                  {country?.attributes?.phone_code}
                </MenuItem>
              ))}
            </TextField>

            <div className="phone-number-container">
              <TextField
                InputProps={{
                  className: classes.textField,
                }}
                fullWidth
                name="whatsapp_number"
                data-testid="txt-whatsAppNumber"
                value={whatsapp_number}
                placeholder={configJSON.phoneNumberPlaceholder}
                onChange={this.handleChangeInput}
                variant="outlined"
                type="number"
                error={!this.state.isWhatsappNumberValid}
              />
            </div>
          </div>
          <div className="phone-number-info">
            {configJSON.whatsappNumberInfo}
          </div>
          {!this.state.isWhatsappNumberValid && (
            <div id="phone-number-error" className="validation-field">
              {configJSON.phoneNumberNotValid}
            </div>
          )}
        </div>
        <div className="d-flex flex-column">
          <div className="message-label-container">
            <label className="input-label">{configJSON.message}</label>
            <label className="input-label">{`${whatsAppMessageTotalCharacter}/200`}</label>
          </div>
          <TextField
            InputProps={{
              className: classes.textArea,
            }}
            fullWidth
            multiline
            minRows={4}
            name="whatsapp_message"
            data-testid="txt-whatsAppMessage"
            value={whatsapp_message}
            placeholder={configJSON.whatsappMessagePlaceholder}
            onChange={this.handleChangeInput}
            variant="outlined"
            size="small"
          />
          <div className="phone-number-info">
            {configJSON.whatsappMessageInfo}
          </div>
          {this.state.is_whatsapp_integration &&
            !this.state.whatsapp_message &&
            this.state.localValidation && (
              <div className="validation-field whatsapp-india-empty-field">
                {configJSON.whatsappMessageValidation}
              </div>
            )}
        </div>
      </>
    );
  };

  renderGSTField = () => {
    const { classes } = this.props;
    const { gst_number } = this.state;
    return (
      <div className="d-flex flex-column">
        <label className="input-label">{configJSON.gstNumber}</label>
        <TextField
          InputProps={{
            className: classes.textField,
          }}
          name="gst_number"
          data-testid="txt-GSTNumber"
          value={gst_number}
          placeholder={configJSON.gstNumberPlaceholder}
          onChange={this.handleChangeGST}
          variant="outlined"
        />
      </div>
    );
  };

  renderAddressOne = () => {
    const { classes } = this.props;
    const {
      areaCode,
      phone_number,
      is_whatsapp_integration,
      country,
      address_line_1,
      address_line_2,
    } = this.state;
    return (
      <div className="store-detail-container address-container justify-content-between">
        <label className="label-store-details">
          {configJSON.addressAndPhone}
        </label>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            md={this.state.country?.attributes?.name !== "India" ? 4 : 3}
          >
            <div className="d-flex flex-column pb-24">
              <label className="input-label">{configJSON.country}</label>
              <Autocomplete<ICountry>
                id="country-list"
                data-testid="ac-country-list"
                options={this.state.locations}
                classes={{
                  option: classes.option,
                  root: classes.countryTextField,
                }}
                open={this.state.openCountry}
                onOpen={this.handleCountryOpen}
                onClose={this.handleCountryClose}
                value={country}
                getOptionLabel={(option) => option?.attributes?.name || ""}
                renderOption={(option) => (
                  <>
                    {option && (
                      <span>
                        {this.countryToFlag(option?.attributes?.code)}
                      </span>
                    )}
                    {option?.attributes?.name} ({option?.attributes?.code})
                  </>
                )}
                onChange={this.handleChangeCountry}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="country"
                    data-testid="txt-country"
                    placeholder={configJSON.countryPlaceholder}
                    InputProps={{
                      ...params.InputProps,
                      className: classes.countryAutocomplete,
                      startAdornment: (
                        <>
                          <InputAdornment position="start">
                            <span style={{ marginTop: "3px" }}>
                              {this.countryToFlag(country?.attributes?.code)}
                            </span>
                          </InputAdornment>
                        </>
                      ),
                      endAdornment: (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={this.handleCountryInverse}
                        >
                          <InputAdornment position="end">
                            <ArrowDropDownIcon />
                          </InputAdornment>
                        </div>
                      ),
                    }}
                    variant="outlined"
                  />
                )}
              />
              <div style={{ height: "0.4em" }}>
                {!this.state.country?.id && this.state.localValidation && (
                  <div id="country-error" className="validation-field">
                    {configJSON.requiredField}
                  </div>
                )}
              </div>
            </div>
          </Grid>
          {this.state.country?.attributes?.name !== "India" ||
          !this.state.country
            ? this.renderNonIndianCountry()
            : this.renderCountryIndia()}
        </Grid>
        <div className="d-flex flex-column pb-24">
          <label className="input-label">{configJSON.addressLine1}</label>
          <TextField
            InputProps={{
              className: classes.textField,
            }}
            name="address_line_1"
            data-testid="txt-addressLine1"
            value={address_line_1}
            placeholder={configJSON.addressPlaceholder}
            onChange={this.handleChangeAddressOne}
            variant="outlined"
          />
          {!this.state.address_line_1 && this.state.localValidation && (
            <div id="address-error" className="validation-field">
              {configJSON.requiredField}
            </div>
          )}
        </div>
        <div className="d-flex flex-column pb-24">
          <label className="input-label">{configJSON.addressLine2}</label>
          <TextField
            InputProps={{
              className: classes.textField,
            }}
            name="address_line_2"
            data-testid="txt-addressLine2"
            value={address_line_2}
            placeholder={configJSON.addressPlaceholder2}
            onChange={this.handleChangeAddressTwo}
            variant="outlined"
          />
        </div>
        <div className="store-details-section-divider" />
        <div className="d-flex flex-column pb-24">
          <label className="input-label">{configJSON.phoneNumber}</label>
          <div className="area-code-text-field-container">
            <TextField
              InputProps={{
                className: classes.areaCodeSelect,
              }}
              SelectProps={{
                classes: { icon: classes.selectIconStyle },
              }}
              id="outlined-select-area-code"
              select
              value={areaCode}
              name="areaCode"
              data-testid="txt-areaCode"
              onChange={this.handleChangeInput}
              variant="outlined"
              size="small"
              error={!this.state.isPhoneNumberValid}
            >
              {this.state?.locations?.map((country) => (
                <MenuItem
                  key={country.attributes?.phone_code}
                  value={country.attributes?.phone_code}
                >
                  <img
                    style={{
                      display: "inline-block",
                      width: "20px",
                      marginRight: "7px",
                    }}
                    src={getFlag(country?.attributes?.code) || getFlag("empty")}
                  />
                  {country?.attributes?.phone_code}
                </MenuItem>
              ))}
            </TextField>

            <div className="phone-number-container">
              <TextField
                InputProps={{
                  className: classes.textField,
                }}
                fullWidth
                name="phone_number"
                data-testid="txt-phoneNumber"
                value={phone_number}
                placeholder={configJSON.phoneNumberPlaceholder}
                onChange={this.handleChangeInput}
                variant="outlined"
                type="number"
                error={!this.state.isPhoneNumberValid}
              />
            </div>
          </div>

          <div className="phone-number-info">{configJSON.phoneNumberInfo}</div>
          {!this.state.isPhoneNumberValid && (
            <div id="phone-number-error" className="validation-field">
              {configJSON.phoneNumberNotValid}
            </div>
          )}
        </div>
        <div className="d-flex flex-column pb-24">
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={is_whatsapp_integration}
                onChange={this.handleChangeInput}
                name="is_whatsapp_integration"
                data-testid="cb-isWhatsAppIntegrationActive"
              />
            }
            label={
              <div className="whatsapp-integration-label">
                {configJSON.whatsappIntegration}
              </div>
            }
          />
        </div>
        {is_whatsapp_integration && this.renderIsWhatsAppIntegrationActive()}
      </div>
    );
  };

  renderAddressTwo = () => {
    const { classes } = this.props;
    const { currency, currencies } = this.state;
    return (
      <div className="store-detail-container address-container justify-content-between">
        <label className="label-store-details-configuration">
          {configJSON.configuration}
        </label>
        <div className="d-flex flex-column pb-24">
          <label className="input-label">{configJSON.currency}</label>
          <TextField
            className={classes.textField}
            id="outlined-select-currency"
            select={true}
            value={currency?.symbol || ""}
            name="currency"
            data-testid="txt-currency"
            variant="outlined"
            size="small"
            disabled={true}
          >
            {currencies.map((option) => (
              <MenuItem key={option?.id} value={option?.symbol || ""}>
                <div className="currency-menu-item">
                  <span className="currency-pre-icon">{option.symbol}</span>
                  <span className="currency-content">{option.name}</span>
                </div>
              </MenuItem>
            ))}
          </TextField>
        </div>

        {this.state.country?.attributes?.name === "India" &&
          this.renderGSTField()}
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    const {
      name,
      email,
      customer_support_email,
      sender_email,
      loading,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <Scrollbars>
        <div className="store-detail-main-container">
          <div className="header">{configJSON.storeDetails}</div>
          <div className="store-detail-container justify-content-between">
            <label className="label-store-details">{configJSON.details}</label>
            <div className="d-flex flex-column pb-24">
              <label className="input-label">{configJSON.storeName}</label>
              <TextField
                InputProps={{
                  className: classes.textField,
                }}
                data-testid="txt-store-name"
                name="name"
                value={name}
                placeholder={configJSON.textStoreName}
                onChange={this.handleChangeStoreName}
                variant="outlined"
              />
            </div>
            <div className="d-flex flex-column pb-24" data-testid="test-email">
              <label className="input-label">{configJSON.email}</label>
              <TextField
                InputProps={{
                  className: classes.textField,
                }}
                data-testid="txt-email"
                name="email"
                value={email}
                placeholder={configJSON.textEnterEmail}
                onChange={this.handleChangeEmail}
                variant="outlined"
              />
              {!this.state.email && this.state.localValidation && (
                <div
                  className="validation-field"
                  data-testid="test-store-email-field"
                >
                  {configJSON.requiredField}
                </div>
              )}
              {this.state.localValidation &&
                !!this.state.email &&
                !this.validateEmail(this.state.email) && (
                  <div className="validation-field email-test-field">
                    {configJSON.emailValidation}
                  </div>
                )}
            </div>
            <div className="d-flex flex-column pb-24">
              <label className="input-label">
                {configJSON.customerSupportEmail}
              </label>
              <TextField
                InputProps={{
                  className: classes.textField,
                }}
                data-testid="txt-customer-support-email"
                name="customer_support_email"
                value={customer_support_email}
                placeholder={configJSON.customerSupportEmailPlaceholder}
                onChange={this.handleChangeInput}
                variant="outlined"
              />
            </div>
            <div className="d-flex flex-column">
              <label className="input-label">{configJSON.senderEmail}</label>
              <TextField
                InputProps={{
                  className: classes.textField,
                }}
                data-testid="txt-sender-email"
                name="sender_email"
                value={sender_email}
                placeholder={configJSON.senderEmailPlaceholder}
                onChange={this.handleChangeInput}
                variant="outlined"
              />
            </div>
          </div>
          {this.renderAddressOne()}
          {this.renderAddressTwo()}
        </div>
      </Scrollbars>
    );
  }
}

export const styles = (theme: Theme) =>
  createStyles({
    areaCodeSelect: {
      height: 48,
      width: "110px",
      color: "#3C3E49",
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
    },
    selectIconStyle: {
      color: "black",
    },
    textField: {
      width: "100%",
      height: 48,
      color: "#3C3E49",
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
    },
    countryTextField: {
      width: "100%",
      height: 48,
      color: "#3C3E49",
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
    },
    countryAutocomplete: {
      padding: "0px",
      paddingLeft: "12px",
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
      height: 48,
      paddingRight: 4,
      textOverflow: "ellipsis",
    },
    textArea: {
      width: "100%",
      color: "#3C3E49",
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
    },
    whatsappLabel: {
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 12,
      display: "flex",
      alignItems: "center",
      color: "#3C3E49",
    },
    option: {
      fontSize: 15,
      "& > span": {
        marginRight: 10,
        fontSize: 18,
      },
    },
    editUploadBoxContainer: {
      position: "relative",
      height: "160px",
      margin: "16px 0px 0px 0px",
      width: "144px",
    },
    crossOverImage: {
      right: "-5px",
      top: "-5px",
      position: "absolute",
      backgroundColor: theme.palette.primary.main,
      color: "white",
      padding: "3px",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      cursor: "pointer",
    },
    editUploadBoxImage: {
      borderRadius: "3px",
      width: "100%",
      maxHeight: "100%",
      objectFit: "contain",
      objectPosition: "center",
    },
    cropperBox: {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#FFF",
      borderRadius: "10px",
    },
    modalTitle: {
      color: "#3C3E49",
      fontSize: 28,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: 1.21,
      fontStretch: "normal",
      fontFamily: "Rubik",
    },
    closeIcon: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  });

export default withLayout(withStyles(styles)(
  withLoader(withSnackBar(withHeaderBar(withRouter(withDialog(StoreDetails)))))
));
// Customizable Area End