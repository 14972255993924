// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Brand } from "../../utilities/src/models/Brand";
import BlockHelpers from "../../utilities/src/BlockHelpers";
import { parseBrand } from "../../utilities/src/helpers/utils";
import { Platform } from "../../utilities/src/models/Platform";
import { Banner } from "../../utilities/src/models/Banner";
import {
  generateRequestMessage,
  isTokenExpired,
  clearStorageData,
} from "../../ss-cms-common-components/src/Utilities/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  brand: Brand | null;
  isSaved: boolean;
}

interface SS {
  id: any;
}

export default class DashboardAdminController extends BlockComponent<
  Props,
  S,
  SS
> {
  getBrandApiCallId: any;
  upsertBrandApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      brand: null,
      isSaved: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getBrand();
  }

  receive = async (_from: string, message: Message) => {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
    }
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getBrandApiCallId != null &&
      this.getBrandApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.handleGetBrandResponse(responseJson);
      } else {
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.upsertBrandApiCallId != null &&
      this.upsertBrandApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.getBrand();
        this.setState({ isSaved: true });
      } else {
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  };

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  handleGetBrandResponse = (responseJson: any) => {
    const response = responseJson.data;

    this.setState({
      brand: parseBrand(response),
    });
  };

  getBrand = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson,
    };

    this.getBrandApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getBrandEndPoint,
      header,
    });
  };

  upsertBrand = (
    values: any,
    isUpdate: boolean,
    deletedImagesIds?: number[]
  ) => {
    const header = {};

    const body = new FormData();
    this.setState({ isSaved: false });

    this.state.brand?.id &&
      body.append(
        "brand[brand_header_attributes][id]",
        `${this.state.brand?.id}`
      );
    values.logo instanceof File &&
      body.append("brand[brand_header_attributes][image]", values.logo);
    body.append("brand[brand_header_attributes][store_name]", values.storeName);

    body.append(
      "brand[brand_footer_attributes][id]",
      `${this.state.brand?.id}`
    );
    body.append(
      "brand[brand_footer_attributes][country_code]",
      values.countryCode
    );
    body.append(
      "brand[brand_footer_attributes][phone_number]",
      values.phoneNumber
    );
    body.append(
      "brand[brand_footer_attributes][copy_right_text]",
      values.copyright
    );

    Object.entries<Platform>(values.socialMedias).forEach(
      ([platformName, platform]) => {
        body.append(
          `brand[brand_footer_attributes][${platformName}]`,
          `${platform.selected}`
        );
        platform.selected &&
          body.append(
            `brand[brand_footer_attributes][${platformName}_url]`,
            platform.url
          );
      }
    );

    Object.entries<Platform>(values.applications).forEach(
      ([platformName, platform]) => {
        body.append(
          `brand[brand_footer_attributes][${platformName}]`,
          `${platform.selected}`
        );
        platform.selected &&
          body.append(
            `brand[brand_footer_attributes][${platformName}_url]`,
            platform.url
          );
      }
    );

    values.banners.forEach((banner: Banner, index: number) => {
      this.state.brand &&
        this.state.brand.banners &&
        this.state.brand.banners[index] &&
        body.append(
          `brand[brand_banners_attributes][${index}][id]`,
          `${this.state.brand.banners[index].id}`
        );
      banner.url &&
        body.append(
          `brand[brand_banners_attributes][${index}][redirect_url]`,
          banner.url
        );
      banner.image instanceof File &&
        body.append(
          `brand[brand_banners_attributes][${index}][image]`,
          banner.image
        );
    });

    deletedImagesIds &&
      deletedImagesIds.length > 0 &&
      body.append("brand[deleted_banner_ids]", deletedImagesIds.toString());

    body.append("brand[testimonial]", values.testimonial);

    this.upsertBrandApiCallId = BlockHelpers.callApi({
      method: isUpdate ? configJSON.putMethod : configJSON.postMethod,
      endPoint: isUpdate
        ? `${configJSON.upsertBrandEndPoint}/${this.state.brand?.id}`
        : configJSON.upsertBrandEndPoint,
      header,
      body,
    });
  };
}
// Customizable Area End
