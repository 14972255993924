import React from "react";
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area End

// Customizable Area Start

export const configJSON = require("./config");

export interface StripesuccessRes {
    authentication_url: string,
    status: string,
}

export type Props = {
    // Customizable Area Start
    classes: Record<string, string>;
    successModalOpen: boolean;
    stripeSuccessData: StripesuccessRes;
    // Customizable Area End
};

interface S {
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}
// Customizable Area End

export default class StripeSuccessController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            // Customizable Area End
        };
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handleCloseModal = () => {
        this.setState({ setOpenStripeModal: false });
    };
    // Customizable Area End
}