import React from "react";
// Customizable Area Start
import { Box, CardMedia, IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { Appfooter as useStyles } from "./styles/Appfooter.web";
import { Link } from "react-router-dom";
import { Platform } from "../../utilities/src/models/Platform";
import GooglePlayBadge from "../assets/google-play-badge.png";
import AppStoreBadge from "../assets/../assets/app-store-badge.png";
import ImageCard from "../../utilities/src/components/ImageCard.web";
import { StaticPage } from "./AppfooterController.web";

interface Props {
  countryCode: string;
  phoneNumber: string;
  socialMedias: Record<string, Platform>;
  applications: Record<string, Platform>;
  copyright: string;
  staticPages: StaticPage[];
}

const Appfooter: React.FC<Props> = ({
  countryCode,
  phoneNumber,
  socialMedias,
  applications,
  copyright,
  staticPages
}) => {
  const classes = useStyles();

  const { facebook, instagram, twitter, youtube } = socialMedias;
  const { android, ios } = applications;

  const staticAboutUs = staticPages.find(
    (staticPage: StaticPage) =>
      staticPage.attributes.page_type.name === "About us"
  )
  const staticTOS = staticPages.find(
    (staticPage: StaticPage) =>
      staticPage.attributes.page_type.name === "Terms of Service"
  )
  const staticPrivacy = staticPages.find(
    (staticPage: StaticPage) =>
      staticPage.attributes.page_type.name === "Privacy Policy"
  )
  const staticFAQs = staticPages.find(
    (staticPage: StaticPage) =>
      staticPage.attributes.page_type.name === "FAQs"
  )

  return (
    <>
      <Box className={classes.table}>
        <Box className={classes.column}>
          <Typography className={classes.header}>Contact us</Typography>
          <Box className={classes.infoWrapper}>
            <Link to={"/AddContact"} className={classes.info}>
              <Typography>Send a message</Typography>
            </Link>
            <Typography
              className={classes.info}
            >{`(${countryCode}) ${phoneNumber}`}</Typography>
          </Box>
        </Box>

        <Box className={classes.column}>
          <Typography className={classes.header}>Help Center</Typography>
          <Box className={`${classes.infoWrapper} ${classes.helpCenter}`}>
          {staticAboutUs && (
              <Link
                to={"/StaticPages/" + staticAboutUs.id}
                className={classes.info}
              >
                <Typography>About us</Typography>
              </Link>
            )}
            {staticFAQs && (
              <Link
                to={"/StaticPages/" + staticFAQs.id}
                className={classes.info}
              >
                <Typography>FAQs</Typography>
              </Link>
            )}
            {staticPrivacy && (
              <Link
                to={"/StaticPages/" + staticPrivacy.id}
                className={classes.info}
              >
                <Typography>Privacy Policy</Typography>
              </Link>
            )}
            {staticTOS && (
              <Link
                to={"/StaticPages/" + staticTOS.id}
                className={classes.info}
              >
                <Typography>Terms of Service</Typography>
              </Link>
            )}
          </Box>
        </Box>

        {(facebook || instagram || twitter || youtube) && (
          <Box className={classes.column}>
            <Typography className={classes.header}>Social</Typography>
            <Box className={classes.row}>
              {facebook && (
                <IconButton onClick={() => window.open(facebook.url)}>
                  <FacebookIcon className={classes.icon} />
                </IconButton>
              )}
              {instagram && (
                <IconButton onClick={() => window.open(instagram.url)}>
                  <InstagramIcon className={classes.icon} />
                </IconButton>
              )}
              {twitter && (
                <IconButton onClick={() => window.open(twitter.url)}>
                  <TwitterIcon className={classes.icon} />
                </IconButton>
              )}
              {youtube && (
                <IconButton onClick={() => window.open(youtube.url)}>
                  <YouTubeIcon className={classes.icon} />
                </IconButton>
              )}
            </Box>
          </Box>
        )}

        {(android || ios) && (
          <Box className={classes.column}>
            <Typography className={classes.header}>Download App</Typography>
            <Box className={classes.imagesWrapper}>
              {android && (
                <ImageCard
                  imageClassName={classes.image}
                  image={GooglePlayBadge}
                  onClick={() => window.open(android.url)}
                />
              )}
              {ios && (
                <ImageCard
                  imageClassName={classes.image}
                  image={AppStoreBadge}
                  onClick={() => window.open(ios.url)}
                />
              )}
            </Box>
          </Box>
        )}
      </Box>

      <Typography component="div" className={classes.copyrights}>
        {`${copyright} - Powered by `}
        <a href="https://www.builder.ai/">Builder.ai</a>
      </Typography>
    </>
  );
};

export default Appfooter;
// Customizable Area End