import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { withToastProps } from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { RouterProps } from "react-router";
import { styles } from "./StoreDetails.web";
import { WithStyles } from "@material-ui/core";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import {
  checkResponseError,
} from "../../ss-cms-common-components/src/ErrorHandler/ErrorHandler";
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import { parseExpireTokenResponse, logoutAndRedirectToLoginPage, generateRequestMessage, showError, isTokenExpired, clearStorageData } from "../../ss-cms-common-components/src/Utilities/Utilities";
import { setStorageData } from "../../../framework/src/Utilities";

export interface ICountryAttributes {
  id: number;
  name: string;
  code: string;
  currency: ICurrency;
  created_at: Date;
  updated_at: Date;
  phone_code: string;
}

export interface ICountry {
  id: string;
  type: string;
  attributes: ICountryAttributes;
}

export interface IStateAttribute {
  id: number;
  name: string;
  code: string;
  gst_code: string;
  country_id: number;
  created_at: Date;
  updated_at: Date;
}

export interface IState {
  id: number;
  type: string;
  attributes: IStateAttribute;
}

export interface ICityAttribute {
  id: number;
  name: string;
  address_state_id: number;
  created_at: Date;
  updated_at: Date;
}

export interface ICity {
  id: number;
  type: string;
  attributes: ICityAttribute;
}

export interface ICurrency {
  id: number;
  name: string;
  symbol: string;
  country_id: number;
  created_at: Date;
  updated_at: Date;
}

export interface IStoreDetails {
  name: string;
  email: string;
  sender_email: string;
  customer_support_email: string;
  country_id: null | number;
  address_state_id: null | number;
  city_id: null | number;
  zipcode: null | string;
  address_line_1: null | string;
  address_line_2: null | string;
  phone_number: null | string;
  is_whatsapp_integration: boolean;
  whatsapp_number: null | string;
  whatsapp_message: null | string;
  currency_id: null | number;
  gst_number: null | string;
  currency_symbol: null | string;
  country_code: null | string;
  created_at?: string;
  updated_at?: string;
  wildcard?: string | number | boolean;
}

// Customizable Area End

export const configJSON = require("./config");

export type Props = DialogProps &
  RouterProps &
  withHeadeActionBarProps &
  withLoaderProps &
  withToastProps &
  WithStyles<typeof styles> & {
    navigation: {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

type S = IStoreDetails & {
  // Customizable Area Start
  locations: ICountry[];
  states: IState[];
  cities: ICity[];
  currencies: ICurrency[];
  currency: ICurrency;
  areaCode: string;
  whatsAppAreaCode: string;
  whatsAppMessageTotalCharacter: number;
  country: ICountry;
  cityState: IState;
  city: ICity;
  localValidation: boolean;
  isCityValidationActive: boolean;
  loading: boolean;
  openCountry: boolean;
  openState: boolean;
  openCity: boolean;
  isPhoneNumberValid: boolean;
  isWhatsappNumberValid: boolean;
  // Customizable Area End
};

interface SS {
  id: string;
  value: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class StoreDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updateStoreDetailApiCallId: string = "";
  getLocationsDataApiCallId: string = "";
  getStatesDataApiCallId: string = "";
  getCitiesDataApiCallId: string = "";
  brandSettingDataApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.ActionMessageFromToaster),
    ];
    this.state = {
      locations: [],
      states: [],
      cities: [],
      currencies: [] as ICurrency[],
      currency: {} as ICurrency,
      areaCode: "",
      whatsAppAreaCode: "",
      whatsAppMessageTotalCharacter: 0,
      country: {} as ICountry,
      cityState: {} as IState,
      city: {} as ICity,
      localValidation: false,
      isCityValidationActive: false,
      loading: true,
      openCountry: false,
      openState: false,
      openCity: false,
      isPhoneNumberValid: true,
      isWhatsappNumberValid: true,

      // store details keys
      name: "",
      email: "",
      sender_email: "",
      customer_support_email: "",
      country_id: null,
      address_state_id: null,
      city_id: null,
      zipcode: "",
      address_line_1: "",
      address_line_2: "",
      phone_number: "",
      is_whatsapp_integration: false,
      whatsapp_number: "",
      whatsapp_message: "",
      currency_id: null,
      gst_number: "",
      currency_symbol: "",
      country_code: "",
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.props.displaySpinner();
    this.getLocations();
    setTimeout(() => {
      this.getBrandSettings();
    }, 1000);
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message?.id) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      
      if (apiRequestCallId != null) {
        this.messageGetCountriesDataApiCall(message);
        this.messageGetStatesDataApiCall(message);
        this.messageGetCitiesDataApiCall(message);
        this.messageUpdateStoreDetailApiCall(message);
        this.messageBrandSettingDataApiCall(message);
      }
      
    } else if (getName(MessageEnum.AlertMessage) === message?.id) {
      this.handleAlertBodyMessage(message);
    } else if (getName(MessageEnum.ActionMessageFromToaster)) {
      const type = message.getData(
        getName(MessageEnum.ActionMessageFromToasterMessage)
      );
      this.handleActionMessageFromToaster(type);
    }
    // Customizable Area End
  }

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  handleAlertBodyMessage = (message: Message) => {
    const AlertBodyMessage = message.getData(
      getName(MessageEnum.AlertBodyMessage)
    );

    if (
      (AlertBodyMessage === configJSON.stateNotFound &&
        !!this.state.states?.length) ||
      (AlertBodyMessage === configJSON.countryNotFound &&
        !!this.state.locations?.length)
    )
      return;
    else {
      this.props.showToast({
        type: "error",
        message: AlertBodyMessage,
      });
    }
  };

  handleActionMessageFromToaster = (type: string) => {
    if (type === "SAVECHANGES") {
      this.updateStoreDetail();
    } else if (type === "DISCARDCHANGES") {
      this.props.displaySpinner();
      this.getBrandSettings();
    }
  };

  messageGetCountriesDataApiCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (apiRequestCallId === this.getLocationsDataApiCallId) {
      if (responseJson?.data?.length > 0) {
        const locations = responseJson?.data;
        const currencies = locations?.map(
          (location: { attributes: { currency: ICurrency } }) => {
            return location?.attributes?.currency;
          }
        );
        this.setState({ locations, currencies }, () => {
          this.getSelectedCountry(this.state.country_id);
        });
      } else {
        showError(
          configJSON.textSomethingWentWrong,
          responseJson,
          this.props.hideLoader,
          this.props.setDialogState
        );
      }

      setTimeout(() => {
        this.props.hideLoader();
      }, 1100);
    }
  };

  messageGetStatesDataApiCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (apiRequestCallId === this.getStatesDataApiCallId) {
      if (responseJson?.data?.length > 0) {
        const states = responseJson?.data;
        let selectedState = states?.filter((state: IState) => {
          return Number(state?.id) === Number(this.state.address_state_id);
        })?.[0];
        this.setState(
          {
            states,
            cityState: selectedState,
          },
          () => {
            Number(this.state.country?.id) == 1 &&
              this.getCities(this.state.cityState?.id);
          }
        );
      } else {
        showError(
          configJSON.textSomethingWentWrong,
          responseJson,
          this.props.hideLoader,
          this.props.setDialogState
        );
      }
      this.props.hideLoader();
    }
  };

  messageGetCitiesDataApiCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (apiRequestCallId === this.getCitiesDataApiCallId) {
      if (responseJson?.data?.length > 0) {
        const cities = responseJson.data;
        let selectedCity = cities?.filter((city: ICity) => {
          return Number(city?.id) == Number(this.state.city_id);
        })?.[0];
        this.setState({
          cities,
          city: selectedCity,
        });
      } else {
        showError(
          configJSON.textSomethingWentWrong,
          responseJson,
          this.props.hideLoader,
          this.props.setDialogState
        );
      }
      this.props.hideLoader();
    }
  };

  messageUpdateStoreDetailApiCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (apiRequestCallId === this.updateStoreDetailApiCallId) {
      if (checkResponseError(message)) {
        return showError(
          configJSON.updateError,
          responseJson,
          this.props.hideLoader,
          this.props.setDialogState
        );
      }
      this.props.showHeaderBar({
        message: configJSON.successMessage,
        type: "success",
      });
      setStorageData(
        "currency_type",
        responseJson.data.attributes.currency_symbol
      );
      setStorageData(
        "store_name",
        responseJson.data.attributes.name
      );
      this.props.hideLoader();
    }
  };

  getSelectedCountry = (countryId: null | number) => {
    let selectedCountry = this.state.locations?.filter((country) => {
      return country?.attributes?.id == countryId;
    })?.[0];
    if (selectedCountry) {
      this.setState(
        {
          country: selectedCountry,
          currency: selectedCountry?.attributes?.currency,
        },
        () => this.getStates(Number(countryId))
      );
    }
  };

  messageBrandSettingDataApiCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (apiRequestCallId === this.brandSettingDataApiCallId) {
      if (responseJson?.data?.attributes) {
        const { whatsapp_number = "", phone_number = "" } =
          responseJson?.data?.attributes || {};
        this.setState({
          loading: false,
          ...(responseJson?.data?.attributes || {}),
          whatsAppMessageTotalCharacter:
            responseJson?.data?.attributes?.whatsapp_message?.length || 0,
          whatsapp_number: (whatsapp_number||"").split(" ")[1],
          whatsAppAreaCode: (whatsapp_number||"").split(" ")[0],
          phone_number: (phone_number || "").split(" ")[1],
          areaCode: (phone_number || "").split(" ")[0],
        });
        this.getSelectedCountry(responseJson?.data?.attributes?.country_id);
      } else {
        this.setState({ loading: false });
        showError(
          configJSON.textSomethingWentWrong,
          responseJson,
          this.props.hideLoader,
          this.props.setDialogState
        );
      }
      this.props.hideLoader();
    }
  };

  getCountryWithCode = (value: string) =>
    this.state.locations.find(
      (country) => country?.attributes?.phone_code.toString() === value
    ) || this.state.locations[0];

  resetCountry() {
    this.setState({
      country: {} as ICountry,
      cityState: {} as IState,
      city: {} as ICity,
      states: [],
      cities: [],
      currency: {} as ICurrency,
    });
  }

  openToastOnChange = () => {
    this.props.showHeaderBar({});
    return true;
  };

  handleChangeInput = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (event.target.name === "whatsapp_message") {
      this.setState({
        whatsAppMessageTotalCharacter: event?.target?.value?.length,
      });
      if (event?.target?.value?.length > 199) return;
    }
    if (
      event.target.name === "phone_number" ||
      event.target.name === "areaCode"
    ) {
      this.setState({
        isPhoneNumberValid: true,
      });
    }
    if (
      event.target.name === "is_whatsapp_integration" ||
      event.target.name === "whatsapp_number" ||
      event.target.name === "whatsAppAreaCode"
    ) {
      this.setState({
        isWhatsappNumberValid: true,
      });
    }
    if (event.target.name === "is_whatsapp_integration") {
      this.setState({
        whatsapp_message:
          event?.target?.type === "checkbox" ? "" : this.state.whatsapp_message,
        whatsapp_number:
          event?.target?.type === "checkbox" ? "" : this.state.whatsapp_number,
        whatsAppMessageTotalCharacter:
          event?.target?.type === "checkbox"
            ? 0
            : this.state.whatsAppMessageTotalCharacter,
      });
    }
    this.setState({
      [event.target.name as "wildcard"]:
        event?.target?.type === "checkbox"
          ? (event?.target as HTMLInputElement)?.checked || false
          : event?.target?.value,
    });
    this.openToastOnChange();
  };

  handleChangeStoreName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length < 51) {
      this.setState({ name: event.target.value });
      this.openToastOnChange();
    } else {
      return false;
    }
  };

  handleChangeCountry = (
    _event: React.ChangeEvent<{}>,
    value: ICountry | null
  ) => {
    if (value === null) {
      this.resetCountry();
    } else {
      this.setState(
        {
          country: value,
          currency: value?.attributes?.currency,
          cityState: {} as IState,
          city: {} as ICity,
          states: [],
          cities: [],
          areaCode: value?.attributes?.phone_code,
          whatsAppAreaCode: value?.attributes?.phone_code,
        },
        () => this.getStates(Number(this.state.country?.id || 1))
      );
    }
    this.openToastOnChange();
  };

  handleChangeState = (_event: React.ChangeEvent<{}>, value: IState | null) => {
    this.setState(
      {
        cityState: (value ?? {}) as IState,
        city: {} as ICity,
        cities: [],
      },
      () => {
        if (Number(this.state.country.id) === 1) {
          this.getCities(this.state.cityState?.id);
        }
      }
    );
    this.openToastOnChange();
  };

  handleChangeCity = (_event: React.ChangeEvent<{}>, value: ICity | null) => {
    this.setState({
      city: value ?? ({} as ICity),
    });
    this.openToastOnChange();
  };

  handleChangeGST = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length < 16) {
      this.setState({
        gst_number: event.target.value.replace(/[^a-z0-9]/gi, ""),
      });
      this.openToastOnChange();
    } else {
      return false;
    }
  };

  handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value });
    this.openToastOnChange();
  };

  handleChangeZIPCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event.target.value.length < 11 &&
      event.target.value.search(/^([a-zA-Z0-9\- ]){1,20}$/) !== -1
    ) {
      this.setState({ zipcode: event.target.value });
      this.openToastOnChange();
    } else if (!event.target.value.length) {
      this.setState({ zipcode: event.target.value });
    }
  };

  handleChangeAddressOne = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length < 51) {
      this.setState({ address_line_1: event.target.value });
      this.openToastOnChange();
    } else {
      return false;
    }
  };

  handleChangeAddressTwo = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length < 51) {
      this.setState({ address_line_2: event.target.value });
      this.openToastOnChange();
    } else {
      return false;
    }
  };

  async getBrandSettings() {
    const getBrandSettings = await generateRequestMessage(
      configJSON.storeDetailsAPI,
      configJSON.getApiMethod
    );
    this.brandSettingDataApiCallId = getBrandSettings.messageId;
    runEngine.sendMessage(getBrandSettings.id, getBrandSettings);
  }

  validateEmail = (email: string) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/i);
  };

  updateStoreDetail() {
    let isCityValidationActive;
    let whatsappMessageValidation = this.state.is_whatsapp_integration;
    if (this.state.country?.attributes?.name === "India") {
      isCityValidationActive = !this.state.city?.id;
    } else {
      isCityValidationActive = false;
    }
    const whatsAppNumber = `${
      this.getCountryWithCode(this.state.whatsAppAreaCode || "")?.attributes
        ?.phone_code
    } ${this.state.whatsapp_number}`;

    const phoneNumber = `${this.getCountryWithCode(this.state.areaCode || "")
      ?.attributes?.phone_code || ""} ${this.state.phone_number}`;
    const isPhoneNumberValid =
      this.state.areaCode === "+971"
        ? this.state.phone_number?.length === 9
        : this.state.phone_number?.length === 10;

    const isWhatsappNumberValid = !this.state.is_whatsapp_integration
      ? true
      : this.state.whatsAppAreaCode === "+971"
      ? this.state.whatsapp_number?.length === 9
      : this.state.whatsapp_number?.length === 10;

    this.setState(
      {
        isPhoneNumberValid,
        isWhatsappNumberValid,
        localValidation:
          !this.state.name ||
          !this.state.email ||
          !this.state.country?.id ||
          !this.state.cityState?.id ||
          !this.state.zipcode ||
          !this.state.address_line_1 ||
          !this.validateEmail(this.state.email) ||
          (whatsappMessageValidation && !this.state.whatsapp_message) ||
          !isPhoneNumberValid ||
          !isWhatsappNumberValid,
        isCityValidationActive,
      },
      async () => {
        if (!this.state.localValidation && !this.state.isCityValidationActive) {
          this.props.displaySpinner();
          const storeDetails = {} as IStoreDetails;
          const keys = configJSON.backendDataKeys;
          keys.forEach((key: string) => {
            Object.assign(storeDetails, { [key]: this.state[key as keyof S] });
          });
          Object.assign(storeDetails, { [configJSON.backendDataKeyCountryId]: Number(this.state.country?.id) })
          Object.assign(storeDetails, { [configJSON.backendDataKeyCurrencyId]: Number(this.state.country?.id) })
          Object.assign(storeDetails, { [configJSON.backendDataKeyAddressStateId]: this.state.cityState?.id })
          Object.assign(storeDetails, { [configJSON.backendDataKeyCityId]: Number(this.state.country?.id) === 1 ? this.state.city?.id : null })
          Object.assign(storeDetails, { [configJSON.backendDataKeyWhatsappNumber]: whatsAppNumber })
          Object.assign(storeDetails, { [configJSON.backendDataKeyPhoneNumber]: phoneNumber })

          const updateStoreDetail = await generateRequestMessage(
            `${configJSON.updateStoreDetailAPI}`,
            configJSON.putAPiMethod
          );
          this.updateStoreDetailApiCallId = updateStoreDetail.messageId;
          updateStoreDetail.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(storeDetails)
          );
          runEngine.sendMessage(updateStoreDetail.id, updateStoreDetail);
        } else {
          return false;
        }
      }
    );
  }

  async getLocations() {
    this.props.displaySpinner();
    const getLocationsData = await generateRequestMessage(
      configJSON.getLocationsAPI + configJSON.backendShowAllDataQuery,
      configJSON.getApiMethod
    );
    this.getLocationsDataApiCallId = getLocationsData.messageId;
    runEngine.sendMessage(getLocationsData.id, getLocationsData);
  }

  async getStates(locationId: number) {
    if (!locationId) {
      return;
    }
    this.props.displaySpinner();
    const getStatesData = await generateRequestMessage(
      `${configJSON.getLocationsAPI}/${locationId ||
        1}/${configJSON.backendAddressStatesRoute}${configJSON.backendShowAllDataQuery}`,
      configJSON.getApiMethod
    );
    this.getStatesDataApiCallId = getStatesData.messageId;
    runEngine.sendMessage(getStatesData.id, getStatesData);
  }

  async getCities(locationId: number) {
    if (!locationId) {
      return;
    }
    this.props.displaySpinner();
    const getCitiesData = await generateRequestMessage(
      `${configJSON.citiesAPI}/${locationId}${configJSON.backendAddressCitiesRoute}${configJSON.backendShowAllDataQuery}`,
      configJSON.getApiMethod
    );
    this.getCitiesDataApiCallId = getCitiesData.messageId;
    runEngine.sendMessage(getCitiesData.id, getCitiesData);
  }
  countryToFlag = (isoCode: ICountryAttributes["code"]) => {
    if (!isoCode) return null;
    return typeof String.fromCodePoint !== "undefined"
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : isoCode;
  };
  handleCountryOpen = () => {
    this.setState({ openCountry: true });
  };
  handleCountryClose = () => {
    this.setState({ openCountry: false });
  };
  handleCountryInverse = () => {
    this.setState({ openCountry: !this.state.openCountry });
  };
  handleStateOpen = () => {
    this.setState({ openState: true });
  };
  handleStateClose = () => {
    this.setState({ openState: false });
  };
  handleStateInverse = () => {
    this.setState({ openState: !this.state.openState });
  };
  handleCityOpen = () => {
    this.setState({ openCity: true });
  };
  handleCityClose = () => {
    this.setState({ openCity: false });
  };
  handleCityInverse = () => {
    this.setState({ openCity: !this.state.openCity });
  };
}
