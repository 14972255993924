import * as React from 'react';
import './assets/css/style.css';

interface ProgressTrackerProps {
    children: any
}

interface ProgressStepProps {
    children: any,
    status: 'pending' | 'progress' | 'done',
    className?: any
}

export const ProgressTracker: React.FunctionComponent<ProgressTrackerProps> = (props) => {
    const { children } = props
    return (
        <div className='progress-tracker'>
            {children}
        </div>
    );
}

export const ProgressStep: React.FunctionComponent<ProgressStepProps> = (props) => {
    const { children, status, className } = props
    return (
        <div className={`progress-step progress-step--${status} ${className || ""}`}>
            {children}
        </div>
    );
}