// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import BlockHelpers from "../../utilities/src/BlockHelpers";
import {
  parseBrand,
  parseCatalogue,
  parseLocation,
} from "../../utilities/src/helpers/utils";
import { Location } from "../../utilities/src/models/Location";
import { Service } from "../../utilities/src/models/Service";
import { Banner } from "../../utilities/src/models/Banner";
import { Navigation } from "../../utilities/src/models/Navigation";
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import parser from "html-react-parser";

export const configJSON = require("./config");

export interface Props {
  navigation: Navigation;
  identifier: string;
}

interface S {
  banners: Banner[];
  topServices: Service[];
  showTestimonials: boolean;
  locationDetails: Location | null;
  testimonials: Record<string, string | number>[];
}

interface SS {
  identifier: string;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  getBrandsApiCallId: string;
  getTopServicesApiCallId: string;
  getLocationApiCallId: string;
  getCustomerFeedbackApiCallId: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      banners: [],
      topServices: [],
      showTestimonials: false,
      locationDetails: null,
      testimonials: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getBrands();
    this.getTopServices();
    this.getLocation();
    this.getCustomerFeedbacks();
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Recived", message);
    this.handleCustomerFeedbackResponse(message);

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getBrandsApiCallId != null &&
      this.getBrandsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.handleGetBrandsResponse(responseJson);
      } else {
        this.setState({
          banners: [],
          showTestimonials: false,
        });

        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTopServicesApiCallId != null &&
      this.getTopServicesApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.handleGetTopServicesResponse(responseJson);
      } else {
        this.setState({ topServices: [] });

        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getLocationApiCallId != null &&
      this.getLocationApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.handleGetLocationResponse(responseJson);
      } else {
        this.setState({ locationDetails: null });

        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  };

  handleGetBrandsResponse = (
    responseJson: Record<string, string | number | Object | Object[]>
  ) => {
    const response = responseJson.data;

    const { banners, testimonial } = parseBrand(response);

    this.setState({ banners, showTestimonials: testimonial });
  };

  handleGetTopServicesResponse = (
    responseJson: Record<string, string | number | Object | Object[]>
  ) => {
    const services: Service[] = (responseJson.data as Object[]).map(
      (response) => parseCatalogue(response)
    );

    this.setState({
      topServices: services,
    });
  };

  handleGetLocationResponse = (
    responseJson: Record<string, string | number | Object | Object[]>
  ) => {
    const response = responseJson.data;

    this.setState({
      locationDetails: parseLocation(response),
    });
  };

  handleCustomerFeedbackResponse = (message: Message) => {
    if(getName(MessageEnum.RestAPIResponceMessage) !== message.id){
      return;
    }
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if(this.getCustomerFeedbackApiCallId === apiRequestCallId){
      const list = responseJson.data.filter((i: {attributes: {is_active: boolean}}) => i.attributes.is_active)
      list.sort((a: any,b: any) => a.attributes.position - b.attributes.position)
      this.setState({
          testimonials: list.map((i: any) => ({
            name: i.attributes?.customer_name,
            rating: 5,
            description: parser(i.attributes?.description)
          }))
      })
    }
  }

  getBrands = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson,
    };

    this.getBrandsApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getBrandsEndPoint,
      header,
    });
  };

  getTopServices = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson,
    };

    this.getTopServicesApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getTopServicesEndPoint,
      header,
    });
  };

  getLocation = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson,
    };

    this.getLocationApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getLocationEndPoint,
      header,
    });
  };

  getCustomerFeedbacks = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson,
    };
  
    this.getCustomerFeedbackApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getCustomerFeedbackEndPoint + "?per_page=100",
      header,
    });
  }
}
// Customizable Area End
