// Customizable Area Start
import { Theme, makeStyles } from "@material-ui/core";

export const Appfooter = makeStyles((theme: Theme) => ({
  table: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: theme.spacing(5),
    gap: theme.spacing(15),
    borderTop: "1px solid #DFE0E6",
    backgroundColor: "#F8F9FA",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: theme.spacing(3),
      gap: theme.spacing(3.125)
    }
  },
  column: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  row: {
    display: "flex",
    alignItems: "flex-start",
    gap: theme.spacing(3),
    "& button": { padding: theme.spacing(0) },
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(2)
    }
  },
  header: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    marginBottom: theme.spacing(2),
    color: "#000000"
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1)
  },
  helpCenter: {
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateRows: "repeat(2,auto)",
      gridAutoFlow: "column",
      columnGap: "57px"
    }
  },
  info: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "#676B7E",
    textDecoration: "none"
  },
  icon: {
    color: "#898F9C"
  },
  imagesWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(0.5),
      maxWidth: "134px"
    }
  },
  image: {
    height: "40px",
    cursor: "pointer"
  },
  copyrights: {
    display: "flex",
    justifyContent: "center",
    fontSize: "10px",
    lineHeight: "24px",
    whiteSpace: "pre",
    backgroundColor: "#DFE0E6",
    color: "#83889E"
  }
}));
// Customizable Area End