import React from 'react';
// Customizable Area Start
import { createStyles, withStyles } from '@material-ui/core/styles';
import StripeSuccessController from './StripeSuccessController.web';
import "react-credit-cards/es/styles-compiled.css";
import { Box, Fade, Modal } from "@material-ui/core";
// Customizable Area End

class StripeSuccessModal extends StripeSuccessController {
    render() {
        // Customizable Area Start
        const style = this.props.classes;
        return (
            <>
                <Modal data-test-id="successOpenModalTestID"
                    open={this.props.successModalOpen}
                    onClose={this.handleCloseModal}
                    className={style.modal}>
                    <Fade in={this.props.successModalOpen}>
                        <Box className={style.paper}>
                            <iframe
                                width="100%"
                                height="100%"
                                style={{ display: "block" }}
                                src={this.props.stripeSuccessData.authentication_url}
                                allowFullScreen
                            ></iframe>
                        </Box>
                    </Fade>
                </Modal>

            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
const useStyle = () =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: "#fff",
            width: "60%"
        }

    });
export default withStyles(useStyle, { withTheme: true })(StripeSuccessModal);
export { StripeSuccessModal };
// Customizable Area End