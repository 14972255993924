Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.contentTypeApplicationJson = "application/json";
exports.getMethod = "GET";
exports.getBrandsEndPoint = "catalogue/brands";
exports.getTopServicesEndPoint = "bx_block_catalogue/catalogues/top_services";
exports.getLocationEndPoint = "bx_block_settings/common_settings";
exports.getCustomerFeedbackEndPoint = "bx_block_customer_feedback/customer_feedbacks";
// Customizable Area End
