Object.defineProperty(exports, "__esModule", {
  value: true
});
// Customizable Area Start
exports.paymentsAdminURL = `bx_block_payment/payment_methods`;
exports.getMethod = "GET";
exports.putMethod = "PUT";
exports.activationStatusLinkBase = "https://staging.cloud-marketplace.builder.ai/api/accounts/";
exports.errorPageInitialisation = "Error on page initialization";
exports.errorCardUpdate = "Error on card update";
exports.errorOnSave = "Error on save";
exports.basic = "Basic ";
exports.errorActivation = "Error on Activation Status Check";
exports.payment = "Payment";
exports.notActive = "Not active";
exports.razorpayTitle = "Razorpay";
exports.stripeTitle = "Stripe";
exports.paymentTitle = "Payment";
exports.dummyPassword = "*************";
exports.useToGetMessage = "Use to get online payment seamlessly into your bank account";
exports.viewDashboardLink = "https://dashboard.razorpay.com/signin?screen=sign_in";
exports.consoleErrorBase = "Async: Could not copy text: ";
exports.paymentGateway = "Payment Gateways";
exports.paymentMethods = "Payment Methods";
exports.fieldRequired = "This field is required";
exports.apiKey = "API Key";
exports.apiSecretKey = "API Secret Key";
exports.stripeIntegration = "Stripe Integration";
exports.paymentIntegration = "Payment Integration";
exports.active = "Active";
exports.inactive = "Inactive";
exports.razorPay = "RazorPay";
exports.stripe = "Stripe";
exports.user = "User:";
exports.password = "Password:";
exports.stars = "*************";
exports.paymentCardHint = "Use to get online payment seamlessly into your bank account";
exports.viewDashboard = "View Dashboard";
exports.activateAccount = "Activate Account";
exports.editPayment = "Edit Payment";
exports.enablePaymentMode = "Enable/Disable Payment Modes";
exports.cashOnDelivery = "Cash On Delivery (COD)";
exports.onlinePayments = "Online Payments";
exports.storeDetailsAPI = '/bx_block_store_details/store_profile';

exports.dialog_title = "Error on save";
exports.dialog_msg = "At least one option must be selected";
exports.dialog_bg = "#FF1744";
exports.dialogCFHover = "rgb(240, 25, 73)";
// Customizable Area End
