Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ccavenueintegration";
exports.labelBodyText = "ccavenueintegration Body";
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.deleteApiMethod = "DELETE";

exports.getOrdersApiEndPoint = "shopping_cart/orders";
exports.createOrderItemApiEndPoint = "shopping_cart/order_items";
exports.deleteOrderItemApiEndPoint = "shopping_cart/orders";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.btnExampleTitle = "CLICK ME";
exports.redirectUrl = "Ccavenueintegration2CartOrder";
exports.cancelUrl = "Ccavenueintegration2CartOrder";
exports.gatewayApiEndpoint =
  "bx_block_ccavenueintegration/check_out_encrypt_data";
exports.paymentDoneUrl =
  "https://test.ccavenue.com/bnk/servlet/processNbkReq?gtwID=AVN&requestType=PAYMENT";
exports.webViewUrl = "bx_block_ccavenueintegration/iframe?enc_resp=";
exports.injectedJavaScript =
  "const meta = document.createElement('meta'); meta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=0.99, user-scalable=0'); meta.setAttribute('name', 'viewport'); document.getElementsByTagName('head')[0].appendChild(meta); ";
exports.merchantId = "2251103";
exports.orderId = "123456";
exports.currency = "INR";
exports.amount = "1";
exports.language = "EN";
exports.billingNameText = "Charli";
exports.billingAddressText = "Room no 1101";
exports.billingCityText = "Indore";
exports.billingStateText = "MP";
exports.billingZipText = "425001";
exports.billingCountryText = "India";
exports.billingEmailText = "personel@email.com";
exports.billingTelText = "9876543210";
exports.merchantParams = "additional Info";
exports.successPaymentTitle = "Payment Successful!";
exports.successPaymentMessage = "you have successfully completed your payment";
exports.cancelPaymentTitle = "Transaction Canceled!";
exports.cancelPaymentMessage = "your transaction was canceled";
exports.integrationType = "iframe_normal";
exports.controllerType = "orders";
exports.actionType = "ccavenue_request";
exports.deliveryName = "Chaplin";
exports.deliveryAddress = "room no.701 near bus stand";
exports.deliveryCity= "Hyderabad";
exports.deliveryState = "Telangana";
exports.deliveryZip = "452001";
exports.deliveryCountry = "India";
exports.deliveryTel = "9595226054";

// Web variables
exports.createObjectApiEndPoint = "bx_block_ccavenueintegration/check_out_encrypt_data";
exports.checkOutDecryptDataEndPoint = "bx_block_ccavenueintegration/check_out_decrypt_data"
exports.cancelUrlEndPoint = "bx_block_ccavenueintegration/cancel_url"
exports.successUrl = "/Ccavenueintegration2Success"

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.successText = "Congratulations! payment completed successfully";
exports.alertMessageText = "Oops! something went wrong";
exports.catalogueIdText = "Catalogue Id";
exports.orderIdText = "Order Id";
exports.currencyText = "Currency"
exports.catalogueText = "Catalogue";
exports.quantityText = "Quantity";
exports.taxableText = "Taxable";
exports.taxableValueText = "Taxable Value";
exports.noOrdersToDisplay = "No orders to display !"
exports.idText = "Id";
exports.priceText = "Price";
exports.actionText = "Action";
exports.totalFeesText = "Total Fees";
exports.totalItemsText = "Total Items";
exports.totalTaxText = "Total Tax";
exports.customerText = "Customer";
exports.amountText = "amount";
exports.parameterValueText = "Parameter Value";
exports.parameterNameText = "Parameter Name";
exports.compulsoryInformationText = "Compulsory Information";
exports.languageText = "Language";
exports.billingInformation = "Billing Information";
exports.billingAddress = "Billing Address";
exports.billingCity = "Billing City";
exports.billingState = "Billing State";
exports.billingZip = "Billing Zip";
exports.billingNameText = "Billing Name"
exports.billingCountry = "Billing Country";
exports.billingTel = "Billing Tel";
exports.billingEmail = "Billing Email";
exports.shippingInformationText = "Shipping Information";
exports.shippingNameText = "Shipping Name";
exports.shippingAddressText = "Shipping Address";
exports.shippingCityText = "Shipping City";
exports.shippingStateText = "Shipping State";
exports.shippingZipText = "Shipping Zip";
exports.shippingCountryText = "Shipping Country";
exports.shippingTelText = "Shipping Tel";
exports.checkOutButtonText = "Checkout";
exports.viewButtonText = "View";
exports.addButtonText = "Add";
exports.closeButtonText = "Close";
exports.cancelButtonText = "Cancel";
exports.deleteButtonText = "Delete"
exports.viewAllOrdersButtonText = "View all orders";
exports.addOrderItemButtonText = "Add Order Item";
// Customizable Area End
