// Customizable Area Start
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  SvgIcon,
  Slide,
  CardMedia,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  CircularProgress
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Add as AddIcon } from "@material-ui/icons";
import { commonSettingsAdmin as useStyles } from "./styles/CommonSettingsAdmin.web";
import { Availability } from "../../utilities/src/models/Appointment";
import { CommonSettingsManager } from "../../utilities/src/models/CommonSettingsManager";
import { ServiceImage } from "../../utilities/src/models/ServiceImage";
import ImageCard from "../../utilities/src/components/ImageCard.web";
import { ReactComponent as Pencil } from "../assets/pencil.svg";
import Portal from "../../../components/src/Portal";
import AppointmentHours from "./AppointmentHours.web";

export interface Props {
  data: CommonSettingsManager | null;
  isSaving: boolean;
  upsertService: (values: CommonSettingsManager) => void;
  resetValues?: boolean;
  setResetValues?: (value: boolean) => void;
}

const CommonSettingsAdmin: React.FC<Props> = ({
  data,
  isSaving,
  upsertService,
  resetValues,
  setResetValues
}) => {
  const imageUploadInputRef = useRef<HTMLInputElement>(null);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const time_zones = [
    { name: "Indian Standard Time (IST), UTC+5:30" },
    { name: "Greenwich Mean Time (GMT), UTC+0" },
    { name: "British Summer Time (BST), UTC+1" },
    { name: "Gulf Standard Time (GST), UTC+4" },
    { name: "Eastern Time (ET), UTC-5" },
    { name: "Eastern Daylight Time (EDT), UTC-4" },
    { name: "Central Time (CT), UTC-6" },
    { name: "Central Daylight Time (CDT), UTC-5" },
    { name: "Mountain Time (MT), UTC-7" },
    { name: "Mountain Daylight Time (MDT), UTC-6" },
    { name: "Pacific Time (PT), UTC-8" },
    { name: "Pacific Daylight Time (PDT), UTC-7" },
    { name: "Alaska Time (AKT), UTC-9" },
    { name: "Alaska Daylight Time (AKDT), UTC-8" },
    { name: "aii-Aleutian Time (HAST), UTC-10" },
    { name: "Hawaii-Aleutian Standard Time (HADT), UTC-9" },
  ];

  const [initialValues, setInitialValues] = useState<CommonSettingsManager>(
    (data || {}) as CommonSettingsManager
  );

  const validationSchema = Yup.object().shape({
    image: Yup.mixed().required("Image is required."),
    city: Yup.string().required("City is required."),
    country: Yup.string().required("Country is required."),
    location_url: Yup.string()
      .required("Url is required.")
      .url("Needs to be a valid url."),
    pin_code: Yup.string().required("Pin Code is required."),
    time_zone: Yup.string().required("Timezone is required."),
    address_line1: Yup.string().required("Address Line 1 is required."),
    address_line2: Yup.string().required("Address Line 2 is required."),
    availability: Yup.array().of(
      Yup.object().shape({
        day: Yup.string(),
        workingHours: Yup.array().of(
          Yup.object().shape({
            openingTime: Yup.string(),
            closingTime: Yup.string()
          })
        ),
        selected: Yup.bool()
      })
    )
  });

  const formik = useFormik<CommonSettingsManager>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: () => {}
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    getFieldProps,
    setValues,
    setErrors,
    setTouched
  } = formik;

  const addImage = () => {
    imageUploadInputRef.current && imageUploadInputRef.current.click();
  };

  const removeImage = () => {
    setTouched({ ...touched, image: undefined });
    setValues({ ...values, image: null }, true);
  };

  const checkImage = (event: ChangeEvent<HTMLInputElement>) => {
    const fileList: FileList | null = event.currentTarget.files;
    let image = (fileList && (fileList[0] || false)) as File | Blob | null;
    setTouched({ ...touched, image: Boolean(image) }, false);

    const allowedFormats = [
      "image/jpeg",
      "image/jpg",
      "image/gif",
      "image/png",
      "image/tiff",
      "image/raw",
      "image/psd"
    ];
    const fileType = fileList && fileList[0].type;
    const fileExtension =
      fileList &&
      fileList[0].name
        .split(".")
        .pop()
        ?.toLowerCase();
    const validImage =
      (fileType && allowedFormats.includes(fileType)) ||
      (fileExtension && allowedFormats.includes(`image/${fileExtension}`));

    if (!validImage) {
      setErrors({
        ...errors,
        image:
          "Please select a valid image format (JPEG, JPG, GIF, PNG, TIFF, RAW, PSD)"
      });
      clearFileInput();
      return;
    }

    if (
      fileList &&
      fileList.length > 0 &&
      fileList[0].size > 20 * 1024 * 1024
    ) {
      setErrors({
        ...errors,
        image: "Image size should not be greater than 20MB."
      });
      clearFileInput();
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setValues({ ...values, image: reader.result as string, image_raw: image as Blob  }, true);
    };
    reader.readAsDataURL(fileList?.[0] as Blob);
    clearFileInput();
  };

  const clearFileInput = () => {
    if (imageUploadInputRef.current) {
      imageUploadInputRef.current.value = "";
    }
  };

  const isFormChanged =
    JSON.stringify(values) !== JSON.stringify(initialValues);

  const discardChanges = () => {
    formik.handleReset(0);
  };

  const saveChanges = () => {
    if (Object.keys(errors).length) {
      console.log("errors", errors);

      return;
    }

    upsertService(values);
  };

  useEffect(() => {
    if (data) {
      setValues(data);
      setInitialValues(data);
    }
  }, [data, setValues]);

  useEffect(() => {
    if (resetValues) {
      setInitialValues(values);
      setResetValues?.(false);
    }
  }, [resetValues, setResetValues, values]);

  const openModal = () => setIsModalOpened(true);
  const closeModal = () => setIsModalOpened(false);
  const checkAvailabilityForm = (availability: Availability[]) =>
    setValues({ ...values, availability });
  const availabilities = values.availability || [];
  const shouldRenderAppointmentHours = availabilities.some(
    (hourItem: Availability) => hourItem.selected
  );
  const classes = useStyles({ hasImages: Boolean(values.image) });

  const InputField = React.useCallback(
    (label: string, name: string) => {
      return (
        <Box className={classes.inputfield}>
          <Typography className={classes.inputlabel}>{label}</Typography>
          <TextField
            data-testid={name}
            variant="outlined"
            fullWidth
            {...getFieldProps(name)}
            error={Boolean(
              touched &&
                (touched as Record<string, string>)[name] &&
                errors &&
                (errors as Record<string, string>)[name]
            )}
            helperText={
              touched &&
              (touched as Record<string, string>)[name] &&
              errors &&
              (errors as Record<string, string>)[name]
            }
          />
        </Box>
      );
    },
    [classes, errors, getFieldProps, touched]
  );

  return (
    <>
      <Slide direction="down" in={isFormChanged} mountOnEnter unmountOnExit style={{left: 0, right: 0}}>
        <Box className={classes.formActionsWrapper}>
          <Box className={classes.formActions}>
            <CardMedia
              component="img"
              src={`${require("../assets/Builder Studio Store.png")}`}
              className={classes.logo}
            />

            <Box>
              <Button
                variant="text"
                className={classes.discardChangesbutton}
                onClick={discardChanges}
                data-testid="discard_changes"
              >
                Discard changes
              </Button>
              <Button
                variant="contained"
                className={classes.saveChangesbutton}
                onClick={saveChanges}
                data-testid="save_changes"
                disabled={isSaving || Object.keys(errors).length > 0}
              >
                {isSaving && (
                  <CircularProgress
                    size={15}
                    className={classes.isSavingCircular}
                  />
                )}
                Save changes
              </Button>
            </Box>
          </Box>
        </Box>
      </Slide>

      <Box className={classes.container}>
        <Typography className={classes.title}>Common Settings</Typography>

        <form className={classes.wrapper}>
          <Box className={classes.leftSideWrapper}>
            <Box className={classes.card}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography className={classes.cardTitle}>
                  Appointment Hours
                </Typography>
                <IconButton
                  data-testid="appointment_modal_button"
                  onClick={openModal}
                >
                  <SvgIcon className={classes.appoIcon} component={Pencil} />
                </IconButton>
              </Box>
              <Typography className={classes.description}>
                Add the operating hours for your businnes. These would be the
                default appointment timings for all services.
              </Typography>
              {shouldRenderAppointmentHours && (
                <TableContainer>
                  <Table className={classes.tableCells}>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography className={classes.tableHeaderText}>
                            Day
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography className={classes.tableHeaderText}>
                            Time
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {values.availability.map(
                        (availability: Availability, index: number) =>
                          availability.selected && (
                            <TableRow
                              key={index}
                              className={classes.tableBodyRowCells}
                            >
                              <TableCell>
                                <Typography className={classes.tableCellText}>
                                  {availability.day}
                                </Typography>
                              </TableCell>

                              <TableCell>
                                <Box className={classes.workingHoursWrapper}>
                                  <Box className={classes.workingHours}>
                                    {availability.workingHours.map(
                                      (
                                        { openingTime, closingTime },
                                        subIndex
                                      ) => (
                                        <Typography
                                          key={subIndex}
                                          className={classes.tableCellText}
                                        >
                                          {openingTime} - {closingTime}
                                        </Typography>
                                      )
                                    )}
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>

            <Box className={[classes.card, classes.inputsWrapper].join(" ")}>
              <Typography className={classes.cardTitle}>
                Location details
              </Typography>
              <Box>
                <Typography className={classes.inputlabel}>URL *</Typography>
                <TextField
                  data-testid="location_url"
                  variant="outlined"
                  fullWidth
                  {...getFieldProps("location_url")}
                  error={Boolean(
                    touched &&
                      touched.location_url &&
                      errors &&
                      errors.location_url
                  )}
                  helperText={
                    touched &&
                    touched.location_url &&
                    errors &&
                    errors.location_url
                  }
                />
              </Box>

              <Box>
                <Typography className={classes.inputlabel}>Image *</Typography>
                <input
                  data-testid="image_upload"
                  ref={imageUploadInputRef}
                  name="image"
                  className={classes.hidden}
                  type="file"
                  accept="image/*"
                  onChange={checkImage}
                  onBlur={event => {
                    handleBlur(event);
                    checkImage(event);
                  }}
                />
                <Box className={classes.imagesWrapper}>
                  {values.image && (
                      <Box>
                        <img src={(values.image as ServiceImage).url || values.image as string} className={classes.image}/> 
                        <Button
                          data-testid="remove_image"
                          variant="text"
                          onClick={() => removeImage()}
                          className={classes.removeButton}
                        >
                          Remove
                        </Button>
                      </Box>
                    )}
                  {!values.image && (
                    <Box
                      data-testid="image_upload_container"
                      className={classes.imageUpload}
                      onClick={addImage}
                    >
                      <AddIcon className={classes.addIcon} />
                      <Typography className={classes.addImageText}>
                        Add image
                      </Typography>
                      <Typography className={classes.fileSize}>
                        Max 20MB
                      </Typography>
                    </Box>
                  )}
                </Box>

                {touched && touched.image && errors && errors.image && (
                  <Typography className={classes.validationError}>
                    {errors.image}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box className={[classes.card, classes.inputsWrapper].join(" ")}>
              <Typography className={classes.cardTitle}>
                Address details
              </Typography>

              {InputField("Address Line 1 *", "address_line1")}
              {InputField("Address Line 2 *", "address_line2")}

              <Box className={classes.AddressLineInputsWrapper}>
                {InputField("City *", "city")}
                {InputField("State", "state")}
              </Box>

              <Box className={classes.AddressLineInputsWrapper}>
                {InputField("Country *", "country")}
                {InputField("Pincode *", "pin_code")}
              </Box>
            </Box>
          </Box>

          <Box className={classes.RightSideWrapper}>
            <Box className={classes.card}>
              <Typography className={classes.cardTitle}>Timezone</Typography>
              <Typography className={classes.inputlabel}>Time *</Typography>
              <Autocomplete
                options={time_zones}
                getOptionLabel={time_zone => time_zone.name}
                value={
                  time_zones.find(
                    time_zone => time_zone.name === values.time_zone
                  ) || null
                }
                onChange={(_event, value) =>
                  setValues({
                    ...values,
                    time_zone:
                      (typeof value === "string" ? value : value?.name) || ""
                  })
                }
                onBlur={handleBlur("time_zone")}
                renderInput={params => (
                  <TextField
                    {...params}
                    data-testid="time_zone"
                    variant="outlined"
                    placeholder="Select"
                    fullWidth
                    error={Boolean(
                      touched && touched.time_zone && errors && errors.time_zone
                    )}
                    helperText={
                      touched && touched.time_zone && errors && errors.time_zone
                    }
                  />
                )}
              />
              <Typography className={classes.helperText}>
                You can select your timezone from here.
              </Typography>
            </Box>
          </Box>

          {isModalOpened && (
            <Portal>
              <AppointmentHours
                availability={values.availability}
                checkAvailabilityForm={checkAvailabilityForm}
                closeModal={closeModal}
              />
            </Portal>
          )}
        </form>
      </Box>
    </>
  );
};

export default CommonSettingsAdmin;
// Customizable Area End