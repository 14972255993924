// Customizable Area Start
import React, { FC, useEffect } from "react"
import { Field, FieldAttributes } from 'formik';

const CurrencyInput: FC<FieldAttributes<{}>> = (props) => {
    const [currency, setCurrency] = React.useState("₹");

    useEffect(() => {
        const localStorageListener = () => {
            const storeDetailsString = localStorage.getItem("storeDetails");
            if (storeDetailsString) {
                setCurrency(JSON.parse(storeDetailsString).currency_symbol || "₹")
            }
        };
        window.addEventListener("store_details", localStorageListener);

        const storeDetailsString = localStorage.getItem("storeDetails");
        if (storeDetailsString) {
            setCurrency(JSON.parse(storeDetailsString).currency_symbol || "₹")
        }

        return () => {
            window.removeEventListener("store_details", localStorageListener);
        }
    }, []);

    return <Field
        {...props}
        placeholder={currency}

    />
}

export default CurrencyInput;
// Customizable Area End