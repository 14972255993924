import moment from "moment";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Service } from "../../utilities/src/models/Service";
import { parseCatalogue } from "../../utilities/src/helpers/utils";
// Customizable Area Start
export const configJSONUrl = require("../../../framework/src/config");

export interface StripesuccessRes {
  authentication_url: string,
  status: string,
}
interface SubmitStripeData {
  name: string;
  cardNumber: number | string,
  cardCVV: number | string,
  cardDate: number | string,
}
// Customizable Area End


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
  personalDetailsData?: any
  activeStep?: any
  bookingData?: any
  service?: any
  paymentOption?: any
  // Customizable Area Start
  // Customizable Area End
}

export interface Values {
  // Customizable Area Start
  name: string;
  email: string;
  phoneNumber: string;
  comments: string;
  // Customizable Area End
}

export interface Address {
  country: string;
  addr1: string;
  addr2?: string;
  houseNo?: string;
  zip: string;
  city: string;
  stateName?: string;
}

export interface Slot {
  id: number;
  slot_start_time: string;
  slot_end_time: string;
  is_available: boolean;
}
export interface S {
  // Customizable Area Start
  available_date: any;
  start_time: any;
  end_time: any;
  id: any;
  token: string;
  appointmentsList: any;
  isRefreshing: boolean;
  selectedDate: any;
  activeStep: any;
  serviceId: any;
  service: Service | null;
  personalDetailsData: any;
  selectedSlot: any,
  name: string;
  email: string;
  phoneNumber: string;
  comments: string;
  errors: Partial<Values>;
  addressError: Partial<Address>;
  isDisabled: boolean
  paymentOption: string;
  country: string;
  addr1: string;
  addr2: string;
  houseNo: string;
  zip: string;
  city: string;
  stateName: string;
  appointmentsAvaibilityId: string;
  bookingData: any;
  openDialog: boolean;
  screenSize: number;
  expanded: boolean;
  characterCount: number;
  loading: boolean;
  paymentPreference: string;
  slotBookingErrorMessage: string;
  timeZone: string;
  stripeModalOpen: boolean;
  cardNumber: number | string,
  cvv: number | string,
  expiry: string | number,
  cardName: string,
  isOpenStripeModal: boolean,
  appointmentData: any,
  stripeSuccessData: StripesuccessRes,
  stripeState: boolean
  ccavenueModalOpen: boolean
  ccavenueModalShowRespons: {
    enc_resp: string;
    access_code: string;
  }
  timeZoneOffset: number;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AppointmentmanagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addAppointmentApiCallId: any;
  getAppointmentsListApiCallId: any;
  deleteAllAppointmentsApiCallId: any;
  getCatalogueApiCallId: any;
  stripePaymentApiCallId: string;
  ccAvenueintegrationApiCallId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    let endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);
    this.state = {
      // Customizable Area Start
      timeZoneOffset: 0,
      id: 0,
      start_time: new Date(),
      end_time: endTime,
      available_date: moment(new Date()).format("DD/MM/YY"),
      appointmentsList: [],
      token: "",
      isRefreshing: false,
      selectedDate: new Date(),
      activeStep: 0,
      serviceId: 0,
      service: null,
      personalDetailsData: null,
      selectedSlot: null,
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
      errors: {},
      isDisabled: true,
      paymentOption: "",
      country: "",
      addr1: "",
      addr2: "",
      houseNo: "",
      zip: "",
      city: "",
      stateName: "",
      addressError: {},
      appointmentsAvaibilityId: "",
      bookingData: null,
      openDialog: false,
      screenSize: window.innerWidth,
      expanded: false,
      characterCount: 0,
      loading: true,
      paymentPreference: "",
      slotBookingErrorMessage: "",
      timeZone: "",
      stripeModalOpen: false,
      cardNumber: "",
      cvv: "",
      expiry: "",
      cardName: "",
      isOpenStripeModal: false,
      appointmentData: null,
      stripeSuccessData: {} as StripesuccessRes,
      stripeState: false,
      ccavenueModalOpen: false,
      ccavenueModalShowRespons: {
        enc_resp: "",
        access_code: ""
      }
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addAppointmentApiCallId != null &&
      this.addAppointmentApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.getAppointmentList(this.state.token);
        if(this.state.paymentOption == "pay_in_person") {
          this.setState({ bookingData: responseJson.data })
        }else{
          this.setState({ appointmentData: responseJson.data, isOpenStripeModal: true, })
          this.postCcavenuePayment(responseJson.data)
          // this.setState({ bookingData: responseJson.data })
        }
      } else {
        this.setState({ slotBookingErrorMessage: responseJson.errors[0] })
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);

      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getAppointmentList(token);
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAppointmentsListApiCallId != null &&
      this.getAppointmentsListApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({
          appointmentsAvaibilityId: responseJson?.data?.id,
          appointmentsList: responseJson?.data?.attributes?.time_slots,
          timeZone: responseJson?.meta?.time_zone_short,
          timeZoneOffset: this.textToUtcOffsetMinutes(responseJson?.meta?.time_zone),
          loading: false
        });
      } else {
        this.setState({ appointmentsList: [], loading: false, timeZone: "" });

        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCatalogueApiCallId != null &&
      this.getCatalogueApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.handleGetCatalgoueResponse(responseJson, message);
      } else {
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.stripePaymentApiCallId != null &&
      this.stripePaymentApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.handlePostStripeResponse(responseJson);
      } else {
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
        this.handleErrorResponse(responseJson.errors);
      }
    }
   
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.ccAvenueintegrationApiCallId != null &&
      this.ccAvenueintegrationApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        this.handleCcrevenueIntegrationResponse(responseJson,message);
      } else {
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start

  handleErrorResponse = (errorResponse: any) => {
    alert(errorResponse[0].stripe)
  }
  textToUtcOffsetMinutes(text: string) {
    const offsetRegex = /UTC([+-]\d+(:\d+)?)/;
    const offsetMatch = (text || "").match(offsetRegex);
    if (!offsetMatch) {
      return 0;
    }
    const offsetStr = offsetMatch[1];

    const offsetParts = offsetStr.split(':');
    const hours = parseInt(offsetParts[0]);
    const minutes = parseInt(offsetParts[1] || "0");

    const totalMinutes = hours * 60 + minutes;
    return totalMinutes
  }

  addAppointment = () => {
    const {
      serviceId,
      personalDetailsData,
      country,
      city,
      addr1,
      selectedSlot,
      paymentOption,
      selectedDate
    } = this.state;
    if (personalDetailsData && serviceId && country && addr1 && city && selectedDate instanceof Date) {
      const month = selectedDate.getMonth() + 1;
      const date = selectedDate.getDate();
      const year = selectedDate.getFullYear();
      const formattedDate = `${date < 10 ? "0" : ""}${date}-${month < 10 ? "0" : ""}${month}-${year}`;
      let paymentMethod;

      if (paymentOption === "pay_in_person") {
        paymentMethod = "pay_later";
      } else if (paymentOption === "pay_online") {
        paymentMethod = "pay_now";
      } else {
        paymentMethod = "";
      }

      const header = {
        "Content-Type": configJSON.appointmentApiContentType,
        token: this.state.token,
      };

      const attrs = {
        appointment: {
          time_slot_id: selectedSlot.id,
          catalogue_id: serviceId,
          appointment_date: formattedDate,
          payment_mode: paymentMethod,
          personal_detail_attributes: {
            full_name: this.state.name,
            full_phone_number: this.state.phoneNumber,
            email: this.state.email,
            comment: this.state.comments
          },
          billing_address_attributes: {
            country: this.state.country,
            city: this.state.city,
            state: this.state.stateName,
            address_line_2: this.state.addr2,
            address_line_1: this.state.addr1,
            zip_code: this.state.zip,
            flat_number: this.state.houseNo
          }
        }
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addAppointmentApiCallId = requestMessage.messageId;

      const httpBody = {
        ...attrs,
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.addAppointmentAPiMethod
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addappointmentAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  postCcavenuePayment = (response: any) => {
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      token: this.state.token,
    };

    const attrs = {
      data:
      {
        order_id: response.attributes.order_id,
        amount: response.attributes.total,
        currency: "INR",
        redirect_url: `${configJSONUrl.baseURL}/bx_block_ccavenue_integration/check_out_decrypt_data`,
        cancel_url: `${configJSONUrl.baseURL}/bx_block_ccavenue_integratio/check_out_decrypt_data`,
        language: "EN",
        billing_name: response.attributes.full_name,
        billing_address: response.attributes.address_line_1 + response.attributes.address_line_2,
        billing_city: response.attributes.city,
        billing_state: response.attributes.state,
        billing_zip: response.attributes.zip_code,
        billing_country: response.attributes.country,
        billing_tel: response.attributes.full_phone_number,
        billing_email: response.attributes.email,
        customer_identifier: "",
        promo_code: "",
        integration_type: "iframe_normal"
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ccAvenueintegrationApiCallId = requestMessage.messageId;

    const httpBody = {
      ...attrs,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAppointmentAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dataEncryptionEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  isStringNullOrBlank(str: string | null | undefined) {
    if (str === null || str === undefined) {
      return true;
    }
    if (typeof str !== "string") {
      return true;
    }
    return str.trim() === "";
  }

  getAppointmentList = (token: any) => {
    if (this.state.selectedDate) {
      const month = this.state.selectedDate.getMonth() + 1;
      const date = this.state.selectedDate.getDate();
      const year = this.state.selectedDate.getFullYear();
      const formattedDate = `${date < 10 ? "0" : ""}${date}-${month < 10 ? "0" : ""}${month}-${year}`;

      const header = {
        "Content-Type": configJSON.appointmentApiContentType,
        token: token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getAppointmentsListApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.appointmentAPiEndPoint}?catalogue_id=${this.state.serviceId}&availability_date=${formattedDate}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAppointmentListAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  postStripePaymentApi = () => {
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      token: this.state.token,
    };

    const Month = this.state?.expiry?.toString().split("/");
    const catalogueId = this.props.navigation.getParam("id");
    const attrs = {
      payment: {
        catalogue_id: catalogueId,
        type: "card",
        card_details: {
          number: this.state.cardNumber,
          exp_month: Month[0],
          exp_year: "20" + Month[1],
          cvc: this.state.cvv
        },
        billing_details: {
          name: this.state.name,
          email: this.state.email,
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.stripePaymentApiCallId = requestMessage.messageId;

    const httpBody = {
      ...attrs,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.stripeAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postStripePaymentApiEndPoints
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCatalgoue = () => {
    const catalogueId = this.props.navigation.getParam("id");
    if (catalogueId) {
      this.setState({ serviceId: catalogueId });
      const header = {
        "Content-Type": configJSON.appointmentApiContentType,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getCatalogueApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.catalogueAPIEndPoint}/${catalogueId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAppointmentListAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  handleGetCatalgoueResponse = (responseJson: any, message: Message) => {
    const response = responseJson.data;
    this.setState({
      service: parseCatalogue(response),
    });
  };

  handlePostStripeResponse = (responseJson: { meta: StripesuccessRes }) => {
    this.addAppointment();
    this.setState({
      stripeSuccessData: responseJson.meta,
      isOpenStripeModal: false
    }, () => {
      this.setState({ stripeState: true, }, () => {
        setTimeout(() => {
          this.setState({ stripeState: false, bookingData: this.state.appointmentData })
        }, 5000)
      });
    })
  }
  handleCcrevenueIntegrationResponse = (responseJson: any, message: Message) => {
    const response = responseJson;
    this.setState({
      ccavenueModalShowRespons: response,
      ccavenueModalOpen: true
    });
  }

  navigateToAppointments = () => {
    this.props.navigation.navigate("Appointments");
  };

  handleIncrement = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  handelAvaibilityTimeSlot = (slot: any) => {
    this.setState({
      selectedSlot: slot,
      isDisabled: false,
    });
  }

  handelProceedFromDateTime = () => {
    const { selectedDate, service, selectedSlot, timeZone } = this.state;
    if (selectedSlot) {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep + 1,
        timeZone: timeZone,
        personalDetailsData: {
          selectedDate,
          selectedSlot,
          service,
          timeZone
        }
      }));
    } else {
      this.showAlert("Error", "No slot selected");
    }
  };

  handleProceedToPersonalDetails = () => {
    const { personalDetailsData, email, name, phoneNumber } = this.state;

    if (!name || !email || !phoneNumber) {
      this.setState({
        errors: {
          name: !name ? "Name is required" : "",
          email: !email ? "Email is required" : "",
          phoneNumber: !phoneNumber ? "Mobile number is required" : ""
        }
      });
      return;
    }
    let paymentOption = "pay_in_person";
    if (this.state.service && ["pay_online_or_in_person", "pay_online"].includes(this.state.service.paymentPreference)) {
      paymentOption = "pay_online";
    }
    if (personalDetailsData) {
      this.setState({
        paymentPreference: this.state.service ? this.state.service.paymentPreference : "",
        paymentOption,
        activeStep: this.state.activeStep + 1,
        errors: {}
      });
    } else {
      this.showAlert("Error", "Something went wrong");
    }
  }

  handleProceedToPayment = () => {
    const { personalDetailsData } = this.state;
    if (personalDetailsData) {
      this.setState({ serviceId: this.props.navigation.getParam("id"), openDialog: false })
      if(this.state.paymentOption == "pay_in_person"){
        this.addAppointment();
      }
      else {
        this.setState({isOpenStripeModal: true});
      }
    }
    else {
      this.showAlert("Error", "Something went wrong");
    }
  }

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  btnBackProps = () => {
    this.props.navigation.goBack();
  }

  handlePaymentOptionChange = (event: { target: { value: any; }; }) => {
    this.setState({
      paymentOption: event.target.value
    });
  };


  handleCloseDialog = () => {
    this.setState({
      openDialog: false,
    });
  };

  closeAppointmentDialog = () => {
    if (this.state.slotBookingErrorMessage === "Catalogue is currently inactive.") {
      this.props.navigation.navigate("Services")
    }
    this.setState({
      activeStep: 0,
      slotBookingErrorMessage: ""
    })
  }

  handleCardData = (data: SubmitStripeData) => {
    this.setState({
      cardNumber: data.cardNumber,
      cvv: data.cardCVV,
      expiry: data.cardDate,
      cardName: data.name
    }, () => {
      this.postStripePaymentApi();
    })
  };
  // Customizable Area Start
  onCloseModal = () => {
    this.setState({
      isOpenStripeModal: false,
      ccavenueModalOpen: false
    })
  }
  // Customizable Area End
}
