// Customizable Area Start
import React from "react";
import DashboardAdminController, {
  Props,
} from "./DashboardAdminController.web";
import DashboardAdmin from "./DashboardAdmin.web";

export default class DashboardAdminWrapper extends DashboardAdminController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <DashboardAdmin brand={this.state.brand} isSaved={this.state.isSaved} upsertBrand={this.upsertBrand} />
    );
  }
}
// Customizable Area End
