// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import BlockHelpers from "../../utilities/src/BlockHelpers";
import { CommonSettingsManager } from "../../utilities/src/models/CommonSettingsManager";
import {
  Appointment,
  Availability
} from "../../utilities/src/models/Appointment";
import { formatShopAvailability } from "../../utilities/src/helpers/utils";
import {
  generateRequestMessage,
  isTokenExpired,
  clearStorageData,
} from "../../ss-cms-common-components/src/Utilities/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface State {
  data: CommonSettingsManager | null;
  availabilityErrors: string[];
  isSaving: boolean;
  resetValues: boolean;
}

interface StateS {
  id: any;
}

export default class CommonSettingsAdminController extends BlockComponent<
  Props,
  State,
  StateS
> {
  getCommonSettingsAdminApiCallId: string;
  upsertCommonSettingsAdminApiCallId: string;
  getCommonSettingsAdminAppointmentHoursApiCallId: string;
  upsertCommonSettingsAdminAppointmentHoursApiCallId: string;
  dayIds: Array<string> = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];
  tempAvailability: Array<Availability> = [];

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      data: null,
      availabilityErrors: [""],
      isSaving: false,
      resetValues: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getCommonAdminSetting();
  }

  receive = async (_from: string, message: Message) => {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
    }
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        case this.upsertCommonSettingsAdminApiCallId:
          if(responseJson.data)
            this.saveAvaibility(responseJson.data.attributes.opening_hours);
          break;

        case this.upsertCommonSettingsAdminAppointmentHoursApiCallId:
          this.setState({ isSaving: false, resetValues: true });
          break;

        case this.getCommonSettingsAdminAppointmentHoursApiCallId:
          const availability = responseJson.data;
          this.setState({
            data: {
              ...(this.state.data as CommonSettingsManager),
              availability: this.formatAvailability(
                availability
              ) as Availability[]
            }
          });
          break;

        case this.getCommonSettingsAdminApiCallId:
          const item = responseJson.data.attributes;
          const data = {
            ...item,
            availability: this.formatAvailability(item.opening_hours)
          };

          this.setState({ data });
          this.getAvailableDays();
          break;
        default:
          break;
      }
    }
  };

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  upsertService = (values: CommonSettingsManager) => {
    const header = {};
    const body = new FormData();

    this.setState({ isSaving: true });

    body.append("[data][attributes][city]", values.city);
    body.append("[data][attributes][state]", values.state);
    body.append("[data][attributes][country]", values.country);
    body.append("[data][attributes][pin_code]", values.pin_code);
    body.append("[data][attributes][time_zone]", values.time_zone);
    body.append("[data][attributes][location_url]", values.location_url);
    body.append("[data][attributes][address_line1]", values.address_line1);
    body.append("[data][attributes][address_line2]", values.address_line2);
    if(typeof values.image === "string" && values.image_raw)
      body.append("[data][attributes][image]", values.image_raw);

    this.tempAvailability = values.availability;

    this.upsertCommonSettingsAdminApiCallId = BlockHelpers.callApi({
      method: configJSON.postMethod,
      endPoint: configJSON.upsertCommonSettingsAdminApi,
      header,
      body
    });
  };

  getAvailableDays = () => {
    this.getCommonSettingsAdminAppointmentHoursApiCallId = BlockHelpers.callApi(
      {
        method: configJSON.getMethod,
        endPoint: configJSON.upsertCommonSettingsAdminAllAppointmentHoursApi
      }
    );
  };

  saveAvaibility = (appointment: Appointment[]) => {
    const patchData: Appointment[] = [];
    const createData: Appointment[] = [];
    this.dayIds.forEach(dateDay => {
      const current = appointment.find(appo => appo.week_day === dateDay);
      const next = this.tempAvailability.find(avai => avai.day === dateDay);
      const data = {
        ...current,
        id: next?.id,
        week_day: dateDay,
        start_time: ((next?.workingHours || [])[0] || {}).openingTime,
        end_time: ((next?.workingHours || [])[0] || {}).closingTime,
        selected: next?.selected
      } as Appointment;

      if (data.id) {
        patchData.push(data);
      } else if (data.selected) {
        createData.push(data);
      }
    });

    if (patchData.length) {
      this.callSaveAvaibility(patchData);
    }
    if (createData.length) {
      this.callSaveAvaibility(createData, false);
    }
  };

  callSaveAvaibility = (attributes: Appointment[], update: boolean = true) => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson
    };

    const body = { data: { attributes } };
    this.upsertCommonSettingsAdminAppointmentHoursApiCallId = BlockHelpers.callApi(
      {
        method: update ? configJSON.putMethod : configJSON.postMethod,
        endPoint: configJSON.upsertCommonSettingsAdminAppointmentHoursApi,
        header,
        body: JSON.stringify(body)
      }
    );
  };

  formatAvailability = (openingHours: Appointment[]) => {
    if (!openingHours?.length) return [];

    return this.dayIds.map(dateDay => {
      const openH = openingHours.find(open => open.week_day === dateDay);
      const workingHours = formatShopAvailability([openH]);
      return {
        id: openH?.id,
        day: openH?.week_day || dateDay,
        workingHours: [
          {
            openingTime: workingHours[0].openingTime,
            closingTime: workingHours[0].closingTime
          }
        ],
        selected: openH?.selected
      };
    });
  };

  resetAvailabilityErrors = () => {
    this.setState({ availabilityErrors: [] });
  };

  getCommonAdminSetting() {
    this.getCommonSettingsAdminApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.upsertCommonSettingsAdminApi
    });
  }

  setResetValues = (value: boolean) => {
    this.setState({ resetValues: value });
  };
}
// Customizable Area End
