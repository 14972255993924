import React from 'react';
// Customizable Area Start
import { createStyles, withStyles } from '@material-ui/core/styles';
import StripePaymentsController from './StripePaymentsController.web';
import Card from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { Box, Fade, Modal } from "@material-ui/core";
// Customizable Area End

class StripePayments extends StripePaymentsController {
    render() {
        // Customizable Area Start
        const style = this.props.classes;
        const { name, number, expiry, cvc, focused, issuer } = this.state;
        return (
            <>
                <Modal data-test-id="closeModalTestID" open={this.props.stripeModalOpen} className={style.modal}>
                    <Fade in={this.props.stripeModalOpen}>
                        <Box className={style.paper}>
                            <div key="Payment">
                                <div className={style.AppPayment}>
                                    <h1 className={style.headerTag}>Enter your Card details</h1>
                                    <Card
                                        data-test-id='cardCallBackID'
                                        number={number}
                                        name={name}
                                        expiry={expiry}
                                        cvc={cvc}
                                        focused={focused}
                                        callback={this.handleCallback}
                                    />
                                    <form ref={(c) => (this.form = c)} onSubmit={this.handleSubmitForm}>
                                        <div className={`${style.cardAligment} ${style.cardPadding}`}>
                                            <div className={style.form_group}>
                                                <small className={style.cardHeading}>Name on card:</small>

                                                <input
                                                    data-test-id='handleInputFocusBackID'
                                                    type="text"
                                                    name="name"
                                                    className={style.formControl}
                                                    placeholder="Name"
                                                    pattern="[a-z A-Z-]+"
                                                    required
                                                    onChange={this.handleName}
                                                    onFocus={this.handleInputFocus}
                                                />
                                            </div>
                                            <div className={style.form_group}>
                                                <small className={style.cardHeading}>Card Number:</small>

                                                <input
                                                    data-test-id="handleInputFocusNumberID"
                                                    type="tel"
                                                    name="number"
                                                    className={style.formControl}
                                                    placeholder="Card Number"
                                                    pattern="[\d| ]{16,22}"
                                                    maxLength={19}
                                                    required
                                                    onChange={this.handleNumber}
                                                    onFocus={this.handleInputFocus}
                                                />
                                            </div>
                                        </div>

                                        <div className={style.cardAligment}>
                                            <div className={style.form_group}>
                                                <small className={style.cardHeading}>Expiration Date:</small>

                                                <input
                                                    data-test-id="handleInputExpiryID"
                                                    type="tel"
                                                    name="expiry"
                                                    className={style.formControl}
                                                    placeholder="Valid Thru"
                                                    pattern="\d\d/\d\d"
                                                    required
                                                    onChange={this.handleExp}
                                                    onFocus={this.handleInputFocus}
                                                />
                                            </div>
                                            <div className={style.form_group}>
                                                <small className={style.cardHeading}>CVC:</small>

                                                <input
                                                    data-test-id="handleInputCVCID"
                                                    type="tel"
                                                    name="cvc"
                                                    className={style.formControl}
                                                    placeholder="CVC"
                                                    pattern="\d{3}"
                                                    required
                                                    onChange={this.handleCvv}
                                                    onFocus={this.handleInputFocus}
                                                />
                                            </div>
                                        </div>
                                        <input type="hidden" name="issuer" value={issuer} />
                                        <div className={style.buttonBox}>
                                            <button>Submit</button>
                                            <button onClick={this.handleCloseModal}>Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Box>
                    </Fade>
                </Modal>
            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
const useStyle = () =>
    createStyles({
        cardHeading: {
            fontSize: "15px",
            fontWeight: 400,
            fontFamily: "Rubik",
        },
        headerTag: {
            fontSize: "27px",
            fontWeight: 600,
            fontFamily: "Rubik",
        },
        form_group: {
            display: "grid",
            marginRight: "25px"
        },
        cardAligment: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "start",
            "@media(max-width: 380px)": {
                display: "block",
            },
        },
        cardPadding: {
            paddingTop: "20px",
        },
        AppPayment: {
            margin: "0",
            webkitFontSmoothing: "antialiased",
            mozOsxFontSmoothing: "grayscale",
            fontFamily: "Montserrat, sans-serif",
            textAlign: "center",
        },
        buttonBox: {
            "& button": {
                transitionDuration: "0.7s",
                marginTop: "2%",
                height: "2.5rem",
                width: "6rem",
                color: "white",
                backgroundColor: " #7589eb",
                borderRadius: "7rem",
                border: "0",
                margin: "5px 5px",
                fontFamily: "Rubik",
            },
            "& button:hover": {
                backgroundColor: "#ccd5fc",
                color: "black",
                borderRadius: "7em",
            }
        },
        formControl: {
            marginTop: "1%",
            marginRight: "1%",
            fontFamily: "Montserrat, sans-serif",
            padding: "3.5%",
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: "#fff",
            width: "600px"
        }

    });
export default withStyles(useStyle, { withTheme: true })(StripePayments);
export { StripePayments }
// Customizable Area End