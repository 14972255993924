// Customizable Area Start
import { Theme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  
      formActions: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#3C3E49",
        padding: theme.spacing(1.25, 3)
      },
      formActionsWrapper: {
        top: 0,
        zIndex: 1,
        width: "100%",
        position: "fixed",   
      },
      logo: {
        width: "auto",
        objectFit: "none",      
      },
      saveChangesbutton: {
        fontSize: "16px",
        backgroundColor: "#00D659",
        lineHeight: "24px",
        fontWeight: 500,
        padding: theme.spacing(1.5, 5),
        textTransform: "none",
        color: "#3C3E49",
  
      },
      discardChangesbutton: {
        fontSize: "14px",
        textTransform: "none",
        lineHeight: "20px",
        color: "#FFFFFF",
        padding: theme.spacing(1.5, 3),
        textDecoration: "underline",
        
      },
   
      loadingResultsText: {
       textAlign: "center",
        fontSize: "18px",
        fontWeight: 500,
        color: "#3C3E49",
        lineHeight: "24px",  
        whiteSpace: "pre",
      },
      noServicesCard: {
        alignItems: "center",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        position: "absolute",
        padding: theme.spacing(3),
        width: "280px",
        background: "#FFFFFF",
        flexDirection: "column",
        justifyContent: "center",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      },
      loadingResults: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      },
      pleaseRefineYourSearch: {
        fontSize: "14px",
        lineHeight: "24px",
        color: "#83889E",
        marginTop: theme.spacing(1),
      },
      notFoundImage: {
        width: "103px",
      },
      noResultsFound: {
        fontSize: "18px",
        fontWeight: 500,
        lineHeight: "24px",
        color: "#000000",
        marginTop: theme.spacing(3),
      },
      noOrderCard: {
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        borderRadius: "8px",
        background: "#FFFFFF",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        top: "45%",
        left: "50%",
        alignItems: "center",
        justifyContent: "center",
        padding: 24
      },
      noOrderCardContent: {
        display: "flex",
        flexDirection: "column",        
        width: "280px",
        height: "180px",
        alignItems: "center",
        justifyContent: "center",
      },
      loaderContainer: {
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        padding: 24,
        gap: 10
      },
      




}));




// Customizable Area End