import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";


// Customizable Area Start
import React from "react";
export interface PaymentInfo {
  orderId: number;
  orderNumber: string;
  encryptRequest: string;
  accessCode: string;
}

export enum CloseReason {
  Success,
  Cancel,
  Failure,
  PopupBlocker
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  ccavenueModalOpen: boolean;
  ccavenueModalShowRespons: {
    enc_resp: string;
    access_code: string;
  }
  classes: Record<string, string>;
  onClose: (reason: CloseReason) => void;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  setOpenModal: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Ccavenueintegration2SuccessController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  iframeRef: React.RefObject<HTMLIFrameElement>;
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.iframeRef = React.createRef();
    // Customizable Area End    
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      setOpenModal: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    window.addEventListener("message", this.onReceiveMessage);
  }

  async componentWillUnmount() {
    super.componentWillUnmount();
    window.removeEventListener("message", this.onReceiveMessage);

  }


  onReceiveMessage = (event: MessageEvent<string>) => {
      console.log(event,"event")
      this.props.onClose(CloseReason.Success);
  };

  // Customizable Area End



  // Customizable Area Start
  handleOpenModal = () => {
    this.setState({ setOpenModal: true });
  };

  handleCloseModal = () => {
    this.setState({ setOpenModal: false });
  };
  // Customizable Area End
}
