// Customizable Area Start
import React from "react";
import {
  Typography,  Button, Container, Box, Grid,
} from "@material-ui/core";

interface Props {

}


const NoCategory = ({
  handleAddCategory,

}: {
  
  handleAddCategory: any;
 
}) => {


  return (
    <>
       <Container maxWidth="xl" style={webStyle.containerMain}>
        <Grid container>
        <Grid item xl={12} lg={12} md={12} xs={12}>
        <div style={webStyle.mainWrapper}>
          <Box>
            <Box sx={webStyle.colWrapper}>
              <Typography
                component="h5"
                variant="h5" style={webStyle.TitleFirst}
              >
                You have no categories
              </Typography>
            </Box>
          </Box>
          <div>
            <div style={webStyle.middleTextStyle} >
              <Typography variant="body1" style={webStyle.TitleTextParg} >
                Start adding your categories so that your products can be
                better organized
              </Typography>
            </div>
          </div>
          <div>
            <div  
              style={{ padding: "10px 10px 10px 10px", textAlign: "center" }}
            >
              <Button type="submit"
                variant="contained"
                color="primary"
                style={webStyle.TitleBtnAdd}
                onClick={()=>{handleAddCategory()}}
                data-test-id="btnPress"
              >
                Add Category
              </Button>
            </div>
          </div>
        </div>
        </Grid>
        </Grid>
        </Container>
    </>
  );
};

export default NoCategory;

const webStyle = {
    containerMain:{
      backgroundColor: '#FAFAFA',
    },
    mainWrapper: {
      width:'100%',
      maxWidth: "684px",
      margin: "50 auto",
      backgroundColor: '#FFFFFF',
      padding:"70px",
    },
    colWrapper: {
      padding: "20px 10px 10px 10px",
      textAlign: "center"
    },
    TitleBtnAdd: {
      backgroundColor: "#00D659",
      color: "#3C3E49",
      width: "204px",
      height: "48px",
      padding: "12px 40px",
      borderRadius: "3px",
      fontFamily: "Rubik",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: "0em",
      textAlign: "center" as "center",
      boxShadow: "none",
      textTransform: "none" as "none",
    },
    TitleTextParg: {
      fontWeight: 400,
      lineHeight: "22px",
      fontSize: "14px",
      fontFamily: "Rubik",
      color: "#676B7E",
      margin: "40px auto",
      letterSpacing: "0em" as "0em",
      textAlign: "center" as "center",
    },
    TitleFirst: {
      fontWeight: 500,
      lineHeight: "32px",
      fontSize: "28px",
      fontFamily: "Rubik",
      color: "#3C3E49",
      letterSpacing: "-0.02em",
      textAlign: "center" as "center",
    },
    middleTextStyle:{
      textAlign: "center" as "center",
      maxWidth: "395px",
      height: "127px",
      margin: "0 auto",
      width:"100%",
    }
    // Rest of your styles...
  };
  
// Customizable Area End



