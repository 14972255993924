// Customizable Area Start
import { Theme, makeStyles } from "@material-ui/core";

export const Dashboard = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(12.5),
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(5),
    },
  },
  banner: {
    height: "480px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: "125px",
    },
  },
  sectionWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(0, 10),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 2),
    },
  },
  sectionTitleWrapper: {
    [theme.breakpoints.down("sm")]: {
      alignSelf: "flex-start",
    },
  },
  sectionTitle: {
    fontSize: "32px",
    fontWeight: 500,
    lineHeight: "36px",
    letterSpacing: "3.2px",
    color: "#364F6B",
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "28px",
      letterSpacing: "-0.4px",
      paddingBottom: theme.spacing(1),
    },
  },
  titleDivider: {
    width: "60%",
    height: "3px",
    backgroundColor: "#3FC1CB",
    margin: "auto",
    opacity: 1,
    [theme.breakpoints.down("sm")]: {
      width: "30%",
      margin: theme.spacing(0),
    },
  },
  testimonials: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(10, 0, 0, 0),
    width: "100%",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      padding: theme.spacing(5, 0),
      gap: theme.spacing(2.5),
      width: "100%",
    },
  },
  testimonial: {
    width: "23%",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      minWidth: "270px",
      padding: theme.spacing(1, 0),
      alignItems: "start",
      textAlign: "start",
    },
  },
  testimonialUsername: {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#3C3E49",
  },
  testimonialStarsWrapper: {
    display: "flex",
  },
  testimonialStar: {
    fontSize: "24px",
  },
  testimonialRated: {
    color: "#FFC400",
  },
  testimonialUnrated: {
    color: "#C4C4C4",
  },
  testimonialDescription: {
    fontSize: "14px",
    lineHeight: "21px",
    fontStyle: "italic",
    color: "#3C3E49",
    padding: theme.spacing(3, 0, 3, 0),
  },
}));
// Customizable Area End