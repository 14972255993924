// Customizable Area Start
import React, { useState } from "react";
import { Box, CardMedia } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStyles } from "./styles/ImageCard.web";

interface Props {
  image: any;
  imageClassName: string;
  onClick?: any;
  alt?: any;
}

const ImageCard: React.FC<Props> = ({
  image,
  imageClassName,
  onClick,
  alt,
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <>
      {loading && (
        <Box className={`${classes.wrapper} ${imageClassName}`}>
          <CircularProgress className={classes.spinner} />
        </Box>
      )}
      <CardMedia
        component="img"
        image={image}
        onLoad={handleImageLoad}
        className={`${imageClassName} ${
          loading ? classes.noImage : classes.blockImage
        }`}
        onClick={onClick}
        alt={alt}
      />
    </>
  );
};

export default ImageCard;
// Customizable Area End