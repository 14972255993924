Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.contentTypeApplicationJson = "application/json";

exports.getBrandEndPoint = "catalogue/brands";
exports.upsertBrandEndPoint = "catalogue/brands";

exports.postMethod = 'POST'
exports.putMethod = 'PUT'
exports.getMethod = 'GET'
// Customizable Area End