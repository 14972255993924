import React from "react";
// Customizable Area Start
import Ccavenueintegration2SuccessController, { Props } from "./Ccavenueintegration2SuccessController.web";
import { Box, Fade, Modal } from "@material-ui/core";
import { generateHTML } from "./CCAvenueUtils";
import { Theme, createStyles, withStyles } from '@material-ui/core/styles';
// Customizable Area End

class CcavenueModal extends Ccavenueintegration2SuccessController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        return (
            <Modal open={this.props.ccavenueModalOpen} onClose={this.handleCloseModal} className={classes?.modal}>
                <Fade in={this.props.ccavenueModalOpen}>
                    <Box className={classes?.paper}>
                        <iframe
                            ref={this.iframeRef}
                            width="100%"
                            height="600px"
                            style={{ display: "unset" }}
                            srcDoc={generateHTML(this.props.ccavenueModalShowRespons)}
                        />
                    </Box>
                </Fade>
            </Modal>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const useStyles = (theme: Theme) => createStyles({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: "#fff",
        width: "60%"
    }
});

export default withStyles(useStyles, { withTheme: true })(CcavenueModal);
export{CcavenueModal}
// Customizable Area End