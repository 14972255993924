// Customizable Area Start
import React from "react";
import { IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CCAvenueCardController from "./CCAvenueCardController.web";
const configJSON = require("./config.js");
const clipboardIcon = require("./assets/clipboard.svg");
const ccaIcon = require("./assets/cca-logo.png");
import CCAvenueActivateDialog from "./CCAvenueActivateDialog.web";
import "./assets/styles.css"
export default class CCAvenueCard extends CCAvenueCardController {
  render() {
    const {showActivateDialog, payment} = this.state;
    const merchant_id  = payment?.attributes?.merchant_id;
    const access_code = payment?.attributes?.access_code;
    const isMerchantIDSet = merchant_id !== "n/a";

    return (
      <div className="payment" style={{ display: "contents"}}>
      <div className="payment-card-vertical ccavenue-payment-card">
        <div className="card-image">
          <img src={ccaIcon} className="payment-card-type" data-testid="ccavenue-img-icon" />
        </div>
        <div className="payment-card-details">
          <div className="payment-card-information">
            {isMerchantIDSet ? (
              <div className="payment-card-active">{configJSON.active}</div>
            ) : (
              <div className="payment-card-inactive">{configJSON.notActive}</div>
            )}
            <div className="payment-card-configuration-type" data-testid="ccavenue-title">
              {configJSON.ccAvenueTitle}
            </div>
            {isMerchantIDSet ? (
              <>
                <div className="payment-card-card-information">
                  <span className="title text-nowrap">{configJSON.labelMerchantId}:</span>
                  <span className="information" title={merchant_id ?? ""}>
                    {merchant_id}
                  </span>
                  <IconButton className="clipboard">
                    <img src={clipboardIcon} data-testid="ccavenue-img-clipboard" onClick={this.handleClipboard(merchant_id)} />
                  </IconButton>
                </div>
                <div className="payment-card-card-information">
                  <span className="title text-nowrap">{configJSON.labelAccessCode}:</span>
                  <span className="information" title={access_code ?? ""}>
                    {access_code}
                  </span>
                  <IconButton className="clipboard">
                    <img src={clipboardIcon} data-testid="ccavenue-img-clipboard" onClick={this.handleClipboard(access_code)} />
                  </IconButton>
                </div>
              </>
            ) : (
              <div className="payment-card-card-not-active">{configJSON.useToGetMessage}</div>
            )}
          </div>
          <Button className="card-button" onClick={this.handleClick} data-testid="ccavenue-activate-button">
            {isMerchantIDSet ? configJSON.editAccount : configJSON.activateAccount}
          </Button>
        </div>
        <CCAvenueActivateDialog
          open={showActivateDialog}
          setOpen={this.handleClick}
          defaultValues={payment}
          setDialogState={this.props.setDialogState} 
          showLoader={this.props.showLoader} 
          hideLoader={this.props.hideLoader}
          showHeader={this.props.showHeader}
        />
      </div>
      </div>
    );
  }
}
// Customizable Area End