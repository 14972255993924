// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import BlockHelpers from "../../utilities/src/BlockHelpers";
import { parseBrand } from "../../utilities/src/helpers/utils";
import { ServiceImage } from "../../utilities/src/models/ServiceImage";
import { Navigation } from "../../utilities/src/models/Navigation";
import { saveStoreDetails } from "../../utilities/src/components/Currency.web";

export const configJSON = require("./config");

export interface Props {
  navigation: Navigation;
  identifier: string;
}

interface S {
  logo: ServiceImage | File | null;
  storeName: string;
  searchQuery: string;
}

interface SS {
  identifier: string;
}

export default class AppheaderController extends BlockComponent<Props, S, SS> {
  getBrandsApiCallId: string;
  getStoreDetailsCallId: string
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SearchQueryMessage)
    ];

    this.state = {
      logo: null,
      storeName: "",
      searchQuery: ""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getBrands();
    this.getStoreDetails();
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Recived", message);
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getBrandsApiCallId != null &&
      this.getBrandsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.handleGetBrandsResponse(responseJson);
      } else {
        this.setState({
          logo: null,
          storeName: ""
        });

        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (getName(MessageEnum.SearchQueryMessage) === message.id) {
      const searchQuery = message.getData("SearchQueryMessage").trim();
      this.setState({ searchQuery });
    } else if (this.getStoreDetailsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) ){
        saveStoreDetails(responseJson?.data?.attributes);
      }
  };

  handleGetBrandsResponse = (
    responseJson: Record<string, string | number | Object | Object[]>
  ) => {
    const response = responseJson.data;

    const { logo, storeName } = parseBrand(response);

    this.setState({
      logo,
      storeName
    });
  };

  getBrands = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson
    };

    this.getBrandsApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getBrandsEndPoint,
      header
    });
  };

  confirmSearch = (searchQuery: string) => {
    this.setState({ searchQuery });
    this.props.navigation.navigate("ServicesSearch", {
      searchQuery
    });
  };

  getStoreDetails = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson
    };

    this.getStoreDetailsCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getStoreDetails,
      header
    });
  }
}
// Customizable Area End
