Object.defineProperty(exports, '__esModule', {
  value: true
})

exports.upsertCommonSettingsAdminApi="bx_block_settings/common_settings";
exports.upsertCommonSettingsAdminAppointmentHoursApi="bx_block_settings/common_settings/appointment_hours";
exports.upsertCommonSettingsAdminAllAppointmentHoursApi="bx_block_settings/common_settings/all_appointment_hours";

// Customizable Area Start
exports.contentTypeApplicationJson = 'application/json'

exports.getBrandsEndPoint = 'catalogue/brands'

exports.getMethod = 'GET'
exports.putMethod = 'PUT'
exports.postMethod = 'POST'

// Customizable Area End