// Customizable Area Start
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  TextField,
  Button,
  Switch,
  Typography,
  CardMedia,
  Slide,
  Divider,
  Checkbox,
  List,
  ListItem,
  SvgIcon,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { useStyles } from "./styles/DashboardAdmin.web";
import ImageCard from "../../utilities/src/components/ImageCard.web";
import { ServiceImage } from "../../utilities/src/models/ServiceImage";
import { Brand } from "../../utilities/src/models/Brand";
import { ReactComponent as CheckboxIcon } from "../assets/Checkbox.svg";
import { ReactComponent as CheckboxIconChecked } from "../assets/Checkbox-checked.svg";
import { Platform } from "../../utilities/src/models/Platform";
import { countries } from "./Countries";
import { Banner } from "../../utilities/src/models/Banner";

const validationSchema = Yup.object().shape({
  logo: Yup.mixed().required("Logo is required"),
  storeName: Yup.string(),

  countryCode: Yup.string(),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .test("phone", "Invalid Phone Number", function () {
      const { from } = this;
      if (from && from.length) {
        const phoneRegex =
          /^(?:(?:\+44\s?|\+1\s?|\+91\s?)?\-?\s?\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{4}|\+971\s?\(?\d{1,2}\)?[\s]?\d{3}[\s]?\d{4})$/;
        const isValidPhone = phoneRegex.test(
          from[0].value.countryCode + from[0].value.phoneNumber
        );
        if (!isValidPhone) {
          return false;
        }
        return true;
      }
    }),
  copyright: Yup.string()
    .required("Copyright is required")
    .max(55, "Copyright must be at most 55 characters."),
  socialMedias: Yup.object().shape({
    facebook: Yup.object().shape({
      selected: Yup.boolean(),
      url: Yup.string().when("selected", {
        is: true,
        then: (schema) =>
          schema.required("URL is required").url("Invalid URL format"),
      }),
    }),
    instagram: Yup.object().shape({
      selected: Yup.boolean(),
      url: Yup.string().when("selected", {
        is: true,
        then: (schema) =>
          schema.required("URL is required").url("Invalid URL format"),
      }),
    }),
    twitter: Yup.object().shape({
      selected: Yup.boolean(),
      url: Yup.string().when("selected", {
        is: true,
        then: (schema) =>
          schema.required("URL is required").url("Invalid URL format"),
      }),
    }),
    youtube: Yup.object().shape({
      selected: Yup.boolean(),
      url: Yup.string().when("selected", {
        is: true,
        then: (schema) =>
          schema.required("URL is required").url("Invalid URL format"),
      }),
    }),
  }),
  applications: Yup.object().shape({
    android: Yup.object().shape({
      selected: Yup.boolean(),
      url: Yup.string().when("selected", {
        is: true,
        then: (schema) =>
          schema.required("URL is required").url("Invalid URL format"),
      }),
    }),
    ios: Yup.object().shape({
      selected: Yup.boolean(),
      url: Yup.string().when("selected", {
        is: true,
        then: (schema) =>
          schema.required("URL is required").url("Invalid URL format"),
      }),
    }),
  }),

  banners: Yup.array().of(
    Yup.object().shape({
      image: Yup.mixed()
        .nullable()
        .test("required", "Image is required", function () {
          const { from } = this;
          if (from && from.length) {
            if (from[1].value.banners[0] === from[0].value) {
              return !!from[0].value.image;
            }
            return true;
          }
        }),
      url: Yup.string().nullable().when("image", {
        is: (image: any) => image && (image instanceof File || Boolean(image.url)),
        then: (schema) =>
          schema.required("URL is required").url("Invalid URL format"),
      }),
    })
  ),

  testimonial: Yup.boolean(),
});

export interface Props {
  brand: Brand | null;
  isSaved: boolean;
  upsertBrand: (
    values: any,
    isUpdate: boolean,
    deletedImagesIds?: number[]
  ) => void;
}

const DashboardAdmin: React.FC<Props> = ({ brand, upsertBrand, isSaved = false }) => {
  const logoInputRef = useRef<HTMLInputElement>(null);
  const bannersInputRef = Array.from({ length: 3 }, () =>
    useRef<HTMLInputElement>(null)
  );
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const resetButtonRef = useRef<HTMLButtonElement>(null);
  const [deletedImagesIds, setDeletedImagesIds] = useState<number[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({
    logo: undefined,
    storeName: "",
    countryCode: "+1",
    phoneNumber: "",
    copyright: "",
    socialMedias: {
      facebook: {
        selected: false,
        url: "",
      },
      instagram: {
        selected: false,
        url: "",
      },
      twitter: {
        selected: false,
        url: "",
      },
      youtube: {
        selected: false,
        url: "",
      },
    },
    applications: {
      android: {
        selected: false,
        url: "",
      },
      ios: {
        selected: false,
        url: "",
      },
    },
    banners: [
      {
        image: undefined,
        url: "",
      },
      {
        image: undefined,
        url: "",
      },
      {
        image: undefined,
        url: "",
      },
    ],
    testimonial: false,
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (brand) {
        upsertBrand(values, true, deletedImagesIds);
        setIsSaving(true);
        setDeletedImagesIds([]);
      } else {
        setIsSaving(true);
        upsertBrand(values, false);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    getFieldProps,
    setValues,
    setErrors,
    setTouched,
    initialErrors,
    initialTouched,
  } = formik;

  const classes = useStyles();

  useEffect(() => {
    setInitialValues({
      logo: brand?.logo || undefined,
      storeName: brand?.storeName || "",
      countryCode: brand?.countryCode || "+1",
      phoneNumber: brand?.phoneNumber || "",
      copyright: brand?.copyright || "",
      socialMedias: {
        facebook: {
          selected: false,
          url: "",
        },
        instagram: {
          selected: false,
          url: "",
        },
        twitter: {
          selected: false,
          url: "",
        },
        youtube: {
          selected: false,
          url: "",
        },
        ...brand?.socialMedias,
      },
      applications: {
        android: {
          selected: false,
          url: "",
        },
        ios: {
          selected: false,
          url: "",
        },
        ...brand?.applications,
      },
      banners: initialValues.banners.map((_: Banner, index: number) => ({
        image: undefined,
        url: "",
        ...brand?.banners[index],
      })),
      testimonial: brand?.testimonial || false,
    });
  }, [brand]);

  useEffect(() => {
    if (isSaved) {
      setIsSaving(false);
    }
  }, [isSaved]);

  const clearFileInput = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref.current) {
      ref.current.value = "";
    }
  };

  const upsertImage = (ref: React.RefObject<HTMLInputElement>) => {
    ref.current && ref.current.click();
  };

  const removeImage = (ref: React.RefObject<HTMLInputElement>) => {
    const isImage = (obj: any): obj is ServiceImage => {
      return "id" in obj && "url" in obj;
    };

    const bannerIndex = bannersInputRef.indexOf(ref);
    if (values.banners) {
      initialValues.banners[bannerIndex].image &&
        isImage(initialValues.banners[bannerIndex].image) &&
        setDeletedImagesIds((currentIds) => {
          return [
            ...currentIds,
            (initialValues.banners[bannerIndex].image as ServiceImage).id,
          ];
        });

      const modifiedBanners: {
        image: ServiceImage | File | null;
        url: string;
      }[] = [...values.banners];

      modifiedBanners[bannerIndex] = {
        url: "",
        image: null,
      };
      setValues({ ...values, banners: modifiedBanners }, true);
    }
  };

  const checkImage = (
    event: ChangeEvent<HTMLInputElement>,
    ref: React.RefObject<HTMLInputElement>,
    isLogo: boolean
  ) => {
    const sizeLimit = 20971520;
    const newImage: File | null =
      event &&
      event.currentTarget &&
      event.currentTarget.files &&
      event.currentTarget.files[0];

    if (newImage) {
      if (isLogo) {
        setTouched({ ...touched, logo: true }, false);
      } else {
        const bannerIndex = bannersInputRef.indexOf(ref);
        const modifiedBannersTouched = initialValues.banners.map(
          (_: Banner, index: number) => ({
            ...(touched &&
              touched.banners &&
              ((
                touched.banners as {
                  [key: string]: any;
                }[]
              )[index] as Banner)),
            image: false,
            url: false,
          })
        );
        modifiedBannersTouched[bannerIndex] = {
          ...modifiedBannersTouched[bannerIndex],
          image: true,
        };
        setTouched({ ...touched, banners: modifiedBannersTouched }, false);
      }
      if (!newImage.type.startsWith("image/")) {
        if (isLogo) {
          setErrors({ ...errors, logo: "Image should be a JPEG, PNG, GIF, TIFF, RAW, PSD, or JPG type" });
        } else {
          const bannerIndex = bannersInputRef.indexOf(ref);
          const modifiedBannersErrors = initialValues.banners.map(
            (_: Banner, index: number) => ({
              ...(errors &&
                errors.banners &&
                ((
                  errors.banners as {
                    [key: string]: any;
                  }[]
                )[index] as Banner)),
              image: "",
              url: "",
            })
          );
          modifiedBannersErrors[bannerIndex] = {
            ...modifiedBannersErrors[bannerIndex],
            image: "Image should be a JPEG, PNG, GIF, TIFF, RAW, PSD, or JPG type",
          };
          setErrors({ ...errors, banners: modifiedBannersErrors });
        }
        clearFileInput(ref);
        return;
      }
      if (newImage.size > sizeLimit) {
        if (isLogo) {
          setErrors({ ...errors, logo: "Image size should not be greater than 20MB" });
        } else {
          const bannerIndex = bannersInputRef.indexOf(ref);
          const modifiedBannersErrors = initialValues.banners.map(
            (_: Banner, index: number) => ({
              ...(errors &&
                errors.banners &&
                ((
                  errors.banners as {
                    [key: string]: any;
                  }[]
                )[index] as Banner)),
              image: "",
              url: "",
            })
          );
          modifiedBannersErrors[bannerIndex] = {
            ...modifiedBannersErrors[bannerIndex],
            image: "Image size should not be greater than 20MB",
          };
          setErrors({ ...errors, banners: modifiedBannersErrors });
        }
        clearFileInput(ref);
        return;
      }
    }

    if (isLogo) {
      setValues({ ...values, logo: newImage }, true);
    } else {
      const bannerIndex = bannersInputRef.indexOf(ref);
      setDeletedImagesIds((currentIds) =>
        currentIds.filter(
          (currentId) =>
            currentId !==
            (initialValues.banners[bannerIndex].image as ServiceImage)?.id
        )
      );
      if (values.banners) {
        const modifiedBanners = [...(values.banners as Banner[])];
        modifiedBanners[bannerIndex] = {
          ...modifiedBanners[bannerIndex],
          image: newImage!,
        };
        setValues({ ...values, banners: modifiedBanners }, true);
      }
    }
    clearFileInput(ref);
  };

  const displayPlatformName = (platformName: string) => {
    switch (platformName) {
      case "facebook":
        return "Facebook";
      case "instagram":
        return "Instagram";
      case "twitter":
        return "Twitter";
      case "youtube":
        return "Youtube";
      case "android":
        return "Android App";
      case "ios":
        return "iOS App";
    }
  };

  const displayUrlOrStoreName = (platformName: string) => {
    switch (platformName) {
      case "facebook":
        return "Facebook url";
      case "instagram":
        return "Instagram url";
      case "twitter":
        return "Twitter url";
      case "youtube":
        return "Youtube url";
      case "android":
        return "Google Playstore url";
      case "ios":
        return "App store url";
    }
  };

  const clearPlatformUrl = (isApplication: boolean, platformName: string) => {
    if (!isApplication) {
      if (values.socialMedias) {
        const modifiedSocialMedias = { ...values.socialMedias };
        modifiedSocialMedias[platformName] = {
          selected: !values.socialMedias[platformName].selected,
          ...(!values.socialMedias[platformName].selected && { url: "" }),
        };
        setValues({ ...values, socialMedias: { ...modifiedSocialMedias } });
      }

      if (touched.socialMedias) {
        const modifiedSocialMediasTouched = {
          ...(touched.socialMedias as {
            [key: string]: any;
          } as Record<string, { selected: boolean; url: boolean }>),
        };
        modifiedSocialMediasTouched[platformName] = {
          selected: true,
          url: false,
        };

        setTouched({
          ...touched,
          socialMedias: { ...modifiedSocialMediasTouched },
        });
      }
    } else {
      if (values.applications) {
        const modifiedApplications = { ...values.applications };
        modifiedApplications[platformName] = {
          selected: !values.applications[platformName].selected,
          ...(!values.applications[platformName].selected && { url: "" }),
        };
        setValues({ ...values, applications: { ...modifiedApplications } });
      }

      if (touched.applications) {
        const modifiedApplicationsTouched = {
          ...(touched.applications as {
            [key: string]: any;
          } as Record<string, { selected: boolean; url: boolean }>),
        };
        modifiedApplicationsTouched[platformName] = {
          selected: true,
          url: false,
        };

        setTouched({
          ...touched,
          applications: { ...modifiedApplicationsTouched },
        });
      }
    }
  };

  const isFormChanged =
    JSON.stringify(values) !== JSON.stringify(initialValues);

  const discardChanges = () => {
    resetButtonRef.current && resetButtonRef.current.click();
  };

  const saveChanges = () => {
    submitButtonRef.current && submitButtonRef.current.click();
  };

  const reset = () => {
    setDeletedImagesIds([]);
    setValues({
      ...initialValues,
      banners: [...initialValues.banners],
    });
    setErrors({ ...initialErrors });
    setTouched({ ...initialTouched });
  };

  return (
    <>
      <Slide direction="down" in={isFormChanged} mountOnEnter unmountOnExit style={{left: 0, right: 0}}>
        <Box className={classes.formActionsWrapper}>
          <Box className={classes.formActions}>
            <CardMedia
              component="img"
              src={require("../assets/Builder Studio Store.png")}
              className={classes.logo}
            />

            <Box>
              <Button
                variant="text"
                className={classes.discardChangesbutton}
                onClick={discardChanges}
              >
                Discard changes
              </Button>
              <Button
                variant="contained"
                className={classes.saveChangesbutton}
                onClick={saveChanges}
                disabled={isSaving}
              >
                {isSaving && <CircularProgress size={15} style={{ marginRight: 10 }} />}
                Save changes
              </Button>
            </Box>
          </Box>
        </Box>
      </Slide>

      <Box className={classes.container}>
        <form onSubmit={handleSubmit} className={classes.wrapper}>
          <Box className={classes.leftSideWrapper}>
            <Box className={classes.card}>
              <Typography className={classes.cardTitle}>Header</Typography>

              <Box className={classes.inputsWrapper}>
                <Box>
                  <Typography className={classes.inputlabel}>Logo *</Typography>
                  <input
                    data-testid="image-upload"
                    ref={logoInputRef}
                    name="logo"
                    className={classes.hidden}
                    type="file"
                    accept="image/*"
                    onChange={(event) => checkImage(event, logoInputRef, true)}
                    onBlur={handleBlur}
                  />
                  {(values.logo as ServiceImage | File) ? (
                    <Box>
                      <ImageCard
                        imageClassName={classes.previewImage}
                        image={
                          (values.logo as ServiceImage).url ||
                          URL.createObjectURL && URL.createObjectURL(values.logo as File)
                        }
                      />
                      <Button
                        variant="text"
                        onClick={() => upsertImage(logoInputRef)}
                        className={classes.editButton}
                      >
                        Edit
                      </Button>
                    </Box>
                  ) : (
                    <Box
                      className={classes.imageUpload}
                      onClick={() => upsertImage(logoInputRef)}
                    >
                      <AddIcon className={classes.addIcon} />
                      <Typography className={classes.addImageText}>
                        Add logo
                      </Typography>
                      <Typography className={classes.fileSize}>
                        Max 20MB
                      </Typography>
                    </Box>
                  )}

                  {touched && touched.logo && errors && errors.logo && (
                    <Typography className={classes.imageValidationError}>
                      {errors.logo}
                    </Typography>
                  )}
                </Box>

                <Box>
                  <Typography className={classes.inputlabel}>
                    Store Name
                  </Typography>
                  <TextField
                    variant="outlined"
                    placeholder="Store Name"
                    fullWidth
                    {...getFieldProps("storeName")}
                    error={Boolean(
                      touched && touched.storeName && errors && errors.storeName
                    )}
                    helperText={
                      touched && touched.storeName && errors && errors.storeName
                    }
                  />
                </Box>
              </Box>
            </Box>

            <Box className={classes.card}>
              <Typography className={classes.cardTitle}>Footer</Typography>

              <Box className={classes.inputsWrapper}>
                <Box>
                  <Typography className={classes.inputlabel}>
                    Phone number *
                  </Typography>
                  <Box className={classes.phoneNumberWrapper}>
                    <Select
                      type="number"
                      variant="outlined"
                      defaultValue={values.countryCode}
                      className={classes.select}
                      {...getFieldProps("countryCode")}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}
                    >
                      {countries.map((country) => (
                        <MenuItem key={country.code} value={country.phone}>
                          <Box className={classes.countryCodeText}>
                            <img
                              src={`https://flagcdn.com/h20/${country.code.toLowerCase()}.png`}
                              className={classes.countryCodeImage}
                            />
                            {country.phone}
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>

                    <TextField
                      variant="outlined"
                      placeholder="Enter your phone number"
                      fullWidth
                      className={classes.phoneNumber}
                      {...getFieldProps("phoneNumber")}
                      error={Boolean(
                        touched &&
                        touched.phoneNumber &&
                        errors &&
                        errors.phoneNumber
                      )}
                      helperText={
                        touched &&
                        touched.phoneNumber &&
                        errors &&
                        errors.phoneNumber
                      }
                    />
                  </Box>
                </Box>

                <Box>
                  <Box className={classes.copyrightWrapper}>
                    <Typography className={classes.inputlabel}>
                      Copyright *
                    </Typography>

                    <Typography className={classes.copyrightCounter}>
                      {values.copyright.length} / 55
                    </Typography>
                  </Box>

                  <TextField
                    variant="outlined"
                    placeholder="Copyright © YYYY 'Company's Name'. All rights reserved."
                    fullWidth
                    {...getFieldProps("copyright")}
                    error={Boolean(
                      touched && touched.copyright && errors && errors.copyright
                    )}
                    helperText={
                      touched && touched.copyright && errors && errors.copyright
                    }
                  />

                  <Typography className={classes.helperText}>
                    This copyright text will appear in the bottom of the page
                  </Typography>
                </Box>

                <Divider />

                <Box>
                  <Typography className={classes.inputlabel}>
                    Social Media
                  </Typography>

                  <List className={classes.list}>
                    {Object.entries<Platform>(values.socialMedias).map(
                      ([platformName, platform]) => (
                        <ListItem key={platformName} className={classes.item}>
                          <Checkbox
                            checked={platform.selected}
                            className={classes.checkbox}
                            icon={
                              <SvgIcon
                                component={CheckboxIcon}
                                viewBox="0 0 26 26"
                              />
                            }
                            checkedIcon={
                              <SvgIcon
                                component={CheckboxIconChecked}
                                viewBox="0 0 26 26"
                              />
                            }
                            {...getFieldProps(
                              `socialMedias[${platformName}].selected`
                            )}
                            onChange={() =>
                              clearPlatformUrl(false, platformName)
                            }
                          />
                          <Box className={classes.platformWrapper}>
                            <Typography className={classes.inputlabel}>
                              {displayPlatformName(platformName)}
                            </Typography>
                            {platform.selected && (
                              <>
                                <Typography
                                  className={`${classes.inputlabel} ${classes.storeLabel}`}
                                >
                                  {displayUrlOrStoreName(platformName)} *
                                </Typography>
                                <TextField
                                  variant="outlined"
                                  placeholder="http://www.example.com"
                                  fullWidth
                                  {...getFieldProps(
                                    `socialMedias[${platformName}].url`
                                  )}
                                  error={Boolean(
                                    touched &&
                                    touched.socialMedias &&
                                    ((
                                      touched.socialMedias as {
                                        [key: string]: any;
                                      }
                                    )[platformName] as Platform) &&
                                    (
                                      (
                                        touched.socialMedias as {
                                          [key: string]: any;
                                        }
                                      )[platformName] as Platform
                                    ).url &&
                                    errors &&
                                    errors.socialMedias &&
                                    ((
                                      errors.socialMedias as {
                                        [key: string]: any;
                                      }
                                    )[platformName] as Platform) &&
                                    (
                                      (
                                        errors.socialMedias as {
                                          [key: string]: any;
                                        }
                                      )[platformName] as Platform
                                    ).url
                                  )}
                                  helperText={
                                    touched &&
                                    touched.socialMedias &&
                                    ((
                                      touched.socialMedias as {
                                        [key: string]: any;
                                      }
                                    )[platformName] as Platform) &&
                                    (
                                      (
                                        touched.socialMedias as {
                                          [key: string]: any;
                                        }
                                      )[platformName] as Platform
                                    ).url &&
                                    errors &&
                                    errors.socialMedias &&
                                    ((
                                      errors.socialMedias as {
                                        [key: string]: any;
                                      }
                                    )[platformName] as Platform) &&
                                    (
                                      (
                                        errors.socialMedias as {
                                          [key: string]: any;
                                        }
                                      )[platformName] as Platform
                                    ).url
                                  }
                                />
                              </>
                            )}
                          </Box>
                        </ListItem>
                      )
                    )}
                  </List>
                </Box>

                <Divider />

                <Box>
                  <Typography className={classes.inputlabel}>
                    Download Apps
                  </Typography>

                  <List className={classes.list}>
                    {Object.entries<Platform>(values.applications).map(
                      ([platformName, platform]) => (
                        <ListItem key={platformName} className={classes.item}>
                          <Checkbox
                            checked={platform.selected}
                            className={classes.checkbox}
                            icon={
                              <SvgIcon
                                component={CheckboxIcon}
                                viewBox="0 0 26 26"
                              />
                            }
                            checkedIcon={
                              <SvgIcon
                                component={CheckboxIconChecked}
                                viewBox="0 0 26 26"
                              />
                            }
                            {...getFieldProps(
                              `applications[${platformName}].selected`
                            )}
                            onChange={() =>
                              clearPlatformUrl(true, platformName)
                            }
                          />
                          <Box className={classes.platformWrapper}>
                            <Typography className={classes.inputlabel}>
                              {displayPlatformName(platformName)}
                            </Typography>
                            {platform.selected && (
                              <>
                                <Typography
                                  className={`${classes.inputlabel} ${classes.storeLabel}`}
                                >
                                  {displayUrlOrStoreName(platformName)} *
                                </Typography>
                                <TextField
                                  variant="outlined"
                                  placeholder="http://www.example.com"
                                  fullWidth
                                  {...getFieldProps(
                                    `applications[${platformName}].url`
                                  )}
                                  error={Boolean(
                                    touched &&
                                    touched.applications &&
                                    ((
                                      touched.applications as {
                                        [key: string]: any;
                                      }
                                    )[platformName] as Platform) &&
                                    (
                                      (
                                        touched.applications as {
                                          [key: string]: any;
                                        }
                                      )[platformName] as Platform
                                    ).url &&
                                    errors &&
                                    errors.applications &&
                                    ((
                                      errors.applications as {
                                        [key: string]: any;
                                      }
                                    )[platformName] as Platform) &&
                                    (
                                      (
                                        errors.applications as {
                                          [key: string]: any;
                                        }
                                      )[platformName] as Platform
                                    ).url
                                  )}
                                  helperText={
                                    touched &&
                                    touched.applications &&
                                    ((
                                      touched.applications as {
                                        [key: string]: any;
                                      }
                                    )[platformName] as Platform) &&
                                    (
                                      (
                                        touched.applications as {
                                          [key: string]: any;
                                        }
                                      )[platformName] as Platform
                                    ).url &&
                                    errors &&
                                    errors.applications &&
                                    ((
                                      errors.applications as {
                                        [key: string]: any;
                                      }
                                    )[platformName] as Platform) &&
                                    (
                                      (
                                        errors.applications as {
                                          [key: string]: any;
                                        }
                                      )[platformName] as Platform
                                    ).url
                                  }
                                />
                              </>
                            )}
                          </Box>
                        </ListItem>
                      )
                    )}
                  </List>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className={classes.RightSideWrapper}>
            <Box className={classes.card}>
              <Typography className={classes.cardTitle}>Banner</Typography>

              <Box className={classes.inputsWrapper}>
                {values.banners.map((banner: Banner, index: number) => (
                  <Box className={classes.inputsWrapper} key={index}>
                    <Box>
                      <Typography className={classes.inputlabel}>
                        Banner {index + 1} {index === 0 && "*"}
                      </Typography>
                      <input
                        data-testid="image-upload"
                        ref={bannersInputRef[index]}
                        name="banners[index].image"
                        className={classes.hidden}
                        type="file"
                        accept="image/*"
                        onChange={(event) =>
                          checkImage(event, bannersInputRef[index], false)
                        }
                        onBlur={handleBlur}
                      />
                      {(banner.image && (banner.image instanceof File || Boolean(banner.image.url))) ? (
                        <Box>
                          <ImageCard
                            imageClassName={classes.previewImage}
                            image={
                              (banner.image as ServiceImage).url ||
                              URL.createObjectURL && URL.createObjectURL(banner.image as File)
                            }
                          />
                          <Box>
                            <Button
                              variant="text"
                              onClick={() =>
                                upsertImage(bannersInputRef[index])
                              }
                              className={classes.editButton}
                            >
                              Edit
                            </Button>
                            {index > 0 && (
                              <Button
                                variant="text"
                                onClick={() =>
                                  removeImage(bannersInputRef[index])
                                }
                                className={classes.removeButton}
                              >
                                Remove
                              </Button>
                            )}
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          className={classes.imageUpload}
                          onClick={() => upsertImage(bannersInputRef[index])}
                        >
                          <AddIcon className={classes.addIcon} />
                          <Typography className={classes.addImageText}>
                            Add banner
                          </Typography>
                          <Typography className={classes.fileSize}>
                            Max 20MB
                          </Typography>
                        </Box>
                      )}

                      {Boolean(
                        touched &&
                        touched.banners &&
                        ((
                          touched.banners as {
                            [key: string]: any;
                          }[]
                        )[index] as Banner) &&
                        (
                          (
                            touched.banners as {
                              [key: string]: any;
                            }[]
                          )[index] as Banner
                        ).image &&
                        errors &&
                        errors.banners &&
                        ((
                          errors.banners as {
                            [key: string]: any;
                          }[]
                        )[index] as Banner) &&
                        (
                          (
                            errors.banners as {
                              [key: string]: any;
                            }[]
                          )[index] as Banner
                        ).image
                      ) && (
                          <Typography className={classes.imageValidationError}>
                            {errors &&
                              errors.banners &&
                              ((
                                errors.banners as {
                                  [key: string]: any;
                                }[]
                              )[index] as Banner) &&
                              (
                                (
                                  errors.banners as {
                                    [key: string]: any;
                                  }[]
                                )[index] as Banner
                              ).image}
                          </Typography>
                        )}
                    </Box>

                    <Box>
                      <Typography className={classes.inputlabel}>
                        URL {banner.image && "*"}
                      </Typography>
                      <TextField
                        variant="outlined"
                        inputProps={{ className: classes.input }}
                        placeholder="http://www.example.com"
                        fullWidth
                        {...getFieldProps(`banners[${index}].url`)}
                        disabled={!banner.image}
                        error={Boolean(
                          touched &&
                          touched.banners &&
                          ((
                            touched.banners as {
                              [key: string]: any;
                            }[]
                          )[index] as Banner) &&
                          (
                            (
                              touched.banners as {
                                [key: string]: any;
                              }[]
                            )[index] as Banner
                          ).url &&
                          errors &&
                          errors.banners &&
                          ((
                            errors.banners as {
                              [key: string]: any;
                            }[]
                          )[index] as Banner) &&
                          (
                            (
                              errors.banners as {
                                [key: string]: any;
                              }[]
                            )[index] as Banner
                          ).url
                        )}
                        helperText={
                          touched &&
                          touched.banners &&
                          ((
                            touched.banners as {
                              [key: string]: any;
                            }[]
                          )[index] as Banner) &&
                          (
                            (
                              touched.banners as {
                                [key: string]: any;
                              }[]
                            )[index] as Banner
                          ).url &&
                          errors &&
                          errors.banners &&
                          ((
                            errors.banners as {
                              [key: string]: any;
                            }[]
                          )[index] as Banner) &&
                          (
                            (
                              errors.banners as {
                                [key: string]: any;
                              }[]
                            )[index] as Banner
                          ).url
                        }
                      />
                    </Box>

                    {index < 2 && <Divider />}
                  </Box>
                ))}
              </Box>
            </Box>

            <Box className={classes.card}>
              <Box className={classes.testimonialHeader}>
                <Typography className={classes.testimonialTitle}>
                  Testimonial *
                </Typography>

                <Box className={classes.switchWrapper}>
                  <Switch
                    color="primary"
                    checked={values.testimonial}
                    className={classes.switch}
                    {...getFieldProps("testimonial")}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Button
            ref={resetButtonRef}
            className={classes.hidden}
            onClick={reset}
          />
          <Button
            ref={submitButtonRef}
            type="submit"
            className={classes.hidden}
          />
        </form>
      </Box>
    </>
  );
};

export default DashboardAdmin;
// Customizable Area End