// Customizable Area Start
import Payment from 'payment'
// Customizable Area End

// Customizable Area Start
function clearNumber(value = '') {
  return value.replace(/\D+/g, '')
}

export function formatCreditCardNumber(value: any) {
  if (!value) {
    return value
  }

  const issuer = Payment.fns.cardType(value)
  const clearValue = clearNumber(value)
  let nextValue

  switch (issuer) {
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`
      break
    case 'dinersclub':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`
      break
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`
      break
  }
  return nextValue.trim()
}

interface AllValues {
  number: string
}

export function formatCVC(value: any, allValues: AllValues) {
  const clearValue = clearNumber(value)
  let maxLength = 3

  if (allValues.number) {
    Payment.fns.cardType(allValues.number)
  }

  return clearValue.slice(0, maxLength)
}

export function formatExpirationDate(value: any) {
  const clearValue = clearNumber(value)

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`
  }

  return clearValue
}
// Customizable Area End