Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.contentTypeApplicationJson = "application/json";

exports.getCataloguesEndPoint = "bx_block_catalogue/catalogues";
exports.getCategoriesEndPoint = "bx_block_categories/categories";
exports.searchCataloguesEndPoint = 'bx_block_catalogue/catalogues/search'

exports.getMethod = "GET";
// Customizable Area End