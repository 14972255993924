import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {
  generateRequestMessage,
  isTokenExpired,
  clearStorageData,
} from "../../ss-cms-common-components/src/Utilities/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  identifier: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface CustomCustomer {
  // Customizable Area Start
  id: string;
  type: string;
  attributes: {
    email: string;
    full_name: string;
    full_phone_number: string;
    created_at: string;
  }
  // Customizable Area End
}


export interface S {
  // Customizable Area Start
  token: string;
  customerList: CustomCustomer[];
  loading: boolean;
  searchBy: string;
  showList: boolean;
  // Customizable Area End
}

interface SS {
  identifier: string;
}

export default class CustomerController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactApiCallId: string | undefined;
  deleteCustomerApiCallId: string | undefined;
  searchCustomerApiCallId: string | undefined;

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactApiCallId = "";
    this.deleteCustomerApiCallId = "";
    this.searchCustomerApiCallId = "";

    this.state = {
      token: "",
      customerList: [],
      loading: true,
      searchBy: "",
      showList: true,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
    }
    const messageID = message.id;
    const data = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    runEngine.debugLog("API Message Received", message);

    if (messageID === getName(MessageEnum.SessionResponseMessage)) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      if (this.state.searchBy === '') {
        this.getCustomerList(token);
      }
    } else if (messageID === getName(MessageEnum.RestAPIResponceMessage)) {
      if (apiRequestCallId === this.contactApiCallId) {
        const contactData = data.data;
        this.handleCustomerAPIResponse(contactData);
      } else if (apiRequestCallId === this.searchCustomerApiCallId) {
        const contactData = data.data;
        if (contactData) {
          this.handleCustomerAPIResponse(contactData);
        }
        else {
          this.setState({ showList: false });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };
  
  download = () => {
    window.open("https://ssserviceinternal-365523-ruby.b365523.dev.eastus.az.svc.builder.cafe//bx_block_appointment_management/personal_details/download_csv.csv", "_blank", "noopener");
  }

  getCustomerList = async (token: string) => {
    const requestMessage = await generateRequestMessage(
      configJSON.getCustomerAPiEndPoint,
      configJSON.httpGetMethod
    )
    this.contactApiCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCustomerAPIResponse(data: CustomCustomer[]) {
    this.setState({ customerList: data, loading: false });
  }

  searchCustomerList = async (value: string) => {
    const requestMessage = await generateRequestMessage(
      configJSON.searchCustomerApiEndPoint + `?search=${value}`,
      configJSON.httpGetMethod
    )
    this.searchCustomerApiCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleFilterCustomerList = (event: any) => {
    const searchText = event.target.value.toLowerCase();

    if (searchText !== this.state.searchBy) {
      this.setState({ searchBy: searchText, showList: true });
      this.searchCustomerList(searchText);
    } else {
      this.setState({ searchBy: "", showList: true });
      this.getCustomerList(this.state.token);
    }
  }

  btnBackProps = () => {
    this.props.navigation.goBack()
  }
  // Customizable Area End
}
