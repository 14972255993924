// Customizable Area Start
export const deleteIcon = require("../assets/delete.png");
export const checkedIcon = require("../assets/checked.png");
export const uncheckedIcon = require("../assets/unchecked.png");
export const nodataIcon = require("../assets/no-data.png");
export const builderStudioStore = require('../assets/builder-studio-store.png');
export const closeIcon = require('../assets/cross.png');
export const gridBtnShow = require('../assets/grid-show.png');
export const listBtnHide = require('../assets/list-hide.png');
export const gridBtnHide = require('../assets/grid-hide.png');
export const listBtnShow = require('../assets/list-show.png');
export const editIcon = require('../assets/editIcon.png');
export const addIcon = require('../assets/addIcon.png');
export const arrowIconBtn = require('../assets/arrowIcon.png');
export const tickIcon = require('../assets/tick.png');
// Customizable Area End