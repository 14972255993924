// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Card,
  withStyles,
  Grid,
  Typography,
  Divider,
  TextField,
  FormHelperText,
  TextareaAutosize,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  Dialog,
  DialogContentText,
  DialogActions,
  DialogContent,
  CardContent,
  Collapse,
  CardActions,
  IconButtonProps,
  CircularProgress,
  DialogTitle,
} from "@material-ui/core";
import { createTheme, styled, Theme } from "@material-ui/core/styles";
import AppointmentmanagementController, { Props, Values, Address } from "./AppointmentmanagementController";
import * as Yup from "yup";
import Calendar from "react-calendar";
import "./cal.css";
import AppointmentStiper from "./AppointmentStiper";
import moment from "moment";
import Confirmation from "./Confirmation.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import "react-calendar/dist/Calendar.css";
import { ArrowBackIcon, ArrowForwardIcon, ArrowIcon } from "./assets";
import Currency from "../../utilities/src/components/Currency.web";
import StripePayments from "../../stripepayments/src/StripePayments.web";
import StripeSuccessModal from "../../stripepayments/src/StripeSuccessModal.web";
import CcavenueModal from "../../ccavenueintegration/src/CcavenueModal.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3FC1CB",
    },
  },
});

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}


const webStyles = (theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    height: "100vh",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    padding: "40px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      margin: "auto",
      width: "100%"
    },
  },
  card1: {
    display: "flex",
    padding: "40px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  card2: {
    flexBasis: "40%",
  },
  customSelectedDay: {
    backgroundColor: "#3FC1CB",
    color: "white"
  },
  calender: { color: "black" },
  wrapper: {
    maxWidth: "718px",
    margin: "30px auto 60px auto",
    padding: "0px 30px",
    [theme.breakpoints.down("sm")]: {
      margin: "20px 5px",
      padding: "0px"
    },
  },
  timeSlot: {
    border: "1px solid",
    padding: "10px",
    flexGrow: 1,
    fontSize: "12px",
    fontFamily: "Rubik",
    lineHeight: "20px",
    fontWeight: 500,
    borderRadius: "3px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 2px",
    },
  },
  dataAndTimeHeading: {
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 500,
    color: "#0000000",
    padding: "40px 30px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 10px",
    },
  },
  containerBox: {
    maxWidth: "1440px",
    margin: "40px auto",
  },
  selectedDate: {
    backgroundColor: "#3FC1CB !important",
    color: "#FFF !important",
    borderRadius: "50% !important",
    width: "3em !important",
    height: "3em !important",
  },
  proceedBtn: {
    color: "white",
    width: "100%",
    height: "48px",
    margin: "auto",
    borderRadius: "5px",
    marginTop: "20px",
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px"
  },
  btnEnable: {
    backgroundColor: "#3FC1CB",
  },
  btnDisable: {
    backgroundColor: "#D0D2DA",
  },
  inputLabel: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    paddingBottom: "4px",
    fontFamily: "Rubik",
  },
  appointmentHeading: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    color: "#000000",
    marginTop: "10px",
    fontFamily: "Rubik",
    marginBottom: "25px",
  },
  paymentHeading: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    color: "#000000",
    marginTop: "10px",
    fontFamily: "Rubik",
    marginBottom: "20px",
  },
  inputError: {
    color: "#f44336",
    marginLeft: "14px",
    marginRight: "14px",
  },
  formContainer: {
    marginTop: "2rem",
    marginBottom: "5rem"
  },
  headingSection: {
    padding: "0px 40px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 14px",
    },
  },
  cancelText: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "14px",
    color: "#3FC1CB",
    marginLeft: "5px",
    lineHeight: "24px",
    cursor: "pointer"
  },
  arrowIcon: {
    color: "#3FC1CB",
    width: "9px",
    height: "9px",
    cursor: "pointer"
  },
  headingText: {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "28px",
    color: "#3C3E49",
    margin: "20px 0px",
    lineHeight: "32px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "28px",
    },
  },
  appointmentText: {
    fontFamily: "Rubik,",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0px",
    color: "#000000",
    marginBottom: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  serviceText: {
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0px",
    color: "#757575",
    marginBottom: "16px"
  },
  appoinmentsubContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px"
  },
  divider: {
    borderColor: "#BFBFBF",
    marginBottom: "16px"
  },
  bottomSection: {
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  titleSection: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#000000"
  },
  appoinmentDate: {
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0px",
    color: "#757575",
    marginBottom: "16px"
  },
  comments: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#8C8C8C",
    fontStyle: "italic"
  },
  textLimit: {
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#000000",
  },
  dialogContent: {
    color: "#8C8C8C",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "400 !important" as "bold",
    width: "65%",
    margin: "auto",
  },
  dialogButton: {
    color: "#000000",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400
  },
  dialogCancel: {
    color: "#8C8C8C",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    padding: "0px",
    marginBottom: "16px"
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  collapseCard: {
    padding: "8px 0px !important"
  },
  availableSlot: {
    width: "100%",
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "25px",
    },
  },
  calendarArrowIcon: {
    width: "13.49px !important",
    height: "12.59px !important",
    color: "#676B7E !important"
  }
});

const webStyle = {
  field: {
    fontFamily: "Rubik,",
    padding: "12px 16px",
    fontSize: "14px",
    borderRadius: "3px",
    lineHeight: "24px",
    height: "auto"
  },
}

const AppointmentSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(4, "Name must be minimum 4 characters")
    .max(50, "Name must be maximum 50 characters")
    .matches(/^[a-zA-Z\s]+$/, "Name should contain only alphabets"),
  email: Yup.string().required("Email is required.").email("Please enter valid email"),
  phoneNumber: Yup.string()
    .required("Mobile Number is required")
    .min(10, "Mobile Number is invalid")
    .max(13, "Mobile Number is invalid"),
  comments: Yup.string()
    .max(100, "Comments must be maximum 100 characters"),
});


const AddressSchema = Yup.object().shape({
  country: Yup.string()
    .required("Country is required")
    .min(3, "Country must be minimum 4 characters")
    .max(50, "Country must be maximum 50 characters")
    .matches(/^[a-zA-Z\s]+$/, "Name should contain only alphabets"),
  city: Yup.string().required("City is required.").matches(/^[a-zA-Z\s]+$/, "City should contain only alphabets"),
  addr1: Yup.string().required("Address line 1 is required"),
  zip: Yup.string()
    .required("Zip code is required")
});

export class Appointments extends AppointmentmanagementController {
  constructor(props: Props) {
    super(props);
    this.handleResize = this.handleResize.bind(this);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.getCatalgoue();
    this.setState({
      selectedDate: new Date(),
      serviceId: this.props.navigation.getParam("id")
    }, () => this.getAppointmentList(this.state.token));
  }

  handleDateChange = (date: any) => {
    this.setState({
      selectedDate: new Date(date),
      serviceId: this.props.navigation.getParam("id")
    }, () => this.getAppointmentList(this.state.token));
  };

  async componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({ screenSize: window.innerWidth });
  }

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };
  dateIsDisabled: any = ({ date, view }: any) => {
    const currentDate = moment().add(new Date().getTimezoneOffset() + this.state.timeZoneOffset, "m");
    return view === "month" && date < currentDate && !moment(date).isSame(currentDate, "day");
  };

  convertTimeFormat(timeString: string): string {
    return moment(timeString, "HH:mm").format("h:mm A");
  }


  formatDate = (dateStr: string) => {
    const newDate = moment(dateStr, "DD-MM-YYYY").format("DD-MM-YYYY");
    const [day, month, year] = newDate.split("-");
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const date = new Date(Number(year), Number(month) - 1, Number(day));

    const daySuffix = (num: number) => {
      if (num >= 11 && num <= 13) {
        return "th";
      }
      switch (num % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
      }
    };

    const formattedDate = `${date.toLocaleString("en-US", { weekday: "long" })}, ${date.getDate()}${daySuffix(date.getDate())} ${months[date.getMonth()]} ${date.getFullYear()}`;
    return formattedDate;
  }

  handleChange = (field: keyof Values) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target;

    const characterCount = field === "comments" ? value.length : this.state.characterCount;
    try {
      const fieldValues: Partial<Values> = {
        [field]: value,
      };
      AppointmentSchema.validateSyncAt(field, fieldValues as Values);
      this.setState((prevState) => ({
        ...prevState,
        [field]: value,
        characterCount: characterCount,
        personalDetailsData: this.state.personalDetailsData,
        errors: { ...prevState.errors, [field]: "" },
      }));
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        this.setState((prevState) => ({
          ...prevState,
          [field]: value,
          characterCount: characterCount,
          errors: { ...prevState.errors, [field]: err.message },
        }));
      }
    }
  };

  handlePaymentChange = (field: keyof Address) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target;

    if (field === "country" || field === "city" || field === "addr1" || field === "zip") {
      try {
        const fieldValues: Partial<Address> = {
          [field]: value,
        };
        AddressSchema.validateSyncAt(field, fieldValues as Address);
        this.setState((prevState) => ({
          ...prevState,
          [field]: value,
          addressError: { ...prevState.addressError, [field]: "" },
        }));
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          this.setState((prevState) => ({
            ...prevState,
            [field]: value,
            isDisabled: true,
            addressError: { ...prevState.addressError, [field]: err.message },
          }));
        }
      }
    } else {
      this.setState((prevState) => ({
        ...prevState,
        [field]: value,
        personalDetailsData: { ...this.state.personalDetailsData, [field]: value },
        addressError: { ...prevState.addressError, [field]: "" },
      }));
    }
  };

  handleOpenDialog = () => {
    const { country, addr1, city, zip } = this.state;

    if (!country || !addr1 || !city || !zip) {
      this.setState({
        addressError: {
          country: !country ? "Country is required" : "",
          addr1: !addr1 ? "Address line 1 is required" : "",
          city: !city ? "City is required" : "",
          zip: !zip ? "Zip code is required" : ""
        }
      });
      return;
    } else {
      this.setState({
        openDialog: true, addressError: {}
      });
    }
  };

  expandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }: { theme: Theme; expand: boolean }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  }));

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
      <>
        {this.state.bookingData === null ? (
          <>
            {this.state.activeStep === 0 && (
              <Box className={classes.containerBox}>
                <>
                  <Box className={classes.headingSection}>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <img src={ArrowIcon} alt="icon" className={classes.arrowIcon} onClick={this.btnBackProps} />
                      <Typography className={classes.cancelText} onClick={this.btnBackProps}>Cancel, back to all services</Typography>
                    </Box>
                    <Typography className={classes.headingText}>Book appointment</Typography>
                    <Box>
                      <AppointmentStiper navigation={undefined} id={""} activeStep={this.state.activeStep} />
                    </Box>
                  </Box>
                  <Grid container className={classes.cardContainer} spacing={2}>
                    <Grid item xs={12} sm={8}>
                      <Card className={{ ...classes.card1, flexDirection: "column" }}>
                        <Box className={classes.dataAndTimeHeading} >Date & time</Box>
                        <Box style={{ margin: "auto" }}>
                          {this.state.selectedDate && <Calendar
                            data-testid="txtInputAvailableDate"
                            value={this.state.selectedDate}
                            onChange={this.handleDateChange}
                            tileDisabled={this.dateIsDisabled}
                            next2Label={null}
                            prev2Label={null}
                            nextLabel={<img src={ArrowForwardIcon} alt="icon" className={classes.calendarArrowIcon} />}
                            prevLabel={<img src={ArrowBackIcon} alt="icon" className={classes.calendarArrowIcon} />}
                          />}
                        </Box>
                        <Box className={classes.wrapper}>
                          {this.state.appointmentsList.length > 0 ? (
                            <>
                              <Grid container>
                                <Box className={classes.availableSlot} style={{ textAlign: "center" }}>
                                  <Typography style={{ fontFamily: "Rubik,", fontWeight: 600, fontSize: "14px", color: "#3C3E49" }}>Available times (in {this.state.timeZone})</Typography>
                                </Box>
                              </Grid>
                              <Grid container spacing={2}>
                                {this.state.appointmentsList.map((slot: { id: string | number | undefined; slot_start_time: React.ReactNode; is_available: boolean }) => (
                                  <Grid item xs={3} sm={3} md={3} key={slot.id}>
                                    <Box
                                      data-testid="selectSlot"
                                      className={classes.timeSlot}
                                      style={{
                                        border: `1px solid ${slot.is_available ? (slot.id === this.state.selectedSlot?.id ? "#0097A7" : "#3C3E49") : "#AFB2C0"}`,
                                        backgroundColor: slot.id === this.state.selectedSlot?.id ? "#E0F7FA" : "transparent",
                                        color: slot.id === this.state.selectedSlot?.id ? "#0097A7" : (slot.is_available ? "#3C3E49" : "#AFB2C0"),
                                        textAlign: "center",
                                        cursor: slot.is_available ? (slot.id === this.state.selectedSlot?.id ? "pointer" : "default") : "not-allowed",
                                      }}
                                      onClick={() => slot.is_available && this.handelAvaibilityTimeSlot(slot)}
                                    >
                                      {this.convertTimeFormat(typeof slot.slot_start_time === "string" ? slot.slot_start_time : "")}
                                    </Box>
                                  </Grid>
                                ))}
                              </Grid>
                            </>
                          ) : (
                            <Box style={{ textAlign: "center", width: "100%", marginBottom: "50px" }}>
                              {this.state.loading ? <CircularProgress /> : (
                                <Typography style={{ fontFamily: "Rubik", fontWeight: 400, fontSize: "14px" }}>No slots available</Typography>
                              )}
                            </Box>
                          )}
                        </Box>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {this.state.screenSize > 599 ? (
                        <Card className={classes.card2}>
                          <Box style={{ padding: "30px" }}>
                            <Typography variant="h6" className={classes.appointmentText} style={{ textAlign: "left" }}>
                              Appointment summary
                            </Typography>

                            <Typography variant="h6" className={classes.serviceText} style={{ textAlign: "left" }}>
                              Service
                            </Typography>

                            <Box className={classes.appoinmentsubContainer}>
                              <Typography variant="body1" className={classes.titleSection}>{this.state.service?.title}, {this.state.service?.duration} mins</Typography>
                              <Typography variant="body1" className={classes.titleSection}><Currency text={`${this.state.service?.discountedPrice?.toFixed(2)}`} /></Typography>
                            </Box>
                            <Divider className={classes.divider} />
                            <Box className={classes.appoinmentsubContainer}>
                              <Typography variant="body1" className={classes.bottomSection}>Total</Typography>
                              <Typography variant="body1" className={classes.bottomSection}><Currency text={`${this.state.service?.discountedPrice?.toFixed(2)}`} /></Typography>
                            </Box>
                          </Box>
                        </Card>) : (
                        <Card style={{ maxWidth: "1923px" }}>
                          <Box style={{ padding: "30px" }}>
                            <CardActions disableSpacing className={classes.collapseCard}>
                              <Typography variant="h6" className={classes.appointmentText}>
                                Appointment summary
                              </Typography>
                              <IconButton
                                className={clsx(classes.expand, {
                                  [classes.expandOpen]: this.state.expanded,
                                })}
                                onClick={this.handleExpandClick}
                                aria-expanded={this.state.expanded}
                                aria-label="show more"
                              >
                                <ExpandMoreIcon />
                              </IconButton>
                            </CardActions>
                            <Collapse in={this.state.expanded} timeout="auto" unmountOnExit >
                              <CardContent className={classes.collapseCard}>
                                <Typography
                                  variant="h6"
                                  className={classes.serviceText}
                                  style={{ textAlign: "left" }}
                                >
                                  Service
                                </Typography>

                                <Box className={classes.appoinmentsubContainer}>
                                  <Typography variant="body1" className={classes.titleSection}>
                                    {this.state.service?.title}, {this.state.service?.duration} mins
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    className={classes.titleSection}
                                  ><Currency text={`${this.state.service?.discountedPrice?.toFixed(2)}`} /></Typography>
                                </Box>
                              </CardContent>
                            </Collapse>
                            <Box className={classes.appoinmentsubContainer}>
                              <Typography variant="body1" className={classes.bottomSection}>Total</Typography>
                              <Typography variant="body1" className={classes.bottomSection}><Currency text={`${this.state.service?.discountedPrice?.toFixed(2)}`} /></Typography>
                            </Box>
                          </Box>
                        </Card>
                      )}
                      <Button data-testid="step-0" variant="contained" disabled={this.state.isDisabled} className={`${classes.proceedBtn} ${this.state.isDisabled ? classes.btnDisable : classes.btnEnable}`} onClick={this.handelProceedFromDateTime} >Proceed</Button>
                    </Grid>
                  </Grid>
                </>
              </Box>
            )}
            {this.state.activeStep === 1 && (
              <>
                <Box className={classes.containerBox}>
                  <>
                    <Box className={classes.headingSection}>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <img src={ArrowIcon} alt="icon" className={classes.arrowIcon} onClick={this.handleBack} />
                        <Typography className={classes.cancelText} onClick={this.handleBack}>Cancel, back to all services</Typography>
                      </Box>
                      <Typography className={classes.headingText}>Book appointment</Typography>
                      <Box>
                        <AppointmentStiper navigation={undefined} id={""} activeStep={this.state.activeStep} />
                      </Box>
                    </Box>
                    <Grid container className={classes.cardContainer} spacing={2}>
                      <Grid item xs={12} sm={8}>
                        <Card
                          style={{
                            padding: "20px"
                          }}
                        >
                          <Typography
                            variant="h4"
                            component="h1"
                            align="left"
                            gutterBottom
                            className={classes.appointmentHeading}
                          >
                            Personal details
                          </Typography>

                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                              <Box mb={2}>
                                <Typography className={classes.inputLabel}>Name *</Typography>
                                <TextField
                                  data-testid="txtName"
                                  name="name"
                                  value={this.state.name}
                                  placeholder="Name"
                                  onChange={this.handleChange("name")}
                                  variant="outlined"
                                  fullWidth
                                  inputProps={{
                                    style: webStyle.field
                                  }}
                                  error={!!this.state.errors.name}
                                />
                                {this.state.errors.name && (
                                  <FormHelperText className={classes.inputError}>
                                    {this.state.errors.name}
                                  </FormHelperText>
                                )}
                              </Box>

                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Box mb={2}>
                                <Typography className={classes.inputLabel}>Email*</Typography>
                                <TextField
                                  data-testid="txtEmail"
                                  name="email"
                                  type="email"
                                  placeholder="Email"
                                  value={this.state.email}
                                  onChange={this.handleChange("email")}
                                  variant="outlined"
                                  fullWidth
                                  inputProps={{
                                    style: webStyle.field
                                  }}
                                  error={!!this.state.errors.email}
                                />
                                {this.state.errors.email && (
                                  <FormHelperText className={classes.inputError}>
                                    {this.state.errors.email}
                                  </FormHelperText>
                                )}
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Box mb={2}>
                                <Typography className={classes.inputLabel}>
                                  Mobile *
                                </Typography>
                                <TextField
                                  data-testid="txtPhoneNumber"
                                  name="phoneNumber"
                                  placeholder="Mobile"
                                  value={this.state.phoneNumber}
                                  onChange={this.handleChange("phoneNumber")}
                                  variant="outlined"
                                  fullWidth
                                  inputProps={{
                                    style: webStyle.field
                                  }}
                                  error={!!this.state.errors.phoneNumber}
                                />
                                {this.state.errors.phoneNumber && (
                                  <FormHelperText className={classes.inputError}>
                                    {this.state.errors.phoneNumber}
                                  </FormHelperText>
                                )}
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Box mb={2}>
                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                  <Typography className={classes.inputLabel}>
                                    Comments
                                  </Typography>
                                  <Typography className={classes.textLimit}>{this.state.characterCount}/100</Typography>
                                </Box>
                                <TextareaAutosize
                                  data-testid="txtMessage"
                                  name="comments"
                                  placeholder="Comments"
                                  value={this.state.comments}
                                  onChange={this.handleChange("comments")}
                                  minRows={6}
                                  style={{
                                    width: "100%",
                                    marginBottom: "20px",
                                    ...webStyle.field,
                                    resize: "none",
                                  }}
                                />
                                {this.state.errors.comments && (
                                  <FormHelperText className={classes.inputError}>
                                    {this.state.errors.comments}
                                  </FormHelperText>
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        {this.state.screenSize > 599 ? (
                          <Card style={{ padding: "40px 30px" }} className={classes.card2} >
                            <Typography variant="h6" className={classes.appointmentText} style={{ textAlign: "left" }}>
                              Appointment summary
                            </Typography>

                            <Typography variant="h6" className={classes.serviceText} style={{ textAlign: "left" }}>
                              Service
                            </Typography>

                            <Box className={classes.appoinmentsubContainer}>
                              <Typography variant="body1" className={classes.titleSection}>{this.state.service?.title}, {this.state.service?.duration} mins</Typography>
                              <Typography variant="body1" className={classes.titleSection}><Currency text={`${this.state.service?.discountedPrice?.toFixed(2)}`} /></Typography>
                            </Box>
                            <Divider className={classes.divider} />
                            <Typography variant="h6" className={classes.serviceText} style={{ textAlign: "left" }}>
                              Date & time
                            </Typography>

                            <Box sx={{ marginBottom: "16px" }}>
                              <Typography variant="body1" className={classes.titleSection}>{this.formatDate(this.state.selectedDate)}</Typography>
                              <Typography variant="body1" className={classes.titleSection}>
                                {this.convertTimeFormat(`${this.state.selectedSlot.slot_start_time} `) + " " + `(${this.state.timeZone})`}
                              </Typography>

                            </Box>
                            <Divider className={classes.divider} />
                            <Box className={classes.appoinmentsubContainer}>
                              <Typography variant="body1" className={classes.bottomSection}>Total</Typography>
                              <Typography variant="body1" className={classes.bottomSection}><Currency text={`${this.state.service?.discountedPrice?.toFixed(2)}`} /></Typography>
                            </Box>
                          </Card>
                        ) : (
                          <Card style={{ maxWidth: "1923px" }}>
                            <Box style={{ padding: "30px" }}>
                              <CardActions disableSpacing className={classes.collapseCard}>
                                <Typography variant="h6" className={classes.appointmentText} style={{ textAlign: "left" }}>
                                  Appointment summary
                                </Typography>
                                <IconButton
                                  className={clsx(classes.expand, {
                                    [classes.expandOpen]: this.state.expanded,
                                  })}
                                  onClick={this.handleExpandClick}
                                  aria-expanded={this.state.expanded}
                                  aria-label="show more"
                                >
                                  <ExpandMoreIcon />
                                </IconButton>
                              </CardActions>
                              <Collapse in={this.state.expanded} timeout="auto" unmountOnExit >
                                <CardContent className={classes.collapseCard}>
                                  <Typography variant="h6" className={classes.serviceText} style={{ textAlign: "left" }}>
                                    Service
                                  </Typography>

                                  <Box className={classes.appoinmentsubContainer}>
                                    <Typography variant="body1" className={classes.titleSection}>{this.state.service?.title}, {this.state.service?.duration} mins</Typography>
                                    <Typography variant="body1" className={classes.titleSection}><Currency text={`${this.state.service?.discountedPrice?.toFixed(2)}`} /></Typography>
                                  </Box>
                                  <Divider className={classes.divider} />
                                  <Typography variant="h6" className={classes.serviceText} style={{ textAlign: "left" }}>
                                    Date & time
                                  </Typography>

                                  <Box sx={{ marginBottom: "16px" }}>
                                    <Typography variant="body1" className={classes.titleSection}>{this.formatDate(this.state.selectedDate)}</Typography>
                                    <Typography variant="body1" className={classes.titleSection}>
                                      {this.convertTimeFormat(`${this.state.selectedSlot.slot_start_time} `) + " " + `(${this.state.timeZone})`}
                                    </Typography>

                                  </Box>
                                </CardContent>
                              </Collapse>
                              <Box className={classes.appoinmentsubContainer}>
                                <Typography variant="body1" className={classes.bottomSection}>Total</Typography>
                                <Typography variant="body1" className={classes.bottomSection}><Currency text={`${this.state.service?.discountedPrice?.toFixed(2)}`} /></Typography>
                              </Box>
                            </Box>
                          </Card>
                        )}
                        <Button data-testid="step-1" variant="contained" className={`${classes.proceedBtn} ${classes.btnEnable}`} onClick={this.handleProceedToPersonalDetails} >Proceed</Button>
                      </Grid>
                    </Grid>
                  </>
                </Box>
              </>
            )}
            {this.state.activeStep === 2 && (
              <Box>
                <Box className={classes.containerBox}>
                  <>
                    <Box className={classes.headingSection}>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <img src={ArrowIcon} alt="icon" className={classes.arrowIcon} onClick={this.handleBack} />
                        <Typography className={classes.cancelText} onClick={this.handleBack}>Cancel, back to all services</Typography>
                      </Box>
                      <Typography className={classes.headingText}>Book appointment</Typography>
                      <Box>
                        <AppointmentStiper navigation={undefined} id={""} activeStep={this.state.activeStep} />
                      </Box>
                    </Box>
                    <Grid container className={classes.cardContainer} spacing={3}>
                      <Grid item xs={12} sm={8}>
                        <Card style={{
                          padding: "20px",
                          marginBottom: "20px"
                        }}>
                          <Typography
                            variant="h4"
                            component="h1"
                            align="left"
                            gutterBottom
                            className={classes.paymentHeading}
                          >
                            Payment location
                          </Typography>

                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="paymentOption"
                              name="paymentOption"
                              value={this.state.paymentOption}
                              onChange={this.handlePaymentOptionChange}
                            >
                              <FormControlLabel
                                value="pay_online"
                                control={
                                  <Radio
                                    style={{
                                      color: ["pay_online_or_in_person", "pay_online"].includes(this.state.paymentPreference) ? "#00D659" : "#BFBFBF"
                                    }}
                                    checked={this.state.paymentOption === "pay_online"}
                                    disabled={["pay_in_person"].includes(this.state.paymentPreference)}
                                    onClick={() => this.setState({ paymentOption: "pay_online" })}
                                  />
                                }
                                label="Pay now"
                              />
                              <FormControlLabel
                                value="pay_in_person"
                                control={
                                  <Radio
                                    style={{
                                      color: ["pay_online_or_in_person", "pay_in_person"].includes(this.state.paymentPreference) ? "#00D659" : "#BFBFBF"
                                    }}
                                    checked={this.state.paymentOption === "pay_in_person"}
                                    disabled={["pay_online"].includes(this.state.paymentPreference)}
                                    onClick={() => this.setState({ paymentOption: "pay_in_person" })}
                                  />
                                }
                                label="Pay later at location"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Card>
                        <Card

                          style={{
                            padding: "20px"
                          }}
                        >
                          <Typography
                            variant="h4"
                            component="h1"
                            align="left"
                            gutterBottom
                            className={classes.appointmentHeading}
                          >
                            Address
                          </Typography>

                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                              <Box mb={2}>
                                <Typography className={classes.inputLabel}>Country *</Typography>
                                <TextField
                                  data-testid="txtCountry"
                                  name="country"
                                  placeholder="Country"
                                  value={this.state.country}
                                  onChange={this.handlePaymentChange("country")}
                                  variant="outlined"
                                  fullWidth
                                  inputProps={{
                                    style: webStyle.field
                                  }}
                                  error={!!this.state.addressError.country}
                                />
                                {this.state.addressError.country && (
                                  <FormHelperText className={classes.inputError}>
                                    {this.state.addressError.country}
                                  </FormHelperText>
                                )}
                              </Box>

                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Box mb={2}>
                                <Typography className={classes.inputLabel}>Flat / House / Apartment No.</Typography>
                                <TextField
                                  data-testid="txtHouseNo"
                                  name="houseNo"
                                  placeholder="Flat / House / Apartment No"
                                  value={this.state.houseNo}
                                  onChange={this.handlePaymentChange("houseNo")}
                                  variant="outlined"
                                  fullWidth
                                  inputProps={{
                                    style: webStyle.field
                                  }}
                                  error={!!this.state.addressError.houseNo}
                                />
                                {this.state.addressError.houseNo && (
                                  <FormHelperText className={classes.inputError}>
                                    {this.state.addressError.houseNo}
                                  </FormHelperText>
                                )}
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Box mb={2}>
                                <Typography className={classes.inputLabel}>
                                  Address line 1 *
                                </Typography>
                                <TextField
                                  data-testid="txtAddr1"
                                  name="addr1"
                                  placeholder="Address line 1"
                                  value={this.state.addr1}
                                  onChange={this.handlePaymentChange("addr1")}
                                  variant="outlined"
                                  fullWidth
                                  inputProps={{
                                    style: webStyle.field
                                  }}
                                  error={!!this.state.addressError.addr1}
                                />
                                {this.state.addressError.addr1 && (
                                  <FormHelperText className={classes.inputError}>
                                    {this.state.addressError.addr1}
                                  </FormHelperText>
                                )}
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Box mb={2}>
                                <Typography className={classes.inputLabel}>
                                  Address line 2
                                </Typography>
                                <TextField
                                  data-testid="txtAddr2"
                                  name="addr2"
                                  placeholder="Address line 2"
                                  value={this.state.addr2}
                                  onChange={this.handlePaymentChange("addr2")}
                                  variant="outlined"
                                  fullWidth
                                  inputProps={{
                                    style: webStyle.field
                                  }}
                                  error={!!this.state.addressError.addr2}
                                />
                                {this.state.addressError.addr2 && (
                                  <FormHelperText className={classes.inputError}>
                                    {this.state.addressError.addr2}
                                  </FormHelperText>
                                )}
                              </Box>
                            </Grid>

                            <Grid item xs={6} sm={6}>
                              <Box mb={2}>
                                <Typography className={classes.inputLabel}>
                                  City *
                                </Typography>
                                <TextField
                                  data-testid="txtCity"
                                  name="city"
                                  placeholder="City"
                                  value={this.state.city}
                                  onChange={this.handlePaymentChange("city")}
                                  variant="outlined"
                                  fullWidth
                                  inputProps={{
                                    style: webStyle.field
                                  }}
                                  error={!!this.state.addressError.city}
                                />
                                {this.state.addressError.city && (
                                  <FormHelperText className={classes.inputError}>
                                    {this.state.addressError.city}
                                  </FormHelperText>
                                )}
                              </Box>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              <Box mb={2}>
                                <Typography className={classes.inputLabel}>
                                  State
                                </Typography>
                                <TextField
                                  data-testid="txtStateName"
                                  name="stateName"
                                  placeholder="State"
                                  value={this.state.stateName}
                                  onChange={this.handlePaymentChange("stateName")}
                                  variant="outlined"
                                  fullWidth
                                  inputProps={{
                                    style: webStyle.field
                                  }}
                                  error={!!this.state.addressError.stateName}
                                />
                                {this.state.addressError.stateName && (
                                  <FormHelperText className={classes.inputError}>
                                    {this.state.addressError.stateName}
                                  </FormHelperText>
                                )}
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Box mb={2}>
                                <Typography className={classes.inputLabel}>
                                  Zip code *
                                </Typography>
                                <TextField
                                  data-testid="txtZip"
                                  name="zip"
                                  placeholder="Zip code"
                                  value={this.state.zip}
                                  onChange={this.handlePaymentChange("zip")}
                                  variant="outlined"
                                  fullWidth
                                  inputProps={{
                                    style: webStyle.field
                                  }}
                                  error={!!this.state.addressError.zip}
                                />
                                {this.state.addressError.zip && (
                                  <FormHelperText className={classes.inputError}>
                                    {this.state.addressError.zip}
                                  </FormHelperText>
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        {this.state.screenSize > 599 ? (
                          <Card style={{ padding: "40px 30px" }} className={classes.card2}>
                            <Typography variant="h6" className={classes.appointmentText} style={{ textAlign: "left" }}>
                              Appointment summary
                            </Typography>
                            <Typography variant="h6" className={classes.serviceText} style={{ textAlign: "left" }}>
                              Service
                            </Typography>

                            <Box className={classes.appoinmentsubContainer}>
                              <Typography variant="body1" className={classes.titleSection}>{this.state.personalDetailsData.service?.title}, {this.state.personalDetailsData.service?.duration} mins</Typography>
                              <Typography variant="body1" className={classes.titleSection}><Currency text={`${this.state.personalDetailsData.service?.discountedPrice?.toFixed(2)}`} /></Typography>
                            </Box>
                            <Divider className={classes.divider} />
                            <Typography variant="h6" className={classes.appoinmentDate} style={{ textAlign: "left" }}>
                              Date & time
                            </Typography>

                            <Box sx={{ marginBottom: "16px" }}>
                              <Typography variant="body1" className={classes.titleSection}>{this.formatDate(this.state.personalDetailsData.selectedDate)}</Typography>
                              <Typography variant="body1" className={classes.titleSection}>
                                {this.convertTimeFormat(`${this.state.personalDetailsData.selectedSlot.slot_start_time} `) + " " + `(${this.state.personalDetailsData.timeZone})`}
                              </Typography>

                            </Box>
                            <Divider className={classes.divider} />
                            <Typography variant="h6" className={classes.appoinmentDate} style={{ textAlign: "left" }}>
                              Personal details
                            </Typography>

                            <Box sx={{ marginBottom: "16px" }}>
                              <Typography variant="body1" className={classes.titleSection}>{this.state.name}</Typography>
                              <Typography variant="body1" className={classes.titleSection}>{this.state.email} | {this.state.phoneNumber}</Typography>
                              <Typography variant="body1" className={classes.comments} style={{ wordWrap: "break-word", whiteSpace: "normal" }}>{this.state.comments}</Typography>
                            </Box>
                            <Divider className={classes.divider} />
                            <Box className={classes.appoinmentsubContainer}>
                              <Typography variant="body1" className={classes.bottomSection}>Total</Typography>
                              <Typography variant="body1" className={classes.bottomSection}><Currency text={`${this.state.personalDetailsData.service?.discountedPrice?.toFixed(2)}`} /></Typography>
                            </Box>
                          </Card>
                        ) : (
                          <Card style={{ maxWidth: "1923px" }}>
                            <Box style={{ padding: "30px" }}>
                              <CardActions disableSpacing className={classes.collapseCard}>
                                <Typography variant="h6" className={classes.appointmentText} style={{ textAlign: "left" }}>
                                  Appointment summary
                                </Typography>
                                <IconButton
                                  className={clsx(classes.expand, {
                                    [classes.expandOpen]: this.state.expanded,
                                  })}
                                  onClick={this.handleExpandClick}
                                  aria-expanded={this.state.expanded}
                                  aria-label="show more"
                                >
                                  <ExpandMoreIcon />
                                </IconButton>
                              </CardActions>
                              <Collapse in={this.state.expanded} timeout="auto" unmountOnExit >
                                <CardContent className={classes.collapseCard}>
                                  <Typography variant="h6" className={classes.serviceText} style={{ textAlign: "left" }}>
                                    Service
                                  </Typography>

                                  <Box className={classes.appoinmentsubContainer}>
                                    <Typography variant="body1" className={classes.titleSection}>{this.state.personalDetailsData.service?.title}, {this.state.personalDetailsData.service?.duration} mins</Typography>
                                    <Typography variant="body1" className={classes.titleSection}><Currency text={`${this.state.personalDetailsData.service?.discountedPrice?.toFixed(2)}`} /></Typography>
                                  </Box>
                                  <Divider className={classes.divider} />
                                  <Typography variant="h6" className={classes.appoinmentDate} style={{ textAlign: "left" }}>
                                    Date & time
                                  </Typography>

                                  <Box sx={{ marginBottom: "16px" }}>
                                    <Typography variant="body1" className={classes.titleSection}>{this.formatDate(this.state.personalDetailsData.selectedDate)}</Typography>
                                    <Typography variant="body1" className={classes.titleSection}>
                                      {this.convertTimeFormat(`${this.state.personalDetailsData.selectedSlot.slot_start_time} `) + " " + `(${this.state.personalDetailsData.timeZone})`}
                                    </Typography>
                                  </Box>
                                  <Divider className={classes.divider} />
                                  <Typography variant="h6" className={classes.appoinmentDate} style={{ textAlign: "left" }}>
                                    Personal details
                                  </Typography>

                                  <Box sx={{ marginBottom: "16px" }}>
                                    <Typography variant="body1" className={classes.titleSection}>{this.state.name}</Typography>
                                    <Typography variant="body1" className={classes.titleSection}>{this.state.email} | {this.state.phoneNumber}</Typography>
                                    <Typography variant="body1" className={classes.comments}>{this.state.comments}</Typography>
                                  </Box>
                                </CardContent>
                              </Collapse>
                              <Box className={classes.appoinmentsubContainer}>
                                <Typography variant="body1" className={classes.bottomSection}>Total</Typography>
                                <Typography variant="body1" className={classes.bottomSection}><Currency text={`${this.state.service?.discountedPrice?.toFixed(2)}`} /></Typography>
                              </Box>
                            </Box>
                          </Card>
                        )}
                        <Button data-testid="step-2" variant="contained" className={`${classes.proceedBtn} ${classes.btnEnable}`} onClick={this.handleOpenDialog}>Proceed</Button>
                      </Grid>
                    </Grid>
                    <Dialog open={this.state.openDialog} onClose={this.handleCloseDialog}>
                      <DialogContent style={{ padding: "40px", textAlign: "center" }}>
                        <DialogContentText className={classes.dialogContent}>
                          Are you sure you want to place the order?
                        </DialogContentText>
                      </DialogContent>
                      <Divider style={{ margin: "16px 0" }} />
                      <DialogActions style={{ display: "flex", justifyContent: "space-around" }}>
                        <Button onClick={this.handleCloseDialog} className={classes.dialogCancel} style={{ textTransform: "none" }}>
                          No
                        </Button>
                        <Divider orientation="vertical" flexItem style={{ margin: "0 8px" }} />
                        <Button onClick={this.handleProceedToPayment} className={classes.dialogButton} style={{ textTransform: "none" }}>
                          Yes
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Dialog
                      open={!!this.state.slotBookingErrorMessage}
                      aria-labelledby="dialog-title"
                      aria-describedby="dialog-description"
                    >
                      <DialogTitle id="dialog-title">Alert</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="dialog-description">
                          {this.state.slotBookingErrorMessage}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={this.closeAppointmentDialog} color="primary">
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                </Box>
              </Box>
            )}
          </>
        ) : (
          <>
            <Confirmation service={this.state.service} paymentOption={this.state.paymentOption} bookingData={this.state.bookingData} navigation={undefined} id={""} />
          </>
        )}
        <StripePayments
          stripeModalOpen={this.state.isOpenStripeModal}
          handleSubmit={this.handleCardData}
          handleClose={this.onCloseModal}
        />
        <StripeSuccessModal
          stripeSuccessData={this.state.stripeSuccessData}
          successModalOpen={this.state.stripeState}
        />
        <CcavenueModal onClose={this.onCloseModal} ccavenueModalShowRespons={this.state.ccavenueModalShowRespons} ccavenueModalOpen={this.state.ccavenueModalOpen} navigation={this.state.ccavenueModalOpen} id={""} />
      </>

    );
  }
  handleTimeClick(slot: string): void {
    throw new Error("Method not implemented.");
  }
}


export default withStyles(webStyles)(Appointments);
// Customizable Area End