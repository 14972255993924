import { Theme, makeStyles } from "@material-ui/core";

export const Catalogues = makeStyles((theme: Theme) => ({
  breadcrumbsWrapper: {
    margin: theme.spacing(5, 10, 1),
    [theme.breakpoints.down(1280)]: {
      margin: theme.spacing(2, 1.25, 1),
      display: "flex",
      justifyContent: "space-between",
    },
  },
  breadcrumbLink: {
    color: "#3FC1CB",
    textDecoration: "none",
  },
  breadcrumbText: {
    color: "#000000",
  },
  container: {
    display: "flex",
    gap: theme.spacing(3.5),
    margin: theme.spacing(0, 10),
    [theme.breakpoints.down(1280)]: {
      margin: theme.spacing(0),
    },
  },
  filterButton: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  filterIcon: {
    objectFit: "none",
  },
  filterButtonText: {
    fontSize: "12px",
    color: "#9B9FB1",
  },
  filter: {
    display: "flex",
    flex: "1 1 25%",
    height: "fit-content",
    paddingTop: theme.spacing(2),
  },
  noServicesCard: {
    flex: "1 1 75%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
    padding: theme.spacing(12.5),
    height: "394px",
  },
  loadingResults: {
    display: "flex",
    alignItems: "center",
  },
  loadingResultsText: {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#000000",
    whiteSpace: "pre",
  },
  notFoundImage: {
    width: "103px",
  },
  noResultsFound: {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#000000",
    marginTop: theme.spacing(5),
  },
  pleaseRefineYourSearch: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "#83889E",
    marginTop: theme.spacing(1),
  },
  resultsWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 75%",
  },
  chip: {
    alignSelf: "flex-start",
    minHeight: "32px",
    marginTop: theme.spacing(2),
    backgroundColor: "#FFFFFF",
    border: "1px solid #D0D2DA",
    fontSize: "14px",
    lineHeight: "24px",
    [theme.breakpoints.down(1280)]: {
      marginTop: theme.spacing(0),
      marginLeft: theme.spacing(1),
    },
  },
  deleteChipIcon: {
    width: "10px",
    height: "10px",
    color: "#D0D2DA",
    marginRight: "10px !important",
    marginLeft: theme.spacing(0),
  },
  listWrapper: {
    display: "flex",
    flex: "1 1 75%",
    justifyContent: "center",
  },
  list: {
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
    width: "100%",
    [theme.breakpoints.up(1440)]: {
      maxWidth: "1330px",
    },
  },
  item: {
    width: "calc(100% / 3)",
    [theme.breakpoints.down("xs")]: {
      width: "calc(100% / 2)",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.down(1280)]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  link: {
    textDecoration: "none",
    width: "100%",
    display: "flex",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    width: "424px",
    height: "352px",
    flex: "1",
    [theme.breakpoints.down(1280)]: {
      width: "170px",
      height: "311px",
    },
  },
  image: {
    height: "75%",
  },
  content: {
    flex: "1 1 auto",
  },
  name: {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#000000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down(1280)]: {
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
  brief: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "#000000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down(1280)]: {
      fontSize: "12px",
      lineHeight: "20px",
    },
  },
}));
