export interface PaymentInfo {
    // Customizable Area Start
    enc_resp: string;
    access_code: string;
    // Customizable Area End
}

export function generateHTML(paymentInfo: PaymentInfo | null) {
    // Customizable Area Start
    return `<!DOCTYPE html>
    <html lang="en">
    
    <head>
        <meta content='text/html; charset=UTF-8' http-equiv='Content-Type' />
        <title>Iframe</title>
    </head>
    
    <body>
        <iframe width="100%" height="500" style="border: none;" id="paymentFrame" title="CCAvenue Iframe"
            src="https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=${paymentInfo?.enc_resp}&access_code=${paymentInfo?.access_code}"></iframe>
    
        <script type="text/javascript" src="assets/javascripts/jquery-1.7.2.js"></script>
        <script type="text/javascript">
            $(document).ready(function(){
            $('iframe#paymentFrame').load(function() {
              window.addEventListener('message', function(e) {
                  $("#paymentFrame").css("height",e.data['newHeight']+'px');    
              }, false);
            }); 
          });
        </script>
    </body>
    
    </html>`;
    // Customizable Area End
}

