// Customizable Area Start
import { Theme, makeStyles } from "@material-ui/core";

export const Location = makeStyles((theme: Theme) => ({
  title: {
    fontSize: "32px",
    fontWeight: 500,
    lineHeight: "36px",
    letterSpacing: "3.2px",
    color: "#364F6B",
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      letterSpacing: "2.4px",
    },
  },
  titleDivider: {
    width: "90%",
    height: "3px",
    backgroundColor: "#3FC1CB",
    margin: "auto",
    opacity: 1,
  },
  locationWrapper: {
    width: "100%",
  },
  location: {
    display: "flex",
    padding: "60px 0",
    gap: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(5, 0, 2.25),
      flexWrap: "wrap",
    },
  },
  locationCard: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: theme.spacing(5),
    width: "30%",
    gap: theme.spacing(2.5),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
      padding: theme.spacing(2.5),
      order: 2,
    },
  },
  locationTitle: {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    paddingBottom: theme.spacing(1.5),
  },
  locationBody: {
    fontSize: "14px",
    lineHeight: "24px",
  },
  locationOpeningHours: {
    display: "flex",
  },
  locationOpeningHoursDay: {
    flex: "0 0 30%",
  },
  locationOpeningHoursTime: {
    fontVariantNumeric: "tabular-nums",
  },
  map: {
    width: "100%",
    height: "460px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: "250px",
      order: 1,
    },
  },
}));
// Customizable Area End