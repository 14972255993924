// Customizable Area Start
import React from "react";
import CataloguesController, { Props } from "./CataloguesController.web";
import Catalogues from "./Catalogues.web";

export default class ServicesWrapper extends CataloguesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Catalogues
        filterOptions={this.state.categories}
        cataloguesData={this.state.services}
        tempSelectedCategories={this.state.tempSelectedCategories}
        selectCategory={this.selectCategory}
        resetCategoriesSelected={this.resetCategoriesSelected}
        applyCategoriesSelected={this.applyCategoriesSelected}
        discardCategoriesSelected={this.discardCategoriesSelected}
        isLoading={this.state.loading}
        searchQuery={this.state.searchQuery}
        deleteChip={this.deleteChip}
      />
    );
  }
}
// Customizable Area End
