import React from "react";
// Customizable Area Start
import AppheaderController, { Props } from "./AppheaderController.web";
import Appheader from "./Appheader.web";
// Customizable Area End

export default class AppheaderWrapper extends AppheaderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Appheader
        logo={this.state.logo}
        storeName={this.state.storeName}
        confirmSearch={this.confirmSearch}
        searchQuery={this.state.searchQuery}
      />
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
