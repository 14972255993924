// Customizable Area Start
import React from "react";
import {
  Box,
  Divider,
  List,
  ListItem,
  Card,
  CardContent,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { Service } from "../../utilities/src/models/Service";
import { TopServices as useStyles } from "./styles/TopServices.web";
import { ServiceImage } from "../../utilities/src/models/ServiceImage";
import ImageCard from "../../utilities/src/components/ImageCard.web";
import Currency from "../../utilities/src/components/Currency.web";

interface Props {
  services: Service[];
}

const TopServices: React.FC<Props> = ({ services }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.header}>
        <Box className={classes.titleWrapper}>
          <Typography className={classes.title}>TOP SERVICES</Typography>
          <Divider className={classes.titleDivider} />
        </Box>
        <Link to="/Services" className={classes.viewAll}>
          <Typography>View all</Typography>
        </Link>
      </Box>

      <List className={classes.serviceList}>
        {services.map((service, index) => (
          <ListItem key={index} className={classes.serviceItem}>
            <Link className={classes.serviceLink} to={`/Service/${service.id}`}>
              <Card className={classes.serviceCard}>
                <ImageCard
                  imageClassName={classes.serviceImage}
                  image={(service.images[0] as ServiceImage).url}
                />
                <CardContent className={classes.serviceContent}>
                  <Typography className={classes.serviceName}>
                    {service.title}
                  </Typography>
                  <Typography className={classes.serviceBrief}>
                  <Currency text={`${service.discountedPrice.toFixed(2)}  |  ${
                      service.duration
                    } mins`}/>
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default TopServices;
// Customizable Area End