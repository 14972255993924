// Customizable Area Start
import React from "react";

export const FacebookIcon = require("../assets/facebook.svg");
export const LinkedinIcon = require("../assets/linkedin.svg");
export const TwitterIcon = require("../assets/twitter.svg");
export const PreviewIcon = require("../assets/preview_icon.png");

export interface DesktopIconProps {
  color?: string;
  size?: number;
  isActive?: boolean;
  className?: string;
  style?: object;
}

export function DesktopIcon(props: DesktopIconProps) {
  const { color = "#6200EA", size = 16, className, style } = props;

  return (
    <span style={style} className={className}>
      <svg
        width={size}
        height={(size * 17) / 16}
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5 15.88H5.5L6 13H10L10.5 15.88V15.88Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 15.8809H12"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.5 11.0801H15.5"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.5 4.48047C0.5 2.82362 1.84315 1.48047 3.5 1.48047H12.5C14.1569 1.48047 15.5 2.82361 15.5 4.48047V10.0005C15.5 11.6573 14.1569 13.0005 12.5 13.0005H3.5C1.84315 13.0005 0.5 11.6573 0.5 10.0005V4.48047Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}

export interface MobileIconProps {
  color?: string;
  size?: number;
  className?: string;
  style?: object;
}

export function MobileIcon(props: MobileIconProps) {
  const { color = "#9B9FB1", size = 16, className, style } = props;
  return (
    <span style={style} className={className}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.5 0.500976C11.1569 0.500977 12.5 1.84412 12.5 3.50098L12.5 12.501C12.5 14.1578 11.1569 15.501 9.5 15.501L6.5 15.501C4.84314 15.501 3.5 14.1578 3.5 12.501L3.5 3.50098C3.5 1.84412 4.84315 0.500976 6.5 0.500976L9.5 0.500976Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.5 12.501L12.5 12.501"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}

export interface TabletIconProps {
  color?: string;
  size?: number;
  className?: string;
  style?: object;
}

export function TabletIcon(props: TabletIconProps) {
  const { color = "#9B9FB1", size = 16, className, style } = props;
  return (
    <span style={style} className={className}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.49609 12.5H13.4961"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.49805 2C2.49805 1.17157 3.16962 0.5 3.99805 0.5H11.998C12.8265 0.5 13.498 1.17157 13.498 2V14C13.498 14.8284 12.8265 15.5 11.998 15.5H3.99805C3.16962 15.5 2.49805 14.8284 2.49805 14V2Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}
// Customizable Area End