// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import { showError, generateRequestMessage } from "../../ss-cms-common-components/src/Utilities/Utilities";
const configJSON = require("./config.js");

import { CCAvenuePayment as CCAvenuePaymentType } from "./Types";


export type Props = {
  showLoader:() => void;
  hideLoader: () => void;
  setDialogState: () => void;
  showHeader: (obj?: object) => void;
};

interface S {
  showActivateDialog: boolean;
  payment: CCAvenuePaymentType;
}

interface SS {
  
}

export default class CCAvenueCardController extends BlockComponent<Props, S, SS> {

 initMessageId: string = "";

 constructor(props: Props) {
   super(props);
   this.receive = this.receive.bind(this);
   this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
   this.state = {
     showActivateDialog: false,
     payment: {} as CCAvenuePaymentType
   };

   runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
   
 }

 receive = (from: string, message: Message) => {
   this.messageInit(message);
 }

 handleClick = () => {
   this.setState({
     showActivateDialog:  !this.state.showActivateDialog
   },()=>{
     this.getCCAvenueDetails();
   })
 };

 handleClipboard = (text?: string|null) => () => {
   navigator?.clipboard?.writeText?.(text ?? "")
 };

 messageInit = (message: Message) => {
   const [responseJson, apiRequestCallId] = getResponseVariables(message);
   if (this.initMessageId === apiRequestCallId) {
     if (responseJson.errors) {
       return this.handleError(configJSON.errorCardUpdate,responseJson);
     }
     this.setState({
       payment: responseJson.data
     });
     this.props.hideLoader();
   }
 };

 componentDidMount = async() => {
   this.getCCAvenueDetails();
 }

 handleError = (title: string, responseJson: object) => {
   showError(
     title,
     responseJson,
     this.props.hideLoader,
     this.props.setDialogState
   );
 };

 getCCAvenueDetails = async () => {
   const requestMessage = await generateRequestMessage(
     `${configJSON.paymentsURL}`,
     configJSON.getMethod
   );
   this.initMessageId = requestMessage.messageId;
   this.send(requestMessage);
 }
}
// Customizable Area End