// Customizable Area Start
export const pencil = require("../assets/pencil.svg");
export const chevron = require("../assets/chevron.svg");
export const image = require("../assets/image.svg");
export const image1 = require("../assets/image1.png");
export const arrowleft = require("../assets/arrowleft.svg");
export const uparrow = require("../assets/uparrow.svg");
export const cross = require("../assets/cross.svg");
export const builder = require("../assets/builder.svg");
export const downarrow = require("../assets/down-arrow.png");
// Customizable Area End