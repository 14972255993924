// Customizable Area Start
import React from "react"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import { TextInput } from "../../ss-cms-common-components/src/Inputs/Inputs.web"
import { Dialog } from "../../ss-cms-common-components/src/Dialog/Dialog"
import StripPaymentActivateDialogController from "./StripPaymentActivateDialogController.web"
const configJSON = require("./config.js")

export default class StripPaymentActivateDialog extends StripPaymentActivateDialogController {
  render() {
    const { open, setOpen, defaultValues } = this.props;
    const initialValues = {
      api_key: defaultValues?.attributes?.api_key || "",
      api_secret_key: defaultValues?.attributes?.api_secret_key || "",
      configuration_type: defaultValues?.attributes?.configuration_type || "Strip",
    }
    return (
      <Dialog
        open={open}
        setOpen={setOpen}
        onSubmit={this.handleSubmitDialog}
        title={configJSON.stripeIntegration}
        customContent
        okay="Save"
        containerClassName="payment-activate-dialog"
        titleClassName="title"
        ignoreClickAway
      >
        <Formik
          innerRef={this.formikRef}
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            api_key: Yup.string().required(configJSON.thisFieldIsRequired),
            api_secret_key: Yup.string().required(configJSON.thisFieldIsRequired),
          })}
          onSubmit={this.handleSubmit}
          enableReinitialize
        >
        {(formikProps) => (
            <Form
              autoComplete="off"
              noValidate
              className="form"
              translate={undefined}
            >
              <TextInput
                label={configJSON.labelApiKey}
                name="api_key"
                data-testid="api_key"
                required
                {...formikProps}
              />
              <TextInput
                label={configJSON.labelSecretApiKey}
                data-testid="api_secret_key"
                name="api_secret_key"
                required
                {...formikProps}
              />
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  }
}
// Customizable Area End
