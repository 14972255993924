// Customizable Area Start
import React from "react";
import DashboardController, { Props } from "./DashboardController.web";
import Dashboard from "./Dashboard.web";

export default class DashboardWrapper extends DashboardController {
  constructor(props: Props) {
    super(props);
  }
  render() {

    return (
      <Dashboard
        banners={this.state.banners}
        topServices={this.state.topServices}
        showTestimonials={this.state.showTestimonials}
        testimonials={this.state.testimonials}
        locationDetails={this.state.locationDetails}
      />
    );
  }
}
// Customizable Area End
