// Customizable Area Start
import React from "react";
import { Box, Grid, Card, Typography, Divider, Theme, createTheme, withStyles } from "@material-ui/core";
import AppointmentmanagementController, { Props } from "./AppointmentmanagementController";
import moment from "moment";
import Currency from "../../utilities/src/components/Currency.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3FC1CB",
    },
  },
});

const styles = (theme: Theme) => ({
  confirmationContainer: {
    margin: "40px 0px",
    [theme.breakpoints.down("sm")]: {
      margin: "40px 20px",
    }
  },
  textFirst: {
    fontWeight: 500,
    fontFamily: "Rubik",
    fontSize: "32px",
    lineHeight: "36px",
    color: "#364F6B",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    }
  },
  divider: {
    maxWidth: "160px",
    margin: "10px auto",
    marginBottom: "50px",
    backgroundColor: '#3FC1CB',
    height: 3,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "35px"
    }
  },
  textSecond: {
    fontWeight: 400,
    fontFamily: "Rubik",
    fontSize: "16px",
    lineHeight: "14px",
    marginBottom: "50px",
    color: "#3C3E49",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "20px",
      marginBottom: "35px",
    }
  },
  containerBox: {
    maxWidth: "628px",
    margin: "auto"
  },
  appointmentText: {
    maxWidth: "628px",
    margin: "auto",
    color: "#000000",
    fontWeight: 500,
    fontSize: "18px",
    fontFamily: "Rubik",
    lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  subText: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Rubik",
    color: "#3C3E49",
    lineHeight: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "16px",
      color: "#757575"
    }
  },
  serviceText: {
    fontWeight: 400,
    fontSize: "18px",
    fontFamily: "Rubik",
    color: "#757575",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  title: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Rubik",
    color: "#000000",
    lineHeight: "24px"
  },
  servicePrice: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Rubik",
    color: "#000000",
    lineHeight: "24px"
  },
  footer: {
    borderTop: "1px solid #DFDFDF",
  },
  footerContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "25px"
  },
  footerText: {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  sectionFirst: {
    borderTop: "1px solid #DFDFDF",
    marginTop: "50px",
    marginBottom: "20px",
  },
  subHeading: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "18px",
    marginTop: "20px",
    color: "#757575",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  content: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "18px",
    marginTop: "20px",
    color: "#757575"
  },
  subContainer: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "18px",
    marginTop: "20px",
    marginBottom: "20px",
    color: "#757575"
  },
  textConatiner: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "14px",
    color: "#000000",
    lineHeight: "24px"
  },
  comment: {
    fontSize: "14px",
    fontFamily: "Rubik",
    fontWeight: 400,
    fontStyle: "italic",
    color: "#8C8C8C",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    }
  },
  cardContainer: {
    padding: "40px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px"
    }
  }
})

function textToUtcOffsetMinutes(text: string) {
  const offsetRegex = /UTC([+-]\d+(:\d+)?)/;
  const offsetMatch = (text||"").match(offsetRegex);
  if (!offsetMatch) {
    return 0;
  }
  const offsetStr = offsetMatch[1];

  const offsetParts = offsetStr.split(':');
  const hours = parseInt(offsetParts[0]);
  const minutes = parseInt(offsetParts[1] || "0");

  const totalMinutes = hours * 60 + minutes;
  return totalMinutes
}

export class Confirmation extends AppointmentmanagementController {
  constructor(props: Props) {
    super(props);
  }

  formatDate = (dateStr: string) => {
    return moment(dateStr).format("MMMM D YYYY, HH:mm");
  }

  formatDT = (dateStr: string) => {
    const date = moment(dateStr);
    const dayOfWeek = date.format("dddd");
    const dayOfMonth = date.format("Do");
    const monthYear = date.format("MMMM YYYY");

    return `${dayOfWeek}, ${dayOfMonth} ${monthYear}`;
  }

  convertTimeFormat = (timeString: string) => {
    return moment(timeString, "HH:mm").format("hh:mm A");
  }

  render() {
    const { service, bookingData, paymentOption, classes } = this.props;
    let paymentMethod;

    if (paymentOption === "pay_in_person") {
      paymentMethod = "Pay later at location";
    } else if (paymentOption === "pay_online") {
      paymentMethod = "Online";
    } else {
      paymentMethod = "";
    }
    return (
      <>
        <Box className={classes.confirmationContainer}>
          <Box style={{ textAlign: "center" }}>
            <Typography
              className={classes.textFirst}
              style={{ textTransform: "uppercase" }}
            >
              THANK YOU, {bookingData?.attributes?.personal_detail.full_name}
            </Typography>
            <Divider className={classes.divider} />
            <Typography className={classes.textSecond}>
              Your booking is confirmed. We have sent a confirmation email to {bookingData?.attributes?.personal_detail.email}
            </Typography>
          </Box>
          <Box className={classes.containerBox}>
            <Card className={classes.cardContainer}>
              <Typography
                className={classes.appointmentText}
              >
                Appointment summary
              </Typography>
              <Grid container style={{ marginTop: "10px" }}>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography className={classes.subText}>Order ID: {bookingData?.attributes.order_id}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography className={classes.subText}>Order Date : {moment.utc(bookingData?.attributes.order_date).format("LLL")}</Typography>
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "20px" }} spacing={2}>
                <Grid item xs={4} sm={4} md={4}>
                  <img
                    src={service?.images[0].url}
                    alt="img"
                    width="100%"
                  />
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                  <Box>
                    <Typography className={classes.serviceText}>
                      Service
                    </Typography>
                    <Typography className={classes.title}>
                      {service?.title}, {service?.duration} mins
                    </Typography>
                    <Typography className={classes.servicePrice}>
                    <Currency text={service?.discountedPrice?.toFixed(2)}/>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box className={classes.sectionFirst}>
                <Typography className={classes.subHeading}>Appointment Date & Time</Typography>
                <Box style={{ marginTop: "10px" }}>
                  <Typography className={classes.textConatiner}>{moment.utc(bookingData?.attributes.appointment_date).format("dddd, Do MMMM YYYY | h:mm A (["+bookingData?.attributes.time_zone_short+"])")}</Typography>
                  

                </Box>
              </Box>
              <Divider />
              <Box className={classes.subContainer}>
                <Typography className={classes.subHeading}>Personal details</Typography>
                <Box style={{ marginTop: "10px" }}>
                  <Typography className={classes.textConatiner}>{bookingData?.attributes?.personal_detail.full_name}</Typography>
                  <Typography className={classes.textConatiner}>{bookingData?.attributes?.personal_detail.email} | {bookingData?.attributes?.personal_detail.full_phone_number} </Typography>
                  <Typography className={classes.comment}>{bookingData?.attributes?.personal_detail.comment}</Typography>
                </Box>
              </Box>
              <Divider />
              <Box className={classes.subContainer}>
                <Typography className={classes.subHeading}>Mode of Payment</Typography>
                <Box style={{ marginTop: "10px" }}>
                  <Typography className={classes.textConatiner}>{paymentMethod}</Typography>
                </Box>
              </Box>
              <Box className={classes.footer}>
                <Box className={classes.footerContainer}>
                  <Typography className={classes.footerText}>Total</Typography>
                  <Typography className={classes.footerText}><Currency text={service?.discountedPrice?.toFixed(2)}/></Typography>
                </Box>
              </Box>
            </Card>
          </Box>
        </Box>
      </>
    );
  }
}


export default withStyles(styles)(Confirmation);

// Customizable Area End