// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import { generateRequestMessage } from "../../ss-cms-common-components/src/Utilities/Utilities";
import { StripPayment as StripPaymentType } from "./Types";


const configJSON = require("./config.js");


export type Props = {
  showLoader:() => void;
  hideLoader: () => void;
  setDialogState: () => void;
  showHeader: (obj?: object) => void;
};

interface S {
  showActivateDialog: boolean;
  payment?: StripPaymentType;
}

interface SS {
}

export default class StripPaymentCardController extends BlockComponent<
  Props,
  S,
  SS
> {
  
  stripAccountStatusMessageId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      showActivateDialog: false,
      payment : {} as StripPaymentType
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleClick = () => {
    this.setState({
      showActivateDialog:  !this.state.showActivateDialog
    },()=>{
      this.getStripAccountStatus();
    })
  };

  handleClipboard = (text?: string|null) => () => {
    navigator?.clipboard?.writeText?.(text ?? "")
  };

  receive = (from: string, message: Message) => {
    this.messageStripAccountStatus(message);
  }

  componentDidMount = async() => {
    this.getStripAccountStatus();
  }

  messageStripAccountStatus = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (this.stripAccountStatusMessageId === apiRequestCallId) {
      if (responseJson) {
        this.setState({ ...this.state, payment: responseJson.data });
      }
    }
  };

  getStripAccountStatus = async () => {
    const requestMessage = await generateRequestMessage(
      configJSON.stripAccountStatusPaymentsAdminURL+"/1",
      configJSON.getMethod
    );
    this.stripAccountStatusMessageId = requestMessage.messageId;
    this.send(requestMessage);
  };
}
// Customizable Area End